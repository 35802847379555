import { createSlice } from '@reduxjs/toolkit'


function getInitialUserInfoCheck() {
	let userLocal = localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null;
	if (null != userLocal) {
		/* if (userLocal.session.willExpire) {
            const currentTime = new Date();
            const expiryTime = new Date(userLocal.session.willExpire);
            if (currentTime.valueOf() > expiryTime.valueOf()) {
                localStorage.removeItem('userInfo');
                userLocal = null;
            }
        } */
		if (userLocal.access_token) {
		} else {
			localStorage.removeItem('userInfo');
			userLocal = null;
		}
	}
	return { loading: false, userSignedIn:userLocal, loginStatus: {
    error: false,
    message: ''
  }};
}

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialUserInfoCheck(),
  reducers: {
    signInRequest:state =>{
      console.log('BEFORE> En reducer signInRequest',state);
      state.loading = true;
      state.userSignedIn = null;
      state.loginStatus= {
        error: false,
        message: ''
      };
      console.log('AFTER> En reducer signInRequest',state);
    },
    signInUser: (state,action) =>{
      state.loading = false;
      state.userSignedIn = action.payload;
      state.loginStatus= {
        error: false,
        message: ''
      };
    },
    signInFailed: (state, action)=>{
      state.loading = false;
      state.userSignedIn = null;
      state.loginStatus= {
          error: true,
          message: action.payload
      };
    },
    signOutUser: (state,action) =>{
      state.loading = false;
      state.userSignedIn = null;
      state.loginStatus = {
        error: false,
        message: ''
      }
    }
    
  }
})

export const { signInRequest,signInUser,signInFailed, signOutUser } = userSlice.actions;
export default userSlice.reducer;