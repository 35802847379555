import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FiletypePdf } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import styles from './OneSupportDocument.module.css';
import Heading from "common/components/Typography/Heading/Heading";
import { Trash } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";


export default function OneSupportDocument(props){
    const {data, pos, deleteCallback} = props;
    const [dialog,setDialog] = useState({open:false});
    const {catalogs} = useSelector((state)=> { 
        return state;
    });
    
    const doctype = catalogs.support_document_types.filter((oneType)=>oneType.id==data.document_type);
    function clickButton(evc){
        setDialog({open:true});
    }
    //console.log("Support Document",data,doctype);
    return <div className={styles.oneitem}>
        <Row>
            <Col xs={10}>
                <Heading variant="h4">Documento de soporte #{pos+1}</Heading>
            </Col>
            <Col xs={2}>
                    <Button variant="danger" onClick={() => deleteCallback(pos)}><Trash /></Button>
            </Col>
        </Row>
        <Row>
            <Col className={styles.lbl} xs={4}>Tipo de documento</Col>
            <Col>{doctype[0].name}</Col>
        </Row>
        {/*<Row>
            <Col className={styles.lbl} xs={4}>Documento</Col>
            <Col><Button variant="primary" onClick={clickButton}><FiletypePdf /> {data.file.original_name}</Button></Col>
        </Row>*/}
    </div>;
}