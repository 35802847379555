
import React, { useEffect, useState } from "react";
import Axios from 'services/BackendApi/BackendApi';
import { Button } from "react-bootstrap";
import { useParams, useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import ApiURLs from "services/BackendApi/BackendApiURLs";
import styles from './BusquedaRecepcionView.module.scss'
import { GLIFOSPDFViewer } from "common/components/GLIFOSPDFViewer/GLIFOSPdfViewer";
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import Modal from 'react-bootstrap/Modal';
import TemporaryFileViewer from "common/components/TemporaryFileViewer/TemporaryFileViewer";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import { CheckCircle, Trash3, ChatDotsFill } from "react-bootstrap-icons";
import Alert from 'react-bootstrap/Alert';

export default function BusquedaRecepcionView(props) {
  const location = useLocation();
  const [isLastPage, setIsLastPage] = useState(true);
  const navigate = useNavigate();
  var inRevision = true;
  const [isRevision, setIsRevision] = useState(true);
  const [user, setUser] = useState([]);
  const [infoEntrega, setInfoEntrega] = useState([]);
  const [resultados, setResultados] = useState();
  const [buscar, setBuscar] = useState([]);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);
  const [alerta, setAlerta] = useState("Descartar");
  const [errores, setErrores] = useState(false);
  const { id } = useParams();
  const [Id, setId] = useState(null);
  const [motivos, setMotivos] = useState(null);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [revisados, setRevisados] = useState(0);
  const [open, setOpen] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [showpdf, setShowpdf] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [columns, setColumns] = useState();
  const [motivoRegistro, setMotivoRegistro] = useState();
  const bearer = localStorage.getItem("userInfo");
  const token = JSON.parse(bearer).access_token;
  const [text, setText] = useState('');

  const [getMes] = useState({
    "enero": "01",
    "febrero": "02",
    "marzo": "03",
    "abril": "04",
    "mayo": "05",
    "junio": "06",
    "julio": "07",
    "agosto": "08",
    "septiembre": "09",
    "octubre": "10",
    "noviembre": "11",
    "diciembre": "12"
  });
  const [getMoneda] = useState({
    "GTQ": "Q",
    "USD": "$",
    "EUR": "€"
  });

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleLastPageReached = () => {
    setIsLastPage(false);
    console.log('fin');
  };

  const modalClose = () => setOpen(false);

  const incrementarRevisados = () => {
    setRevisados(prevRevisados => prevRevisados + 1);
  };

  const TypeButton = (value, llave) => {
    handleAction(value, llave);
    setShow(false)
    setShow3(false)
  };

  function Actions(accion) {
    if (accion == "Descartar") {
      setText("")
      setAlerta("Descartar")
      setOpen(true)
    } else {
      if (resultados > revisados) {
        handleShow2()
      } else {
        setAlerta("Cargar")
        setOpen(true)
      }
    }
  };

  const obtenerNumeroMes = (nombreMes) => {
    const mesMinusculas = nombreMes?.toLowerCase();
    return getMes[mesMinusculas];
  };
  const obtenerMoneda = (moneda) => {
    const currency = moneda?.toUpperCase();
    return getMoneda[currency];
  };

  const meses = {
    "enero": 1,
    "febrero": 2,
    "marzo": 3,
    "abril": 4,
    "mayo": 5,
    "junio": 6,
    "julio": 7,
    "agosto": 8,
    "septiembre": 9,
    "octubre": 10,
    "noviembre": 11,
    "diciembre": 12
  };

  const Moneda = {
    "GTQ": "Q",
    "USD": "$",
    "EUR": "€"
  };

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  const handleShow = (name, dato, motivo) => {
    setIsLastPage(true);
    if (dato.status == 1 || dato.status == 0) {
      setIsLastPage(false);

    }
    setTitle(dato?.posicion + ".pdf");
    setShowpdf(name);
    setShow(true);
    setMetadata(dato)
    setMotivoRegistro(motivo)
  };

  const handleShow2 = () => {
    setShow2(true);
  };
  const handleShow3 = () => {
    setText('')
    setShow3(true);
  };


  useEffect(() => {
    var ubicacion = location.pathname.split('/')
    if (ubicacion[1] == "revision") {
      setIsRevision(false)
      inRevision = false;
    }
  }, []);

  useEffect(() => {
    if (id != 0) {
      setBuscar({ value: id, label: id })
      console.log(inRevision)
      Show(id)
    }
  }, [inRevision]);




  const conditionalRowStyles = [
    {
      when: row => row.Color === 'success',
      style: {
        backgroundColor: '#28a745',
      },
    },
    {
      when: row => row.Color === 'danger',
      style: {
        backgroundColor: "#dc3545",
      },
    },
  ];

  async function Show(code) {
    setRevisados(0)
    setData([])
    setMessage(null)
    var res = "";
    var tipo = "";
    var revision = 0;
    if (!inRevision) {
      revision = 1;
    }
    res = await Axios.GetRecepcionById({ urlParams: { id: code, revision: revision } });
    if (res?.data?.error === true) {
      setMessage("No se pudo obtener información de la entrega")
    }
    console.log(res)
    tipo = code.split("-")
    var cuenta;
    var datos = []
    var doctype = "";
    var arreglo = res.data[1];
    var usuario = res.data[3]?.first_name + ' ' + res.data[3]?.last_name
    setId(usuario)
    var entrega = res.data[0]?.code
    setResultados(arreglo.length);
    setUser(res.data[2]);
    setInfoEntrega(res.data[0]);
    setMotivos(res.data[4]);
    var name = "";
    arreglo.forEach(function (dato, indx) {
      if (tipo[1] == "CR") {
        setColumns(cierres);
        var color = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          color = "danger"
        }
        var doc = "";
        if (dato.empresa.includes("GT")) {
          doc = dato.empresa + "-" + dato.no_sap_ora;
        } else {
          doc = dato.no_nota;
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Año: dato.anio,
          Mes: dato.mes,
          Tipo: dato.tipo,
          Nota: dato.no_nota,
          Color: color,
          Sap: dato.no_sap_ora,
          Monto: Moneda[dato.currency] + "" + dato.monto,
          Ruta: <Button onClick={() => handleShow(entrega + "/" + doc + ".pdf/CR", dato, motivo)}>{(inRevision) ? 'Revisar Registro' : 'Ver Documento'}</Button>
        }
      } else if (tipo[1] == "LC") {
        setColumns(libros);
        var nombre = entrega + "/" + dato.posicion + ".pdf/LC";
        console.log(res.data[0]?.type)
        if (res.data[0]?.type == "LIBROI") {
          nombre = entrega + "/" + res.data[0]?.id_zip + "/LCI";
        }
        var color = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          color = "danger"
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Año: dato.anio,
          Mes: dato.mes,
          Mes_f: dato.mes_final,
          Tipo: dato.tipo_libro,
          Color: color,
          Inicio: dato.inicio_folio,
          Fin: dato.fin_folio,
          Ruta: <Button onClick={() => handleShow(nombre, dato, motivo)}>{(inRevision) ? 'Revisar Registro' : 'Ver Documento'}</Button>
        }
      } else if (tipo[1] == "FC") {
        setColumns(facturas);
        var color = "";
        var icon = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          icon = <ChatDotsFill />
          color = "danger"
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var pdf = dato.posicion
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Serie: dato.serie,
          Factura: dato.factura,
          FechaE: dato.fecha_entrega,
          FechaD: dato.fecha_doc,
          Tipo: dato.tipo,
          Sap: dato.sap,
          Cod: dato.cod_solicitante,
          Nit: dato.nit,
          Razon: dato.razon_social,
          Monto: dato.monto,
          Indicador: dato.indicador,
        Pais: dato.pais,
        Autorizacion: dato.autorizacion,
          Area: dato.area,
          Color: color,
          Ruta: <Button onClick={() => handleShow(entrega + "/" + pdf + "/FC", dato, motivo)}>{(inRevision) ? 'Revisar Registro' : <>Ver Documento {icon}</>}</Button>
        }
      } else if (tipo[1] == "EC") {
        setColumns(estadoscuenta);
        var color = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          color = "danger"
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Cuenta: dato.cuenta,
          Año: dato.anio,
          Mes: dato.mes,
          Banco: dato.banco,
          Color: color,
          Ruta: <Button onClick={() => handleShow(entrega + "/" + dato.posicion + ".pdf/EC", dato, motivo)}>{(inRevision) ? 'Revisar Registro' : 'Ver Documento'}</Button>
        }
      } else if (tipo[1] == "ND") {
        setColumns(notas);
        datos = res.data[0]?.data.split("/")
        var color = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          color = "danger"
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Cuenta: dato.cuenta,
          Año: dato.anio,
          Mes: dato.mes,
          Tipo: dato.tipo,
          Nota: dato.no_nota,
          Sap: dato.no_sap_ora,
          Color: color,
          Monto: Moneda[datos[2]] + "" + dato.monto,
          Ruta: <Button onClick={() => handleShow(entrega + "/" + dato.posicion + "/ND", dato, motivo)}>{(inRevision) ? 'Revisar Registro' : 'Ver Documento'}</Button>
        }
      } else if (tipo[1] == "CL") {
        setColumns(conciliaciones);
        var color = "";
        if (dato.status == 1) {
          incrementarRevisados()
          color = "success"
        }
        if (dato.status == 0) {
          incrementarRevisados()
          setErrores(true)
          color = "danger"
        }
        var motivo;
        res.data[4].forEach(function (valor, i) {
          if (valor.register == dato.llave) {
            motivo = valor;
            console.log(motivo)
          }
        })
        var arreglo = {
          id: dato.llave,
          Empresa: dato.empresa,
          Cuenta: dato.cuenta,
          Año: dato.anio,
          Mes: dato.mes,
          Tipo: dato.tipo,
          Color: color,
          Monto: Moneda[dato.currency] + "" + dato.monto,
          Ruta: <Button onClick={() => handleShow(entrega + "/" + dato.posicion + ".pdf/CL", dato, motivo)}>{(inRevision) ? 'Revisar Registro' : 'Ver Documento'}</Button>
        }
      }
      setData(preData => [...preData, arreglo])
    });
  }

  async function Entrega() {
    if (errores) {
      const data = await Axios.CargarLote({ urlParams: { id: infoEntrega?.id } });
      await Axios.RecepcionCrearNodo(
        {
          id: infoEntrega?.id
        }
      );
      // console.log(data)
      if (data?.data?.success == true) {
        console.log("data")
        setSuccess(true);
        setTimeout(() => {
          navigate("/busqueda/usuario");
        }, 2000);
        // navigate("/busqueda/usuario", true);
      }
    } else {
      const data = await Axios.RecepcionCrearNodo(
        {
          id: infoEntrega?.id
        }
      );
      setSuccess(true);
        setTimeout(() => {
          navigate("/busqueda/usuario", true);
        }, 2000);
    }

  }

  async function Descartar() {
    // const data = await Axios.DescartarLote({ urlParams: { id: infoEntrega?.id, message: text } });
    const data = await Axios.DescartarLote( { id: infoEntrega?.id, message: text } );
    console.log(data)
    if (data?.data?.success == true) {
      setSuccess(true);
      setTimeout(() => {
        navigate("/busqueda/usuario");
        // console.log("data")
      }, 2000);
      // navigate("/busqueda/usuario", true);
    }
  }

  const handleAction = async (value, llave) => {
    var action = 1;
    if (value === 'reject') {
      action = 0
    }
    var tipo = id.split("-")
    var type = tipo[1];
    try {
      var res = await Axios.SetRevision({ urlParams: { id: llave, type: type, action: action, message: text } });
      Show(id)

    } catch (error) {

    }
  }


  const cierres = [
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    }, {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    }, {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    }, {
      name: <span>Id Sap/Oracle</span>,
      sortable: true,
      selector: row => row.Sap
    }, {
      name: <span>Id Bancario</span>,
      sortable: true,
      selector: row => row.Nota
    }, {
      name: <span>Monto</span>,
      sortable: true,
      selector: row => row.Monto
    }, {
      name: <span>Recepción</span>,
      sortable: true,
      cell: (row) => row.Ruta
    }
  ];

  const libros = [
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes Inicial</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>Mes Final</span>,
      sortable: true,
      selector: row => row.Mes_f
    },
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Inicio Folio</span>,
      sortable: true,
      selector: row => row.Inicio
    },
    {
      name: <span>Fin Folio</span>,
      sortable: true,
      selector: row => row.Fin
    },
    {
      name: <span>Recepción</span>,
      sortable: true,
      cell: (row) => row.Ruta
    }
  ];

  const notas = [
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Cuenta</span>,
      sortable: true,
      selector: row => row.Cuenta
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>Id Sap/Oracle</span>,
      sortable: true,
      selector: row => row.Sap
    },
    {
      name: <span>Id Bancario</span>,
      sortable: true,
      selector: row => row.Nota
    },
    {
      name: <span>Monto</span>,
      sortable: true,
      selector: row => row.Monto
    },
    {
      name: <span>Recepción</span>,
      sortable: true,
      cell: (row) => row.Ruta
    }
  ];

  const conciliaciones = [
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Cuenta</span>,
      sortable: true,
      selector: row => row.Cuenta
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>Recepción</span>,
      sortable: true,
      cell: (row) => row.Ruta
    }
  ];

  const facturas = [
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Fecha Entrega</span>,
      sortable: true,
      width: '120px',
      selector: row => row.FechaE
    },
    {
      name: <span>Fecha Documento</span>,
      sortable: true,
      width: '120px',
      selector: row => row.FechaD
    },
    {
      name: <span>Serie</span>,
      sortable: true,
      width: '120px',
      selector: row => row.Serie
    },
    {
      name: <span>Factura</span>,
      sortable: true,
      width: '120px',
      selector: row => row.Factura
    },
    {
      name: <span>Sap</span>,
      sortable: true,
      width: '120px',
      selector: row => row.Sap
    },
    {
      name: <span>Razon</span>,
      sortable: true,
      width: '210px',
      selector: row => row.Razon
    },
    {
      name: <span>Nit</span>,
      sortable: true,
      selector: row => row.Nit
    },
    {
      name: <span>Monto</span>,
      sortable: true,
      selector: row => row.Monto
    },
    {
      name: <span>Indicador</span>,
      sortable: true,
      selector: row => row.Indicador
    },
    {
      name: <span>Pais</span>,
      sortable: true,
      selector: row => row.Pais
    },
    {
      name: <span>Autorizacion</span>,
      sortable: true,
      width: '350px',
      selector: row => row.Autorizacion
    },
    {
      name: <span>Recepción</span>,
      sortable: true,
      width: '150px',
      cell: (row) => row.Ruta
    }
  ];

  const estadoscuenta = [
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Cuenta</span>,
      sortable: true,
      selector: row => row.Cuenta
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>Banco</span>,
      sortable: true,
      selector: row => row.Banco
    },
    {
      name: <span>Recepción</span>,
      sortable: true,
      cell: (row) => row.Ruta
    }
  ];


  return <><div>
    {/* <h1>RECEPCIÓN</h1> */}
    {(data.length == 0) ? null :
      <div>
        <br />
        {(isRevision) ?
          <>
            <h4><b>Código lote:</b> {buscar?.value}</h4>
            <h4><b>Usuario:</b> {user?.first_name + ' ' + user?.last_name}</h4>
            <h4><b>Fecha de entrega:</b> {infoEntrega?.time}</h4>
            <h4><b>Número de documentos por revisar:</b> {resultados}</h4>
            <h4><b>Porcentaje del lote para revisar:</b> {infoEntrega?.percent}%</h4>
            {(Id) ? <h4><b>Lote asignado a:</b> {Id}</h4> : ""}
          </>
          : <>
            <h4><b>Código lote:</b> {buscar?.value}</h4>
            <h4><b>Fecha de entrega:</b> {infoEntrega?.time}</h4>
            {(motivos[0]?.message != null) ?
              <>
                <Alert variant="danger">
                  <Alert.Heading>Motivo de rechazo</Alert.Heading>
                  <p>
                    {motivos[0]?.message}
                  </p>
                </Alert>
                <Alert variant="warning" >
                  <Alert.Heading>Alerta</Alert.Heading>
                  <p>Por favor, corrija los comentarios y vuelva a cargar el lote, gracias </p>
                </Alert>
              </>
              : <>{(errores) && <Alert variant="warning">
                <Alert.Heading>Alerta</Alert.Heading>
                <p>Por favor, corrija los comentarios y vuelva a cargar los registros con error, gracias </p>
              </Alert>}</>
            }
          </>}
        <br />

        <DataTableWidget
          columns={columns}
          data={data}
          conditionalRowStyles={conditionalRowStyles}
        />
        {(isRevision) ?
          <div className={styles.buttons}>
            <Button variant="danger" onClick={() => Actions("Descartar")}><Trash3 />Descartar Lote</Button>
            <Button variant="primary" onClick={() => Actions("Cargar")}><CheckCircle />Cargar Lote</Button>
          </div>
          : <Button variant="primary" onClick={() => navigate("/mis-entregas/", true)}> Regresar</Button>
        }
        <form>
        </form>
      </div>
    }

    {(message != null) ?
      <div>
        <br />
        <h3>{message}</h3>
      </div>
      : null}

  </div>
    {/* <Modal show={show} className="modal-dialog modal-fullscreen"  onHide={handleClose}> */}
    <Modal show={show} fullscreen={true} className={styles.modal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="row" >
          <div className="col-4" style={{ paddingLeft: 30 }}>
            {motivoRegistro?.message != undefined && (
              <div className="row">
                <Alert variant="danger">
                  <Alert.Heading>Motivo de rechazo</Alert.Heading>
                  <p>
                    {motivoRegistro?.message}
                  </p>
                </Alert>
              </div>
            )}
            <div className="row">
              <div className="col" style={{ textAlign: 'right' }}>
                <h5><b>Pais</b>:</h5>
              </div>
              <div className="col">
                <h5>GT</h5>
              </div>
            </div>
            {metadata?.empresa != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Empresa:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.empresa}</h5>
                </div>
              </div>
            )}
            <div>
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Repositorio:</b></h5>
                </div>
                <div className="col">
                  <h5>CT-14</h5>
                </div>
              </div>
            </div>
            {(metadata?.tipo != undefined || metadata?.tipo_libro != undefined) && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Tipo de documento:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.tipo}</h5>
                  <h5>{metadata?.tipo_libro}</h5>
                </div>
              </div>
            )}
            {(metadata?.no_sap_ora || metadata?.sap) != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Número SAP:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.no_sap_ora}</h5>
                  <h5>{metadata?.sap}</h5>
                </div>
              </div>
            )}
            {metadata?.no_nota != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Número Nota bancaria:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.no_nota}</h5>
                </div>
              </div>
            )}
            {metadata?.inicio_folio != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Inicio Folio:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.inicio_folio}</h5>
                </div>
              </div>
            )}
            {metadata?.fin_folio != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Fin Folio:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.fin_folio}</h5>
                </div>
              </div>
            )}
            {metadata?.monto != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Monto:</b></h5>
                </div>
                <div className="col">
                  <h5>{obtenerMoneda(metadata?.currency)}{metadata?.monto}</h5>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col" style={{ textAlign: 'right' }}>
                <h5><b>Fecha de documento:</b></h5>
              </div>
              {((metadata?.tipo == "ND" || metadata?.tipo == "NC") && metadata?.dia != undefined) ?

                <div className="col">
                  <h5>{metadata?.dia}-{metadata?.mes}-{metadata?.anio}</h5>
                </div> :
                (metadata?.mes != undefined) ?
                  <div className="col">
                    <h5>01-{obtenerNumeroMes(metadata?.mes)}-{metadata?.anio}</h5>
                    {metadata?.mes_final != undefined && (
                      <>
                        <h5>hasta</h5>
                        <h5>01-{obtenerNumeroMes(metadata?.mes_final)}-{metadata?.anio}</h5>
                      </>
                    )}
                  </div> :
                  <div className="col">
                    <h5>{metadata?.fecha_doc}</h5>
                  </div>
              }
            </div>
            {metadata?.cuenta != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Cuenta Bancaria:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.cuenta}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.banco != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Banco:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.banco}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.serie != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Serie:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.serie}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.factura != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Factura:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.factura}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.cod_solicitante != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Codigo Solicitante:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.cod_solicitante}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.nit != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>NIT:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.nit}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.razon_social != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Razón Social:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.razon_social}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
            {metadata?.area != undefined && (
              <div className="row">
                <div className="col" style={{ textAlign: 'right' }}>
                  <h5><b>Area:</b></h5>
                </div>
                <div className="col">
                  <h5>{metadata?.area}</h5>
                  {/* <h5>{metadata?.llave}</h5> */}
                </div>
              </div>
            )}
          </div>
          <div className="col-8" >
            <GLIFOSPDFViewer fileUrl={ApiURLs.publicFilesURL+"file/get-preview/" + showpdf} token={token} onLastPageReached={handleLastPageReached} />
            {/* <TemporaryFileViewer file={Id} pdf={showpdf} /> */}
          </div>
        </div>
      </Modal.Body>
      {(isRevision) ?
        <Modal.Footer>
          <Button variant="warning" type="submit" onClick={() => handleShow3()} disabled={isLastPage}>Rechazar</Button>
          <Button type="submit" onClick={() => TypeButton('accept', metadata?.llave)} disabled={isLastPage}>Aceptar</Button>
        </Modal.Footer>
        : ''}
    </Modal>
    <Modal show={show2} onHide={handleClose2} dialogClassName={styles.alerta}>
      <Modal.Header closeButton className={styles.header}>
        <Modal.Title style={{ color: 'white' }}>Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>No ha revisado los documentos necesarios</h4></Modal.Body>
    </Modal>
    <Modal show={open} backdrop="static" keyboard={false} onHide={modalClose} dialogClassName={(alerta == "Cargar" ? styles.aceptar : styles.alerta)}>
      <Modal.Header closeButton className={styles.header}>
        <Modal.Title style={{ color: 'white' }}>Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {(alerta == "Cargar") ?
            <>
              {!success && <h5>¿Está seguro que desea cargar el lote?</h5>}
              {success && <div className={styles.overlayStyle}><CheckMarkSuccess /></div>}
            </> :
            <>
              {!success && <> <h5>Deje un comentario de por qué desea descartar el lote:</h5>
                <div class="form-floating">
                  <textarea class="form-control" onChange={handleChange} style={{ 'height': 100 }} id="floatingTextarea"></textarea>
                  <label for="floatingTextarea">Motivo de descarte</label>
                </div></>}
              {success && <div className={styles.overlayStyle}><CheckMarkSuccess /></div>}
            </>
          }
          <br />
          {(alerta == "Cargar") ?
            <>{!success && <Button className={styles.buttons} variant="primary" onClick={Entrega}>Aceptar</Button>}</> :
            <>{!success && <Button className={styles.buttons} variant="danger" onClick={Descartar} disabled={(text == "") ? true : false}>Descartar</Button>}</>
          }
        </div>

      </Modal.Body>
    </Modal>
    <Modal show={show3} backdrop="static" keyboard={false} onHide={handleClose3} dialogClassName={styles.alerta}>
      <Modal.Header closeButton className={styles.header}>
        <Modal.Title style={{ color: 'white' }}>Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <>
            <div class="form-floating">
              <textarea class="form-control" onChange={handleChange} style={{ 'height': 100 }} id="floatingTextarea"></textarea>
              <label for="floatingTextarea">Motivo de descarte</label>
            </div>
          </>
          <br />
          <Button className={styles.buttons} variant="danger" onClick={() => TypeButton('reject', metadata?.llave)} disabled={(text == "") ? true : false}>Rechazar</Button>

        </div>

      </Modal.Body>
    </Modal>

  </>;

}