import { useRef, useState, useEffect } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { SearchIcon } from '@react-pdf-viewer/search';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import CustomToolbar from './CustomToolbar/CustomToolbar';

import styles from './GLIFOSPdfViewer.module.scss';
import Loader from './Loader/Loader';
import SearchPlugin from './SearchPlugin/SearchPlugin';

const WORKER_URL =
    'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

export function GLIFOSPDFViewer({ fileUrl, token, onLastPageReached }) {
    const [theme, setTheme] = useState('dark');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const viewerRef = useRef(null);

    const handleSwitchTheme = (new_theme) => {
        setTheme(new_theme);
    };

    const handleScroll = (event) => {
        const scrollTop = event.currentTarget.scrollTop;
        // Do something with the scroll position (scrollTop)
        console.log('Scroll position:', scrollTop);
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            {
                content: (
                    <SearchPlugin
                        searchPluginInstance={
                            defaultLayoutPluginInstance.toolbarPluginInstance
                                .searchPluginInstance
                        }
                        theme={theme}
                    />
                ),
                icon: <SearchIcon />,
                title: 'Search',
            },
            defaultTabs[0],
        ],
        renderToolbar: CustomToolbar,
        toolbarPlugin: {
            zoomPlugin: {
                enableShortcuts: true,
            },
        },
    });

    const handleDocumentLoadSuccess = (pdfDocument) => {
        console.log(pdfDocument.doc.numPages)
        setTotalPages(pdfDocument.doc.numPages);
		if (pdfDocument.doc.numPages ==1) {
			if (onLastPageReached) {
                onLastPageReached();
            }
        }
    };

    const handlePageChange = ({ currentPage }) => {
        const current = currentPage + 1; 
        setCurrentPage(current);

        
        if (totalPages > 0 && current === totalPages) {
			if (onLastPageReached) {
                onLastPageReached();
            }
        }
    };

    return (
        <Worker workerUrl={WORKER_URL}>
            <div className={`w-100 ${styles.cnt_viewer}`} onScroll={handleScroll} style={{ height: '85vh', overflow: 'auto' }}>
                <Viewer
                    ref={viewerRef}
                    httpHeaders={{ "Authorization": "Bearer " + token }}
                    withCredentials={true}
                    onSwitchTheme={handleSwitchTheme}
                    fileUrl={fileUrl}
                    renderLoader={Loader}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                    renderError={ErrorMessage}
                    onDocumentLoad={handleDocumentLoadSuccess}
                    onPageChange={handlePageChange}
                    theme={{
                        theme: 'dark',
                    }}
                />
            </div>
        </Worker>
    );
}
