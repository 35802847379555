import React from "react";
import styles from './AnimatedBlocks.module.css';
// Tomado de > https://github.com/n3r4zzurr0/svg-spinners
export default function AnimatedBlocks(props){
    // xmlns="http://www.w3.org/2000/svg" conflicts with content security
    const {stroke,fill} = props;
    return <svg width="24" height="24" viewBox="0 0 24 24">
                <rect className={styles.spinner9y7u} x="1" y="1" rx="1" width="10" height="10" style={{stroke: (stroke)?stroke:"#000", fill: (fill)?fill:"#000"}} />
                <rect className={styles.spinner9y7u +" "+styles.spinnerDF2s} x="1" y="1" rx="1" width="10" height="10" style={{stroke: (stroke)?stroke:"#000", fill: (fill)?fill:"#000"}} />
                <rect className={styles.spinner9y7u +" "+styles.spinnerq27e} x="1" y="1" rx="1" width="10" height="10" style={{stroke: (stroke)?stroke:"#000", fill: (fill)?fill:"#000"}}/>
            </svg>;

}