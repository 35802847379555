import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FacturaPapelForm from "modules/recepcion/components/Forms/FacturaPapelForm/FacturaPapelForm";
import styles from './IngresoDocumentosFisicosView.module.scss';
import CargaEstadoCuentaForm from "../../Forms/CargaEstadoCuentaForm/CargaEstadoCuentaForm";

import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";
import ReciboPapelForm from "../../Forms/ReciboPapelForm/ReciboPapelForm";


export default function IngresoDocumentosFisicosView(props){
    const { type } = useParams();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(retrieveCatalogs());
    },[dispatch]);
   
    const {catalogs} = useSelector((state)=> {
            return state;
    });

    if (!["factura","recibo"].includes(type)) return <div className={styles.viewwrapper}>
            Error. 
        </div>;

    return <div className={styles.viewwrapper}>
        <div>
           
            <Heading variant="h2" override>
                {(type=="factura")? "Recepcion de: Cierres bancarios, Estado de cuenta":"Registro recibo de papel"}    
            </Heading> 
            {(type=="factura")?<CargaEstadoCuentaForm />:<CargaEstadoCuentaForm />}
        </div>
    </div>;
}