import React from "react";
import styles from './SideMenu.module.scss';
import { Navbar, Nav, Button } from "react-bootstrap";
import { BoxArrowDownRight } from "react-bootstrap-icons";
import { useLocation, matchPath, Link } from 'react-router-dom';
import { memberof } from "modules/user/actions/userActions";
import logo from 'assets/img/poweredByGLIFOS.png';
/*
    PENDIENTE:
        -   Aquí se debe de integrar de alguna manera el React Redux para manejar los puntos
            pendientes o las notificacion de globito para cada opción.

*/
export default function SideMenu(props) {
    const { options, displayed, onDisplayClick } = props;
    //const defaultOption = options.find(({ defaultOption }) => defaultOption === true);
    const { pathname } = useLocation();

    const handleClick = () => {
        window.open('https://glifos.com/library', '_blank');
    };
    if (displayed === true)
        return <Nav variant="pills" className=" flex-column mb-auto"  >

            {
                (props.logo) ?
                    <>
                        <Navbar.Brand className={styles.navbarbrand}>
                            <img
                                src={props.logo}
                                width="50%"
                                height="auto"
                                className="d-inline-block align-top"
                                alt={props.logoalt ? props.logoalt : ''}
                            />
                        </Navbar.Brand>
                        <hr />
                    </>
                    :
                    ""
            }

            <div className={styles.history}>

                {
                    options.map((op, idx) => {
                        if (op.roles) {
                            if (!memberof(op.roles)) {
                                return "";
                            }
                        }
                        if ((op.variant) && (op.variant == "divider")) {
                            return <div key={idx}><hr />{op.label}</div>;
                        }
                        let isActive = matchPath({ path: op.route }, pathname);
                        if (!isActive && op.alias?.length > 0) {
                            op.alias.map((rt, idx) => {
                                let thisActive = matchPath({ path: rt }, pathname);
                                if (!isActive) isActive = (thisActive) ? true : false;
                                return "";
                            })
                        }
                        if ((op.variant) && (op.variant == "external")) {
                            return <a href={op.route} className={styles.navlink + " nav-link "} target="_blank" key={idx}>
                                {(op.icon) ? op.icon : ""} {op.label}
                            </a>;
                        }
                        else {
                            return <Nav.Item key={idx}>
                                {/*<Nav.Link href={op.route} className={styles.navlink + ((isActive)?" active":"")}>*/}
                                <Link to={op.route} className={styles.navlink + " nav-link " + ((isActive) ? styles.myactive/*" active"*/ : "")}>
                                    {(op.icon) ? op.icon : ""} {op.label}
                                </Link>
                                {/*</Nav.Link>*/}
                            </Nav.Item>;
                        }
                    })
                }
                <hr />

                <Nav.Item key={options.length}>
                    <Link to="/user/logout" className={styles.navlink + " nav-link "}>
                        <BoxArrowDownRight /> Cerrar Sesión
                    </Link>
                </Nav.Item>
                <Navbar.Brand className={styles.navbarlogo}>
                    <img
                        src={logo}
                        width="25%"
                        height="auto"
                        onClick={handleClick}
                        className="d-inline-block align-top"
                        alt='powered by GLIFOS'
                    />
                </Navbar.Brand>
            </div>
        </Nav>;
    else {
        return '';
    }

} 