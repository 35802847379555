import { House } from "react-bootstrap-icons";

const options =[
    {
        label: "Inicio",
        defaultOption: true,
        icon: <House />,
        route: '/dashboard'
    }
];
export default options;