import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import React from "react";
import { useEffect } from "react";
import { signout } from "modules/user/actions/userActions";
import { useDispatch } from "react-redux";

export default function LogoutForm(props){
    const dispatch = useDispatch(); 
    const {children} = props;
    useEffect(()=>{
        dispatch(signout());
    },[])
    return <div>
                <CheckMarkSuccess />
                <div style={{textAlign:"center"}}> Sesión finalizada con éxito.</div>
                {children}
            </div>;
}