import React from "react";
import styles from "../AnimatedIcons.module.css";

export default function CheckMarkSuccess(props){
    // xmlns="http://www.w3.org/2000/svg" conflicts with header#22AE73
    return <div className={styles.animationctn}>
            <div className={"icon "+styles.iconordersuccess+" svg"}>
                <svg width="154px" height="154px">  
                <g fill="none" stroke="#15e574" strokeWidth="2"> 
                    <circle className={styles.iconordersuccesssvgcircle} cx="77" cy="77" r="72" style={{strokeDasharray:"480px, 480px", strokeDashoffset:"960px"}}></circle>
                    <circle className={styles.iconordersuccesssvgcolored}  id="colored" fill="#15e574" cx="77" cy="77" r="72" style={{strokeDasharray:"480px, 480px", strokeDashoffset: "960px"}}></circle>
                    <polyline className={styles.iconordersuccesssvgpolyline+" st0"} stroke="#fff" strokeWidth="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{strokeDasharray:"100px, 100px", strokeDashoffset:"200px"}} />   
                </g> 
                </svg>
            </div>
        </div>;

}