import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FacturaPapelForm from "modules/recepcion/components/Forms/FacturaPapelForm/FacturaPapelForm";
import styles from './RecepcionDocumentosView.module.scss';
import RecepcionDocumentosForm from "../../Forms/RecepcionDocumentosForm/RecepcionDocumentosForm";
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";



export default function RecepcionDocumentosView(props) {
    const { type } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    useEffect(() => {
        dispatch(retrieveCatalogs());
    }, [dispatch]);

    const { catalogs } = useSelector((state) => {
        return state;
    });

    // if (!["factura","recibo"].includes(type)) return <div className={styles.viewwrapper}>
    //         Error. 
    //     </div>;

    return <div className={styles.viewwrapper}>
        <div>
            <Heading variant="h2" override>
                {"Recepcion de: Documentos de Cierres Bancarios"}
            </Heading>
            {<RecepcionDocumentosForm />}
        </div>
    </div>;
}