import React, { useEffect, useState } from "react";
import { formatCurrency } from 'common/helpers/DataFormat';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios from 'services/BackendApi/BackendApi';
import './CargaNotasForm.scss';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import FormElement from "common/components/FormElement/FormElement";
import { Receipt } from "react-bootstrap-icons";
import Heading from "common/components/Typography/Heading/Heading";
import { TrashFill, Trash, FastForward, SkipBackward, ExclamationTriangleFill } from "react-bootstrap-icons";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";
import FileUploader from "common/components/FileUploader/FileUploader";
import FileViewer from "common/components/FileViewer/FileViewer";


const formErrorLabels = {
    enterprise: 'No ha seleccionado la empresa',
    nit: 'No ha ingresado NIT o número de proveedor, o dato es erróneo',
    no_pedido: 'No ha ingresado No. de Pedido (Órden de Compra)',
    hoja_entrada: 'No ha ingresado No. de Hoja de entrada',
    anticipo: '',
    reporte_servicios: '',
    recibos: {
        serie: 'Serie del documento faltante',
        numero: 'Número del documento faltante',
        moneda: 'Moneda no válida',
        tipo: 'Tipo no válido',
        monto: 'Monto no válido',
        fecha: 'Por favor, ingrese una fecha válida',
        concepto: 'Por favor ingrese una descripción'
    }
};
//CargaEstadoCuentaForm
export default function RecepcionDocumentosForm(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Doctype } = props;

    const [cuentas, setCuentas] = useState([]);
    useEffect(() => {
        getAccounts();
    }, []);

    async function getAccounts() {
        let res = await Axios.getAccounts({ urlParams: "1" });
        let aux = res.data.query_result.models_1.queryOneModel_1.result[0]
        let cuenta0={cuenta:aux.bank_account_number+"/"+aux.enterprise+"/"+aux.currency
        ,año:"",mes:"enero"}
        setFormDataCierre(cuenta0);
        setCuentas(res.data.query_result.models_1.queryOneModel_1.result)
    }

    const { catalogs } = useSelector((state) => {
        return state;
    });
    const [oneSupportDoc, setOneSupportDoc] = useState({ error: false, verifying: false, data: null });
    const [wizardState, setWizardState] = useState({ step: 0 });
    const [txDataError, setTxDataError] = useState([]);


    function handleChangeCierre(evc) {
        console.log(evc);
        const newformDataCierre = { ...formDataCierre };
        newformDataCierre[evc.target.name] = evc.target.value;
        setFormDataCierre(newformDataCierre);
    }


    const [formDataCierre, setFormDataCierre] = useState({
        cuenta: cuentas[0]?.bank_account_number+"/"+cuentas[0]?.enterprise+"/"+cuentas[0]?.currency,
        año: '',
        mes: 'enero',

    });

    const [formDataCierreError, setFormDataCierreError] = useState({
        cuenta: false,
        año: false,
        mes: false,

    });

    const month = [
        { name: "enero" }, { name: "febrero" }, { name: "marzo" }, { name: "abril" }, { name: "mayo" }, { name: "junio" }, { name: "julio" }, { name: "agosto" }, { name: "septiembre" }, { name: "octubre" }, { name: "noviembre" }, { name: "diciembre" }
    ]


    return <div className="f001">

        <Form>
            
            <FormElement label="Cuenta" id="cuenta" name="cuenta" type="dropdown" onChange={handleChangeCierre} fieldLength={2}>
                {
                    cuentas.map((mes, index) =>
                        <option value={mes.bank_account_number+"/"+mes.enterprise+"/"+mes.currency} > {mes.bank_account_number}/{mes.enterprise}/{mes.currency}</option>
                    )
                }
            </FormElement>
            <FileUploader dropLabel={<>Arrastre y suelte su archivo ZIP de documentos <br /> o haga click acá para ubicar</>} callBack={UploadDocument} acceptOnly=".pdf" ></FileUploader>
        </Form>

    </div>;


    function UploadDocument(type, data) {
        switch (type) {
            case "UPLOAD_SUCCESSFULL": {
                console.log("onUploadDocumentCallback>", data);
                var url ="?account="+formDataCierre.cuenta;
                if (Doctype==="EC") {
                    navigate("/estados-cuenta/carga-lote/" + data.id+url,true);
                }else{
                    navigate("/notas/carga-lote/" + data.id+url,true);
                }
            } break;
        }
    }



};