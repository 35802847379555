import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from './CargaLibroContableForm.module.scss';
import CargaEstadoCuentaForm from "../../Forms/CargaEstadoCuentaForm/CargaEstadoCuentaForm";
import { useNavigate } from "react-router-dom";
import { Trash3, CheckCircle, TypeH1 } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";
import FormElement from "common/components/FormElement/FormElement";
import FileUploader from "common/components/FileUploader/FileUploader";
import FileViewer from "common/components/FileViewer/FileViewer";
import Axios from 'services/BackendApi/BackendApi';

export default function CargaLibroContableForm(props) {
    const { type } = useParams();
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [verifica, setVerifica] = useState(null);
    const [mensaje, setMensaje] = useState(null);
    const [empresas, setEmpresas] = useState(null);
    const navigate = useNavigate(false);
    const [reception, setReception] = useState([]);
    var ArrayEmpresas = [];

    function Descartar() {
        navigate("/", true);
    }
    useEffect(() => {
        getEnterprises();
      }, []);

    async function getZip(id) {
        return 2;
    }

    async function Entrega() {
        if (verificar() && id != null) {
            console.log(formData);
            await Axios.RecepcionCrearUnzip({ file_id: id, data: formData, type: "LIBROI" });
            let excel = await Axios.GetJsonZip({ urlParams: { id } });
            var meta = {
                id: excel.data.data[0].reception
            };
            navigate("/entrega/" + meta.id, true);
        } else {
            console.log("faltan parametros");
            setMensaje("Faltan datos por completar");
            setVerifica(true);
        }
    }

    const tipo = [
        { name: "Inventario" }, { name: "Diario mayor General" }, { name: "Estado Financiero" }, { name: "Ventas" }, { name: "Compras" }, { name: "Resultados" }
        , { name: "Balance" }    ]
    const month = [
        { name: "enero" }, { name: "febrero" }, { name: "marzo" }, { name: "abril" }, { name: "mayo" }, { name: "junio" }, { name: "julio" }, { name: "agosto" }, { name: "septiembre" }, { name: "octubre" }, { name: "noviembre" }, { name: "diciembre" }
    ]

    const [formData, setFormData] = useState({
        empresa: 'A',
        tipo: 'Inventario',
        año: '',
        mesi: 'enero',
        mesf: null,
        folioi: '',
        foliof: ''
    });

    const verificar = () => {
        for (const key in formData) {
            if (formData[key] === '') {
                setMensaje("Faltan datos por completar");
                return false;
            }
        }
        return true
    };

    async function getEnterprises(){
        let res = await Axios.GetEnterprises({ urlParams:  "1"  });
        //setEmpresas(res.data.query_result.models_1.queryOneModel_1.result)
        let array = res.data.query_result.models_1.queryOneModel_1.result
        for (let i = 0; i < array.length; i++) {
            if (array[i].erp_code_1 != array[i].erp_code_2){
                ArrayEmpresas.push({name:array[i].name,code:array[i].erp_code_2});
            }
            ArrayEmpresas.push({name:array[i].name,code:array[i].erp_code_1});
        }
        setEmpresas(ArrayEmpresas);
    }

    function handleChange(evc) {
        setMensaje("");
        console.log(evc.target.value);
        const newFormData = { ...formData };
        newFormData[evc.target.name] = evc.target.value;
        setFormData(newFormData);
        console.log(formData);
    }


    return <div>
        <div className="container text-center">
            <div className="row">
                <div className="col-4">
                    <FormElement label="Empresa" id="empresa" name="empresa" type="dropdown" onChange={handleChange} fieldLength={8}>
                        {
                            empresas?.map((enterprise, index) =>
                                <option value={enterprise.code} >{enterprise.code}-{enterprise.name} </option>
                            )
                        }
                    </FormElement>
                    <FormElement label="Tipo" id="tipo" name="tipo" type="dropdown" onChange={handleChange} fieldLength={8}>
                        {
                            tipo.map((tipo, index) =>
                                <option value={tipo.name} >{tipo.name} </option>
                            )
                        }
                    </FormElement>
                    {(formData.tipo == "Inventario" || formData.tipo == "Estado Financiero") ?
                        <div>
                            <FormElement label="Mes inicial" id="mesi" name="mesi" type="dropdown" onChange={handleChange} fieldLength={8}>
                                {
                                    month.map((mes, index) =>
                                        <option value={mes.name} > {mes.name}</option>
                                    )
                                }
                            </FormElement>
                            <FormElement label="Mes final" id="mesf" name="mesf" type="dropdown" onChange={handleChange} fieldLength={8}>
                                {
                                    month.map((mes, index) =>
                                        <option value={mes.name} > {mes.name}</option>
                                    )
                                }
                            </FormElement>
                        </div> :
                        <div>
                            <FormElement label="Mes" id="mesi" name="mesi" type="dropdown" onChange={handleChange} fieldLength={8}>
                                {
                                    month.map((mes, index) =>
                                        <option value={mes.name} > {mes.name}</option>
                                    )
                                }
                            </FormElement>
                        </div>

                    }
                    <FormElement label="Año" id="año" value={formData.año} type="number" name="año" onChange={handleChange} fieldLength={8}
                    ></FormElement>

                    <FormElement label="Folio inicial" id="folioi" value={formData.folioi} type="number" onChange={handleChange} name="folioi" fieldLength={{ sm: 3, xs: 10, lg: 5 }}
                    ></FormElement>
                    <FormElement label="Folio final" id="foliof" value={formData.foliof} type="number" onChange={handleChange} name="foliof" fieldLength={{ sm: 3, xs: 10, lg: 5 }}
                    ></FormElement>
                    {(verifica) ? <h5 className={styles.text}>{mensaje}</h5> : <h2></h2>}
                </div>
                <div className="col-8">
                    <div className={styles.columna}>
                        {Visor()}
                        {/* {butons()} */}
                        <div className={styles.buttons}>
                            {butons()}
                        </div>
                    </div>
                    {/* <FileViewer variant={"view"} file={"ad8c446c22293c4a7cd08e8f3ef43536"} openingLabel={"Accediendo al archivo..."} /> */}
                </div>
            </div>
        </div>
    </div>
        ;

    function UploadDocumentCallback(type, data) {
        switch (type) {
            case "UPLOAD_SUCCESSFULL": {
                setMensaje("");
                console.log("onUploadDocumentCallback>", data);
                setId(data.id);
            } break;
        }
    }
    function Visor() {
        // console.log(id);
        if (id == null) {
            return <FileUploader dropLabel={<>Arrastre y suelte su archivo PDF,<br /> o haga click acá para buscarlo</>} acceptOnly=".pdf" callBack={UploadDocumentCallback} ></FileUploader>
        } else {
            return <FileViewer file={id} />;
        }
    }

    function butons() {
            return <div>
                <Button variant="danger" onClick={Descartar}><Trash3 />Descartar</Button>
                <Button variant="primary" onClick={Entrega}><CheckCircle />Enviar</Button>
            </div>
        if (id != null) {
        }
    }

}
