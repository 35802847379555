import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from './EstadoCuentaFileView.module.scss';
import CargaEstadoCuentaForm from "../../Forms/CargaEstadoCuentaForm/CargaEstadoCuentaForm";
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { Button } from "react-bootstrap";
import Axios from 'services/BackendApi/BackendApi';
import { CheckCircle, Trash3 } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";


export default function CargaEstadoCuentaView(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({progress:0.0});
  const [metadata, setMetadata] = useState([]);
  const [datos, setDatos] = useState(null);
  const navigate = useNavigate();
  var prueba = [];

  useEffect(() => {
    const pathname = window.location.pathname;
    let array = pathname.split("/");
    let id = array[3];
    getExcel(id);
    var json = localStorage.getItem("datosEC");
    prueba = JSON.parse(json);
    // console.log(prueba);
    setDatos(JSON.parse(json));
    // console.log(datos);
  }, []);

  async function getExcel(id) {
    let res = await Axios.RecepcionCrearUnzip({ file_id: id, type: "CIERRES" });
    console.log(res);
    checkReporte(id);
  }

  async function checkReporte(id) {
    const data = await Axios.RecepcionCheckUnzip({ report: id });
    console.log(data);
    if (data.data.result && data.data.query_result.models_1.queryOneModel_1.result.length > 0) {
      let result = data.data.query_result.models_1.queryOneModel_1.result[0];
      if (result.progress < 1.00) {
        setLoading({progress:result.progress*100});
        setTimeout(() => { checkReporte(id); }, 1000);
      } else {
        setLoading({progress:result.progress*100});
        getZip(id);
        // setReporteData({ report: rid, progress: 1.00, ready: true, grant: result.grant });
      }

      }
  }

  async function getZip(id) {
    let excel = await Axios.GetJsonZip({ urlParams: { id } });
    console.log(excel);
    var array = [];
    var arrayMeta = [];
    let arreglo = excel.data.data;
    arreglo.forEach(function (dato) {
      // var temp = dato.empresa + "-" + dato.anio + "-" + dato.tipo_libro;
      var arreglo = {
        id: dato.llave,
        Empresa: dato.empresa,
        Año: dato.anio,
        Mes: dato.mes,
        Tipo: dato.tipo,
        Nota: dato.no_nota,
        Sap: dato.no_sap_ora,
        Monto: dato.monto
      }
      var meta = {
        id: dato.reception
      };
      arrayMeta.push(meta);
      array.push(arreglo);
    });
    setMetadata(arrayMeta);
    setData(array);
  }
  const columns = [
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>No Sap/Oracle</span>,
      sortable: true,
      selector: row => row.Sap
    },
    {
      name: <span>No Bancario</span>,
      sortable: true,
      selector: row => row.Nota
    },
    {
      name: <span>Monto</span>,
      sortable: true,
      selector: row => row.Monto
    }
  ];

  function Descartar() {
    navigate("/", true);
  }
  async function Entrega() {
    // const data = await Axios.RecepcionCrearNodo(
    //   {
    //     id: metadata[0].id
    //   }
    // );
    // let res = await Axios.RecepcionEntrega({ id: metadata[0].id,letters:"CR" });
    // console.log(res);
    navigate("/entrega/"+metadata[0].id, true);
  }
  return <div>
    <h2>Recepcion de: Cierres bancarios, Carga de Estado de cuenta</h2>
    <h4>Cuenta Bancaria: {datos?.cuenta}</h4>
    <h4>Año: {datos?.año}</h4>
    <h4>Mes: {datos?.mes}</h4>
    <h3>Cargando {loading.progress} %</h3>
    <DataTableWidget
      title=""
      columns={columns}
      data={data}
    />
    <div className={styles.buttons}>
      <Button variant="danger" onClick={Descartar}><Trash3 />Descartar</Button>
      <Button variant="primary" onClick={Entrega}><CheckCircle />Guardar</Button>
    </div>
  </div>;
}