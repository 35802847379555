import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from './CambiarAsignacionView.module.scss';
import { Button } from "react-bootstrap";
import { Check2Square, ArrowLeft } from "react-bootstrap-icons";
import CargaLibroContableForm from "../../Forms/CargaLibroContableForm/CargaLibroContableForm";
import Axios from 'services/BackendApi/BackendApi';
import Modal from 'react-bootstrap/Modal';
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import Select from "react-select";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";

export default function CambiarAsignacionView(props) {
    const navigate = useNavigate();
    const [asignaciones, setAsignaciones] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [tipo, setTipo] = useState('');
    const [recepcion, setRecepcion] = useState('');
    const [user, setUser] = useState();
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        GetAsignaciones()
        // getUsuariosByPool('6')
    }, []);

    async function GetAsignaciones() {
        setAsignaciones([])
        let res = await Axios.getAsignaciones({ urlParams: '1' });
        console.log(res)
        res.data.forEach(function (dato, indx) {
            var arreglo = {
                code: dato.code,
                type: dato.type,
                user: dato.user,
                date: dato.date,
                load: Math.round(dato.load) + ' registros',
                boton: <Button onClick={() => getUsuariosByPool(dato)}>Cambiar Asignación</Button>
            }
            setAsignaciones(preData => [...preData, arreglo])
        })
    }

    async function getUsuariosByPool(values) {
        setSuccess(false)
        setUsuarios([])
        setTipo(values.type)
        setRecepcion(values.id)
        let users = await Axios.getUserAgainstPool({ urlParams: { id: values.pool } });
        console.log(users)
        users.data.forEach(function (dato, indx) {
            if (values.user != dato.user) {
                var arreglo = {
                    id: dato.id,
                    usuario: dato.user,
                    load: dato.load + ' registros'
                }
                setUsuarios(preData => [...preData, arreglo])
            }
        })
        // setUsuarios(users?.data)
        setShow(true)
    }
    async function Change(id) {
        // console.log("se cambiará la recepción" + recepcion + " al usuario" + id)
        let cambio = await Axios.changeRevision({ urlParams: { id: id, recepcion: recepcion } });

        console.log(cambio.data.error == false)
        if (cambio.data.error == false) {
            setSuccess(true)
            setTimeout(() => {
                setShow(false)
                GetAsignaciones()
            }, 2000);
        }

    }

    const columns = [
        {
            name: <span>Tipo</span>,
            sortable: true,
            selector: row => row.type
        }, {
            name: <span>Codigo</span>,
            sortable: true,
            selector: row => row.code
        }, {
            name: <span>Fecha entrega</span>,
            sortable: true,
            selector: row => row.date
        }, {
            name: <span>Asignado a</span>,
            sortable: true,
            selector: row => row.user
        },
        {
            name: <span>Registros a revisar</span>,
            sortable: true,
            selector: row => row.load
        },
        {
            name: <span>Acción</span>,
            sortable: true,
            selector: row => row.boton
        }
    ];

    return <div>
        <h1>Asignación de revisiones</h1>

        {(asignaciones.length == 0 &&
            <h3>No hay entregas</h3>
        )}
        <div className={styles.contenedor}>
            {(asignaciones.length > 0 &&
                <DataTableWidget
                    columns={columns}
                    data={asignaciones}
                />
            )}
            <Modal show={show} onHide={handleClose} dialogClassName={styles.alerta}>
                <Modal.Header closeButton className={styles.header}>
                    <Modal.Title style={{ color: 'white' }}>Configuración</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Usuarios disponibles para {tipo}</h4><br />
                    <div className="row">
                        <div className="col">
                            <Select
                                name="tipo"
                                options={usuarios?.map((tipo) => ({
                                    label: tipo.usuario + " -carga: " + tipo.load,
                                    value: tipo.id
                                }))}
                                value={user}
                                onChange={(e) => setUser(e)}
                                placeholder="Usuarios disponibles"
                                styles={{ control: (base) => ({ ...base, width: 300 }) }}
                            />
                        </div>
                        <div className="col">
                            <Button onClick={() => Change(user?.value)} disabled={(user == undefined) ? true : false}>Cambiar asignación</Button>
                        </div>
                        {/* <br /> */}
                        {success && <div className={styles.overlayStyle}><br /><CheckMarkSuccess /></div>}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </div>
        ;
}