
import React, { useEffect } from "react";
import styles from './DigitalizacionPendientesView.module.scss';
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import Axios from 'services/BackendApi/BackendApi';
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DigitalizacionLoteModal from "./DigitalizacionLoteModal/DigitalizacionLoteModal";
import { Collection } from "react-bootstrap-icons";
import { ExclamationTriangle } from "react-bootstrap-icons";









export default function DigitalizacionPendientesView(props){



    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState({loading:true,data:[]});
    const [confirmLot,setConfirmLot] = useState({dialog:false,data:[]});
    const [txDataError,setTxDataError] = useState([]);
    const [txDataSuccess,setTxDataSuccess] = useState(null);

    function switchDossierTypeLabel(typelbl){
      switch (typelbl){
        case 1:{return "Facturas en papel";}break;
        case 2:{return "Facturas electrónicas";}break;
        case 3:{return "Recibos/Invoices en papel";}break;

      }
    }
    function countDocuments(objr){
      let total=0;
      /*let {facturas,recibos} = objr.data;
      if (objr.data.reporte_servicios=="1") total++;

      Object.keys(facturas||recibos).forEach((prop)=>{
        total++;
        let thisFactura = (facturas||recibos)[prop];
        if (thisFactura.reporte=="1") total++;
      });*/
      return total;
    }
    const columns = [
      {
        name: <span className={styles.tableheader}>Recibido en</span>,
        selector: (row) => <Link  className={styles.tablelink}  to={'/detalle-tramite/'+row.id}>{row.reception_timestamp}</Link>,
        sortable: true,
        right: false,
        compact: true,
        maxWidth: '130px'
      },
      {
        name: <span className={styles.tableheader}>No. Proveedor</span>,
        selector: (row) => <Link  className={styles.tablelink} to={'/detalle-tramite/'+row.id}>{row.num_proveedor}</Link>,
        sortable: true,
        maxWidth: '75px'
      },
/*      {
        name: <span className={styles.tableheader}>Num. Proveedor</span>,
        selector: (row) => <Link  className={styles.tablelink} to={'/detalle-tramite/'+row.id}>{row.num_proveedor}</Link>,
        sortable: true,
        compact: true,
        maxWidth: '75px'
      },*/
      {
        name: <span className={styles.tableheader}>Tipo</span>,
        selector: (row) => <Link  className={styles.tablelink} to={'/detalle-tramite/'+row.id}>{switchDossierTypeLabel(row.type)}</Link>,
        sortable: true,
        compact: true
      },
      {
        name: <span className={styles.tableheader}>No. Docs</span>,
        selector: (row) => <Link  className={styles.tablelink} to={'/detalle-tramite/'+row.id}>{countDocuments(row)}</Link>,
        sortable: true,
        compact: true,
        maxWidth:'50px'
      }/*,
      {
        name: <span className={styles.tableheader}>Descripción</span>,
        selector: (row) => <Link  className={styles.tablelink} to={'/detalle-tramite/'+row.id}>{row.description}</Link>,
        sortable: true,
        right: false,
        compact: true
      }*/,
      {
        name: <span className={styles.tableheader}>ESTATUS</span>,
        selector: (row) => {
            switch (row.status){
              case 0: {return <Link  className={styles.tablelink+" "+styles.recibidovent} to={'/detalle-tramite/'+row.id}>RECIBIDO EN VENTANILLA</Link>}break;
              case 1: {return <Link  className={styles.tablelink+" "+styles.transitoarchivo} to={'/detalle-tramite/'+row.id}>EN TRÁNSITO A ARCHIVO</Link>}break;
              case 2: {return <Link  className={styles.tablelink+" "+styles.retornadoarchivo} to={'/detalle-tramite/'+row.id}><ExclamationTriangle /> RETORNADO</Link>}break;
            }
          },

        sortable: true,
        right: false,
        compact: true
      },
      {
        button: true,
        cell: (row) => {
            //console.log(row);
            return <div>
                <Button variant="primary" onClick={()=>{navigate('/detalle-tramite/'+row.id);}}>Ver</Button>  
            </div>;
          }
        
      }
    ];


    async function processTx(){
      let dossiers = [];
      setTableData({loading:true,data:[]});
      setConfirmLot({dialog:false,data:[]});
      setTxDataError([]);
      setTxDataSuccess(null);
      try {
         const { data } = await  Axios.ListarDocumentosPapel();
         if (data.error){
            //console.log('Error,',data);
         }
         if (data.result && !data.error){
             //console.log('Good',data);
            data.query_result[0].result.map((item,idx)=>{
              dossiers[dossiers.length]={...item,data:{...item},metadata:{dossier_id:item.dossier},documents:[]};
              data.query_result[2].result.map((onem,idx)=>{
                if (onem.dossier===item.dossier){
                  // si es metadata de este dossier
                  dossiers[dossiers.length-1].metadata[onem.code]=onem[onem.datatype+"_value"];
                }
              });
              dossiers[dossiers.length-1].documents=data.query_result[1].result.filter((oned)=>oned.dossier===item.dossier);
               
            })
            //console.log('json interpreted',dossiers);
            setTableData(
              {
                loading:false,
                data:{
                    dossiers: dossiers,
                    files:data.query_result[1].result
                }
              }
            );
            //console.log("this was stored",tableData);
         }	
      }catch(error){
          //console.log('catch Error',error);
      }
          
    }
    useEffect(()=>{
        dispatch(retrieveCatalogs());
        processTx();
    },[dispatch]);
   
    /*const {catalogs} = useSelector((state)=> {
            return state;
    });*/

    function handleSelectedItems(rows){
      //console.log('The rows are>',rows);
      setConfirmLot({dialog:false, data: rows.selectedRows});
    }
    function switchShow(newState){
      setConfirmLot({...confirmLot,dialog:newState});
    }
    async function processTXCreate(){
      let thisLot = [];
      for (let x = 0; x<confirmLot.data.length;x++){
        thisLot[thisLot.length] = confirmLot.data[x].id;
      }
      try {
          const { data } = await  Axios.CrearLoteDigitalizacion({ dossier:thisLot});
          //console.log("Lo que obtuvimos de pedir lote",data);
          if (data.error){
              //setConfirmationState(-1);
              //console.log("ERROR ... LA DATA");
              //console.log(data.error_log);
              if (Array.isArray(data.error_log))
              setTxDataError(data.error_log);
              else
              setTxDataError([data.error_log]);
          }
          if (data.result && data.freeSQL_result.result.length>0 && data.freeSQL_result.result[0].lote_id){
              setTxDataSuccess(data.freeSQL_result.result[0]);
              //setConfirmationState(10);
              setTimeout(()=>setConfirmLot({dialog: false, data: []}),5000);
          }
      }catch(error){
          //console.log("ERROR ON REGISTRARFACTURA PAPEL",error);
          //setConfirmationState(-1);
      }
      
    }
    return <div className={styles.viewwrapper}>
        <div> 
            <div>
              Operaciones: <Button variant={confirmLot.data.length==0? "light":"primary"} disabled={confirmLot.data.length===0} onClick={()=> setConfirmLot({...confirmLot,dialog:true})}><Collection></Collection> Enviar lote </Button>
            </div>
            <DigitalizacionLoteModal data={confirmLot.data} show={confirmLot.dialog} switchShow={switchShow} onClose={()=>{setConfirmLot({dialog:false,data:[]});setTableData({loading:false,data:[]});setTxDataSuccess(null);switchShow(false);processTx();}} onProceed={processTXCreate} errors={txDataError} success={txDataSuccess}></DigitalizacionLoteModal>
         
            { (tableData.loading)? "Cargando...":
                <DataTableWidget
                  title="Trámites recientes"
                  columns={columns}
                  data={tableData.data.dossiers}
                  handleSelection={handleSelectedItems}
                  selectableRowDisabled={row => (row.status==1)}
                />
        }
        </div>
    </div>;
}