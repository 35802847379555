import { createSlice } from "@reduxjs/toolkit";


export const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState: {
		enterprises:[],
		currencies:[]
	},
    reducers: {
        setCatalogs : (state, action ) => {
            //console.log('We are setting catalogs ',action);
            if (action.payload.query_result.models_1.queryOneModel_1.result.length>0){
                if (!action.payload.query_result.models_1.queryOneModel_1.result[0].hasOwnProperty('id')){
                    for (var xc=0; xc<action.payload.query_result.models_1.queryOneModel_1.result.length;xc++){
                        action.payload.query_result.models_1.queryOneModel_1.result[xc]['id']=action.payload.query_result.models_1.queryOneModel_1.result[xc].erp_code1;
                    }
                }
            }
            if (action.payload.query_result.models_1.queryOneModel_2.result.length>0){
                if (!action.payload.query_result.models_1.queryOneModel_2.result[0].hasOwnProperty('id')){
                    for (var xc=0; xc<action.payload.query_result.models_1.queryOneModel_2.result.length;xc++){
                        action.payload.query_result.models_1.queryOneModel_2.result[xc]['id']=action.payload.query_result.models_1.queryOneModel_2.result[xc].code;
                    }
                }
            }
            //console.log('We are returning ',{ ...state, loading: false, enterprises: action.payload.query_result.models_1.queryOneModel_1.result, currencies: action.payload.query_result.models_1.queryOneModel_2.result })
            return { ...state, loading: false, enterprises: action.payload.query_result.models_1.queryOneModel_1.result, currencies: action.payload.query_result.models_1.queryOneModel_2.result, support_document_types: action.payload.query_result.models_1.queryOneModel_3.result };
        },
        requestCatalogs : (state, action ) =>{
            return { ...state, loading: true };
        },
        requestCatalogsFailed: (state, action ) =>{
            return { ...state, loading: false, error: action.payload };
        }
    }
});

export const {setCatalogs, requestCatalogs, requestCatalogsFailed} = catalogsSlice.actions;

export default catalogsSlice.reducer;
