import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import {frontEndBaseUrl} from 'services/BackendApi/BackendApiURLs';
export default function ProtectedRoute(props){
    const {allowedroles, children} = props;
    const location = useLocation();  
    let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;
    if (userLocal==null){
        return <Navigate to={"/user/login?returnTo="+encodeURIComponent(location.pathname)} replace />
    };
    if ((!allowedroles)) return children;
    if (allowedroles){
        let found = allowedroles.some(r => userLocal.roles.indexOf(r) >= 0);
        if (found) return children;
        else return <Navigate to="/user/restricted" replace />;
    }
    return children;
}