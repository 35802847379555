import React, {useEffect, useState} from "react";
import styles from './CentralElementLayout.module.scss';
import { Navigate } from "react-router-dom";

export default function CentralElementLayout(props){
    const {classes, redirectAfterTo, timeOutToRedirect} = props;
    const [redirectNow,setRedirectNow] = useState(false);
    let extraClassNames = "ce-layout-wrapper ";

    useEffect(()=>{
        if (redirectAfterTo)
            if (timeOutToRedirect) setTimeout(()=> setRedirectNow(true),timeOutToRedirect);
            else setTimeout(()=> setRedirectNow(true),5000);
    });

    if (classes instanceof Array){
        extraClassNames+= classes.join(" ");
    }
    if (redirectAfterTo && redirectNow) return <Navigate to={redirectAfterTo} replace />;

    return <div className={styles.layoutwrapper+" "+extraClassNames}>
            <div className={styles.centralelement + " ce-central-element "} >
                {props.children}
            </div>
    </div>;
};