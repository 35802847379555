import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from './CargaConciliacionesFileView.module.scss';
import CargaEstadoCuentaForm from "../../Forms/CargaEstadoCuentaForm/CargaEstadoCuentaForm";
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { Button } from "react-bootstrap";
import Axios from 'services/BackendApi/BackendApi';
import axios from 'axios';
import { CheckCircle, Trash3 } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import TemporaryFileViewer from "common/components/TemporaryFileViewer/TemporaryFileViewer";
import Modal from 'react-bootstrap/Modal';
import { useSearchParams } from 'react-router-dom';
import SpinnerIcon from 'assets/img/loading'
import Alert from 'react-bootstrap/Alert';

export default function CargaDocumentosFileView(props) {
  var prueba = [];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({ progress: 0.0 });
  const [metadata, setMetadata] = useState([]);
  const [Id, setId] = useState(null);
  const [datos, setDatos] = useState(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [show, setShow] = useState(false);
  const [Cont, setCont] = useState(false);
  const [showpdf, setShowpdf] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState();

  const handleClose = () => setShow(false);
  const modalClose = () => setOpen(false);
  //const Account = searchParams.get('account');
  const handleShow = (name) => {
    setTitle(name.slice(0, -3));
    setShowpdf(name);
    setShow(true);
  };


  useEffect(() => {
    const pathname = window.location.pathname;
    console.log(pathname);
    let array = pathname.split("/");
    let id = array[5];
    setId(id);
    crearReporte(id);
    // const fetchData = async () => {
    //     fetch("/server/unpack/process/"+id)
    //       .then(res=>res.json())
    //       .then(res=>console.log(res))
    //       .catch(error=>console.log(error));
    //   };
    // fetchData();
  }, []);

  async function crearReporte(idReport) {
    const data = await Axios.RecepcionCrearUnzip({ file_id: idReport, type: "CONCI" });
    console.log(data);
    checkReporte(idReport);

  }

  async function checkReporte(id) {
    const data = await Axios.RecepcionCheckUnzip({ report: id });
    // console.log(data);
    if (data.data.result && data.data.query_result.models_1.queryOneModel_1.result.length > 0) {
      let result = data.data.query_result.models_1.queryOneModel_1.result[0];
      if (result.progress < 1.00) {
        setLoading({ progress: result.progress * 100 });
        setTimeout(() => { checkReporte(id); }, 1000);
      } else {
        setLoading({ progress: result.progress * 100 });
        getZip(id);
        setisLoading(false);
        // setReporteData({ report: rid, progress: 1.00, ready: true, grant: result.grant });
      }

    }
  }

  async function getZip(id) {
    let excel = await Axios.GetJsonZip({ urlParams: { id } });
    //console.log(excel);
    var tot = 0;
    var count = 0;
    let arreglo = excel.data.data;
    var Contdatos = arreglo.length;
    arreglo.forEach(function (dato, indx) {
      var emp = dato.empresa
      var name = "";
      if (emp.includes("GT")) {
        name = dato.empresa + "-" + dato.no_sap_ora;
      } else {
        name = dato.no_nota;
      }
      let carpeta = dato.code;
      let id = dato.posicion + ".pdf";
      tot++;
      var name2 = "Documento";
      var color = "primary"
      if (dato.encontrado == 0) {
        color = "danger";
        count++;
        setShowModal(true);
        name2 = "PDF NO ENCONTRADO";
      }
      var arreglo = {
        id: dato.llave,
        Empresa: dato.empresa,
        Cuenta: dato.cuenta,
        Año: dato.anio,
        Mes: dato.mes,
        Tipo: dato.tipo,
        Monto: dato.monto,
        Color: color,
        Button: <Button variant={color} onClick={() => handleShow(dato.code + "/" + dato.posicion+".pdf/CL")} >{name2}</Button>
      }
      var meta = {
        id: dato.reception
      };
      setCont(count)
      setTotal(tot);
      setData(preData => [...preData, arreglo])
      setMetadata(preData => [...preData, meta]);
      if (indx === Contdatos - 1) {
        setisLoading(false);
      }
    });
    setOpen(true);
    // setisLoading(false);
    // setMetadata(arrayMeta);
    // setData(array);
  }

  const columns = [
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Empresa</span>,
      sortable: true,
      selector: row => row.Empresa
    },
    {
      name: <span>Cuenta</span>,
      sortable: true,
      selector: row => row.Cuenta
    },
    {
      name: <span>Año</span>,
      sortable: true,
      selector: row => row.Año
    },
    {
      name: <span>Mes</span>,
      sortable: true,
      selector: row => row.Mes
    },
    {
      name: <span>Documento</span>,
      sortable: true,
      cell: (row) => row.Button
    }
  ];

  const conditionalRowStyles = [
    {
      when: row => row.Color === 'danger',
      style: {
        color: 'red',
      },
    },
  ];

  async function Descartar() {
    // const data = await Axios.DiscardLoteLibro({ urlParams: 1 });
    navigate("/", true);
  }
  async function Entrega() {
    // const data = await Axios.RecepcionCrearNodo(
    //   {
    //     id: metadata[0].id
    //   }
    // );
    // let res = await Axios.RecepcionEntrega({ id: metadata[0].id, letters: "CR" });
    navigate("/entrega/" + metadata[0].id, true);
  }
  return <div>
    {(loading.progress < 100) && <h3>Cargando {loading.progress} %</h3>}


    <div>
      {(showModal) &&
        <Alert variant="danger" >
          <Alert.Heading>Alerta</Alert.Heading>
          <h5>
            Hay {Cont} registro(s) con error, por favor revisar el lote
          </h5>
        </Alert> }
      <DataTableWidget
        title="Recepcion de: Conciliaciones por lote"
        columns={columns}
        data={data}
        conditionalRowStyles={conditionalRowStyles}
        handleSelection={handleSelectedItems}
      />
    </div>

    <Modal show={open} onHide={modalClose} dialogClassName={styles.alerta}>
      {(Cont > 0) ?
        <Modal.Header closeButton className={styles.error}>
          <Modal.Title>Alerta</Modal.Title>
        </Modal.Header>
        :
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title>Carga exitosa</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body className={styles.columna}>
        {(Cont > 0) ?
          <div>
            <h3>Hay {Cont} registro(s) sin PDF encontrado, por favor revisar lote</h3>
          </div>
          :
          <div>
            <h3>El lote se cargó exitosamente con {total} registro(s)</h3>
          </div>}

      </Modal.Body>
    </Modal>

    <div className={styles.buttons}>
      <Button variant="danger" onClick={Descartar}><Trash3 /> Descartar</Button>
      <Button variant="primary" onClick={Entrega}><CheckCircle /> Confirmar y enviar</Button>
    </div>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.columna}>
        <TemporaryFileViewer file={Id} pdf={showpdf} />
      </Modal.Body>
    </Modal>
  </div>;

  function handleSelectedItems(selectedRows) {
    //console.log(selectedRows);
  }
  // async function DescomprimirZip() {
  //   console.log(Id);
  //   const data = await Axios.RecepcionUnzip({ report: Id });
  //   console.log(data);
  // }
}