import { setCatalogs, requestCatalogs, requestCatalogsFailed } from '../services/Redux/catalogsSlice.js';
import Axios from 'services/BackendApi/BackendApi';


export const retrieveCatalogs = () => async(dispatch) =>{
	dispatch(requestCatalogs());
	try {
		const { data } = await Axios.GetCatalogs();
		if (data.result) {
			console.log(data.result);
			dispatch(setCatalogs(data));
		} else {
			//console.log('Catalogs retrieve error no data success',data);
			dispatch(requestCatalogsFailed("NO DATA"));
		}
	} catch (error) {
		console.log('Catalogs retrieve error',error);
		dispatch(requestCatalogsFailed(
			 error.response && error.response.data.message
					? error.response.data.message
					: error.message
		));
	}
}
