
// La conexión la diferencia dependiendo el ambiente en el que esté
// const ApiHostName = window.location.hostname+'/pruebas/api';
const ApiHostName = window.location.hostname.indexOf('archivo.imsa.com.gt') >= 0
		? window.location.hostname+'/api'
		: window.location.hostname+'/api';
		// : 'api.colaborador.imsavv.local';
		// : ((window.location.hostname.indexOf('local') >= 0)||(window.location.hostname.indexOf('celular')))
		// ? 'api.' + window.location.hostname
export const frontEndBaseUrl = "/recepcion";
//console.log('ApiHostName:' + ApiHostName);
const ApiURLs = {
	publicFilesURL: 'https://'+ApiHostName+'/v1/',
	baseURL: window.location.protocol + '//' + ApiHostName + '/v1/',
	UserLogin: {
		href: '/auth/authenticate',
		method: 'post',
		requires: ['username', 'password', 'remember'],
	},
	GetRecepcionById: {
		href: '/reception/get-recepcion/:id/:revision',
		method: 'get',
        urlParams: ['id','revision'],
	},
	getAsignaciones: {
		href: '/reception/get-asignaciones',
		method: 'get',
	},
	getUserAgainstPool: {
		href: '/reception/get-user-against-pool/:id',
		method: 'post',
		urlParams: ['id'],
	},
	changeRevision: {
		href: '/reception/change-revision/:id/:recepcion',
		method: 'post',
		urlParams: ['id','recepcion'],
	},
	DescartarLote: {
		href: '/reception/discard-lote',
		method: 'post',
        requires: ['id','message'],
	},
	CargarLote: {
		href: '/reception/cargar-lote/:id',
		method: 'post',
        urlParams: ['id'],
	},
	setPorcentaje: {
		href: '/reception/set-percent/:code/:count',
		method: 'post',
        urlParams: ['code','count'],
	},
	getTiposEntregas: {
		href: '/reception/list-tipos-entregas',
		method: 'post',
	},
	GetPorcentaje: {
		href: '/reception/get-percent',
		method: 'post',
	},
	SetRevision: {
		href: '/reception/set-revision/:id/:type/:action/:message',
		method: 'get',
        urlParams: ['id','type','action','message'],
	},
	GetRecepcionByUser: {
		href: '/reception/get-user-recepcion',
		method: 'get',
	},
	GetRecepcionByAdmin: {
		href: '/reception/get-admin-recepcion/:id/:type/:year/:month',
		method: 'get',
        urlParams: ['id','type','year','month'],
	},
	ListRecepciones: {
		href: '/reception/list-recepciones/:type/:year/:month',
		method: 'get',
        urlParams: ['type','year','month'],
	},
	documentTypes: {
		href: '/api/tx/document-type',
		method: 'get',
	},
	receptionCodes: {
		href: '/api/tx/reception-codes',
		method: 'get',
	},
	receptionUsers: {
		href: '/api/tx/reception-users',
		method: 'get',
	},
	GetCatalogs:{
		href: '/api/tx/list-catalogs',
		method: 'get',
	},
	getAccounts:{
		href: '/api/tx/list-accounts',
		method: 'get',
	},
	GetEnterprises: {
		href: '/api/tx/list-enterprises',
		method: 'get',
	},
	RegistrarFacturasPapel: {
		href: '/api/tx/registro-facturas-papel',
		method: 'post',
	},
	RegistrarFacturasPapel2: {
		href: '/api/tx/registro-facturas-papel2',
		method: 'post',
	},
	RegistrarRecibosPapel: {
		href: '/api/tx/registro-recibos-papel',
		method: 'post',
	},
	RegistrarRecibosPapel2: {
		href: '/api/tx/registro-recibos-papel2',
		method: 'post',
	},
	ListarDocumentosPapel:{
		href: '/api/tx/list-documentos-papel',
		method: 'get'
	},
	EliminarTramite:{
		href: '/api/tx/eliminar-tramite',
		method: 'post',
		requires: ['dossier_id'],
	}
	,
	DetalleDossier:{
		href: '/api/tx/dossier-detail',
		method: 'post'
	},
	CrearLoteDigitalizacion:{
		href: '/api/tx/crear-lote-digitalizacion',
		method: 'post'
	},
	DetalleLote:{
		href: '/api/tx/lote-detalle',
		method: 'post'
	},
	RetornarLote:{
		href: '/api/tx/retornar-lote',
		method: 'post'
	},
	ListUserLots:{
		href: '/api/tx/list-user-lots',
		method: 'post'
	},
	OneLotDetail:{
		href: '/api/tx/one-lot-detail',
		method: 'post'
	},
	AcceptLot:{
		href: '/api/tx/accept-lot',
		method: 'post'
	},
	RejectLot:{
		href: '/api/tx/reject-lot',
		method: 'post'
	},
	UploadFile: {
		href: '/file/upload',
		method: 'post',
	},
	DiscardLoteLibro: {
		href: '/file/discard',
		method: 'post',
	},
	GetJsonExcel: {//Este es para el estado de cuenta
		href: '/file/get-json/:id',
		method: 'get',
        urlParams: ['id'],
	},
	GetJsonZip: { // retorna la tabla de mysql en base al tipo
		href: '/file/get-zip/:id',
		method: 'get',
        urlParams: ['id'],
	},
	GetNameDelivery: { // retorna la tabla de mysql en base al tipo
		href: '/file/get-name-delivery/:id',
		method: 'get',
        urlParams: ['id'],
	},
	GetExist:{
		href: '/file/get-exist/:carpeta/:id',
		method: 'get',
		urlParams:['carpeta','id'],
	},
	RequestAccessToFile: {
		href: '/file/request-access',
		method: 'post',
	},
	RequestAccessToDossierFile:{
		href: '/file/dossier-file-request-access',
		method: 'post',
	},
	ListPendingDigitization: {
		href: '/api/tx/list-pending-digitization',
		method: 'post'
	},
	OneLotForDigitizationDetail:{
		href: '/api/tx/one-lot-digitization-detail',
		method: 'post'
	},
	AssociateDocumentToFile:{
		href: '/api/tx/associate-document-file',
		method: 'post'
	},
	FinalizeLot:{
		href: '/api/tx/finalize-lot',
		method: 'post'
	},
	ListMyPendingReview:{
		href: '/api/tx/costeo-list-my-pending',
		method: 'post'
	},
	ListMyPendingRetenciones:{
		href: '/api/tx/costeo-list-my-pending-retenciones',
		method: 'post'
	},
	DetalleDossierCosteo:{
		href: '/api/tx/dossier-detail-costeo',
		method: 'post'
	},
	CosteoOperarTramite:{
		href: '/api/tx/costeo-operar-tramite',
		method: 'post'
	},
	CosteoGetCatalogs:{
		href: '/api/tx/list-catalogs-costeo',
		method: 'post'
	},
	CosteoAdicionarDocumentoTramite:{
		href: '/api/tx/costeo-adicionar-documento',
		method: 'post'
	},
	CosteoCrearReporteMiro:{
		href: '/api/tx/costeo-crear-reporte-miro',
		method: 'post'
	},
	RecepcionCrearUnzip:{
		href: '/reception/process',
		method: 'post'
	},
	CosteoCheckReporteMiro:{
		href: '/api/tx/costeo-check-reporte-miro',
		method: 'post'
	},
	RecepcionCheckUnzip:{
		href: '/api/tx/recepcion-check-unzip',
		method: 'post'
	},
	GetJsonZip: { // retorna la tabla de mysql en base al tipo
		href: '/file/get-zip/:id',
		method: 'get',
        urlParams: ['id'],
	},
	RecepcionCrearNodo:{
		href: '/api/tx/recepcion-crear-nodo',
		method: 'post'
	},
	RecepcionEntrega:{
		href: '/api/tx/recepcion-entrega',
		method: 'post'
	},
	JefeCosteoListMyPendingReview:{
		href: '/api/tx/jefe-costeo-list-my-pending',
		method: 'post'
	},
	DetalleDossierJefeCosteo:{
		href: '/api/tx/dossier-detail-jefe-costeo',
		method: 'post'
	},
	JefeCosteoOperarTramite:{
		href: '/api/tx/jefe-costeo-operar-tramite',
		method: 'post'
	},
	/*
	GetInvoiceInfo: {
		href: '/file/get-factura-info',
		method: 'get',
		//urlParams: ['id'],
	},
	GetTramitePago: {
		href: '/api/tx/list-tramite-pago',
		method: 'get',
	},
	CreateDossier: {
		href: '/api/tx/create-dossier',
		method: 'post',
	},
	CreateSection: {
		href: '/api/tx/create-section',
		method: 'post',
	},
	CreateDocument: {
		href: '/api/tx/create-document',
		method: 'post',
	},
	CreateDocumentIdentifier: {
		href: '/api/tx/create-document-identifier',
		method: 'post',
	},
	PendingDossier: {
		href: '/api/tx/my-pendings',
		method: 'get',
	},
	GetDossierStatus: {
		href: '/api/tx/list-dossier-status',
		method: 'get',
	},
	GetDossier: {
		href: '/api/tx/dossier',
		method: 'get',
		//requires: ['number'],
	},
	UpdateDossierStatus: {
		href: '/api/tx/update-dossier',
		method: 'put',
		//requires: ['number'],
	},
	UpdateDossier: {
		href: '/api/tx/file-update',
		method: 'post',
	},
	DossierHistory: {
		href: '/api/tx/document-history',
		method: 'post',
	},
	RequestDraftReview: {
		href: '/api/tx/request-draft-review',
		method: 'post',
	},
	DossierComments: {
		href: '/api/tx/list-comments',
		method: 'post',
	},
	RequestSignature: {
		href: '/api/tx/request-signatures',
		method: 'post',
	},
	AddDocuments: {
		href: '/api/tx/add-final-authorization-document',
		method: 'post',
	},
	FinalizeDossier: {
		href: '/api/tx/finalize-dossier',
		method: 'post',
	},
	ReadUserBatch: {
		href: '/glifos/api/checkin/batch',
		method: 'post',
	},
	dowloadFile: {
		href: '/glifos/file/download/',
		method: 'get',
	},
	getFileMetadata: {
		href: '/lot/lot-data',
		method: 'get',
	},
	signPrepare: {
		href: '/sign/prepare',
		method: 'post',
		requires: ['dossier_document_id'],
	},
	signFinish: {
		href: '/sign/finish',
		method: 'post',
		requires: ['request_id', 'cer_file_id', 'password'],
	},
	getMyDocuments: {
		href: '/api/tx/my-documents',
		method: 'get',
	},*/
};
//console.log(ApiURLs);
export default ApiURLs;
