
import React, { useEffect, useState, useRef } from "react";
import Axios from 'services/BackendApi/BackendApi';
import { Button } from "react-bootstrap";
import styles from './BusquedaUsuarioView.module.scss'
import FormElement from "common/components/FormElement/FormElement";
import DataTableWidget from "common/components/DataTableWidget/DataTableWidget";
import Modal from 'react-bootstrap/Modal';
import BusquedaRecepcionView from "../BusquedaRecepcion/BusquedaRecepcionView";
import TemporaryFileViewer from "common/components/TemporaryFileViewer/TemporaryFileViewer";
import Select from "react-select";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

export default function BusquedaUsuarioView(props) {

  const [users, setUsers] = useState([]);
  const [bandera, setBandera] = useState(false);
  const [buscar, setBuscar] = useState([]);
  const [type, setType] = useState([]);
  const [years, setYears] = useState([]);
  const [anio, setAnio] = useState();
  const [mes, setMes] = useState();
  const [months, setMonths] = useState();
  const [data, setData] = useState([]);
  const [codigo, setCodigo] = useState(null);
  const handleClose = () => setShow(false);
  const [mostrar, setMostrar] = useState(false);
  const [title, setTitle] = useState(null);
  const [showpdf, setShowpdf] = useState(null);
  const [show, setShow] = useState(false);
  // const [columns, setColumns] = useState();
  const [value, setValue] = useState(10);
  const valueRef = useRef(value);
  const navigate = useNavigate();


  const Tipo = {
    "CIERRES": "CIERRE BANCARIO",
    "NOTAS": "NOTAS DE DEBITO",
    "LIBROS": "LIBROS CONTABLES",
    "CONCI": "CONCILIACIONES",
    "FACTURAS": "FACTURAS",
    "EC": "ESTADO DE CUENTA",
    "LIBROI": "LIBRO INDIVIDUAL"
  };

  const Types = [{ code: "CIERRES", name: "CIERRE BANCARIO" }, { code: "NOTAS", name: "NOTAS DE DEBITO" },
  { code: "LIBROS", name: "LIBROS CONTABLES" }, { code: "CONCI", name: "CONCILIACIONES" }, { code: "LIBROI", name: "LIBRO INDIVIDUAL" },
  { code: "FACTURAS", name: "FACTURAS" }, { code: "EC", name: "ESTADO DE CUENTA" }]

  useEffect(() => {
    console.log('Valor actual del slider en useEffect:', value);
  }, [value]);

  useEffect(() => {
    getReceptions();
    getFechas();
  }, []);

  async function getReceptions() {
    const res = await Axios.receptionUsers({ urlParams: "1" });
    setUsers(res.data.query_result.models_1.queryOneModel_1.result)
    setData([])
    var tipo = 0;
    var Fmonth = 0;
    var user = 0;
    var Fyear = 0;
    const datos = await Axios.GetRecepcionByAdmin({ urlParams: { id: user, type: tipo, year: Fyear, month: Fmonth } });
    var arreglo = datos.data;
    var name = "";
    arreglo.forEach(function (dato, indx) {
      var arreglo = {
        Tiempo: dato.time,
        Tipo: Tipo[dato.type],
        Codigo: dato.code,
        Button: (
          <Button onClick={() => setPercent(dato.code)} variant="primary">
            Ver Entrega
          </Button>
        )
      }
      setData(preData => [...preData, arreglo])
    });
    setBandera(true)
  }

  async function setPercent(codigo) {
    // console.log(valueRef.current)
    // await Axios.setPorcentaje({ urlParams: { code: codigo, count: valueRef.current } });
    navigate("/busqueda/recepcion/" + codigo, true);
    // console.log(res)
  }

  function getFechas() {
    var today = new Date();
    var year = today.getFullYear();
    for (let i = 2024; i <= year; i++) {
      setYears(preData => [...preData, i])
    }
    var meses = [
      { "value": 1, "label": "01-ENERO" },
      { "value": 2, "label": "02-FEBRERO" },
      { "value": 3, "label": "03-MARZO" },
      { "value": 4, "label": "04-ABRIL" },
      { "value": 5, "label": "05-MAYO" },
      { "value": 6, "label": "06-JUNIO" },
      { "value": 7, "label": "07-JULIO" },
      { "value": 8, "label": "08-AGOSTO" },
      { "value": 9, "label": "09-SEPTIEMBRE" },
      { "value": 10, "label": "10-OCTUBRE" },
      { "value": 11, "label": "11-NOVIEMBRE" },
      { "value": 12, "label": "12-DICIEMBRE" },
    ]
    setMonths(meses);
  }


  async function Show() {
    setBandera(true)
    setData([])
    var tipo = 0;
    var Fmonth = 0;
    var user = 0;
    var Fyear = 0;
    if (type.value != undefined) {
      tipo = type.value;
    }
    if (buscar.value != undefined) {
      user = buscar.value;
    }
    if (anio?.value != undefined) {
      Fyear = anio?.value;
    }
    if (mes?.value != undefined) {
      Fmonth = mes?.value;
    }
    // console.log(user)
    const res = await Axios.GetRecepcionByAdmin({ urlParams: { id: user, type: tipo, year: Fyear, month: Fmonth } });
    // console.log(res.data[1])
    var arreglo = res.data;
    var name = "";
    arreglo.forEach(function (dato, indx) {
      var arreglo = {
        Tiempo: dato.time,
        Tipo: Tipo[dato.type],
        Codigo: dato.code,
        Button: (
          <Button onClick={() => setPercent(dato.code)} variant="primary">
            Ver Entrega
          </Button>
        )
      }
      setData(preData => [...preData, arreglo])
    });
  }

  async function Clean() {
    setBandera(true)
    setBuscar([])
    setType([])
    setMes([])
    setAnio([])
    setData([])
    var tipo = 0;
    var Fmonth = 0;
    var user = 0;
    var Fyear = 0;
    const datos = await Axios.GetRecepcionByAdmin({ urlParams: { id: user, type: tipo, year: Fyear, month: Fmonth } });
    var arreglo = datos.data;
    var name = "";
    arreglo.forEach(function (dato, indx) {
      var arreglo = {
        Tiempo: dato.time,
        Tipo: Tipo[dato.type],
        Codigo: dato.code,
        Button: (
          <Button onClick={() => setPercent(dato.code)} variant="primary">
            Ver Entrega
          </Button>
        )
      }
      setData(preData => [...preData, arreglo])
    });
  }

  const columns = [
    {
      name: <span>Fecha de Entrega</span>,
      sortable: true,
      selector: row => row.Tiempo
    },
    {
      name: <span>Tipo</span>,
      sortable: true,
      selector: row => row.Tipo
    },
    {
      name: <span>Codigo de Entrega</span>,
      sortable: true,
      selector: row => row.Codigo
    },
    {
      name: <span>Recepcion</span>,
      sortable: true,
      selector: row => row.Button
    }
  ]


  return <div>
    <h1>Listado de recepciones</h1>

    <div className="col-4 mb-3 form-group d-flex">
      <div className={styles.form}>

        <label htmlFor="Codigo"><b>Año:</b>
        </label>
        <Select
          name="codigo"
          options={years?.map((year) => ({
            label: year,
            value: year
          }))}
          value={anio}
          onChange={(e) => setAnio(e)}
          placeholder="Año"
          styles={{ control: (base) => ({ ...base, width: 200 }) }}
        />
      </div>
      <div className={styles.form}>

        <label htmlFor="Codigo"><b>Mes:</b>
        </label>
        <Select
          name="codigo"
          options={months?.map((month) => ({
            label: month.label,
            value: month.value
          }))}
          value={mes}
          onChange={(e) => setMes(e)}
          placeholder="Listado de Meses"
          styles={{ control: (base) => ({ ...base, width: 200 }) }}
        />
      </div>
      <div className={styles.form}>

        <label htmlFor="Codigo"><b>Usuarios:</b>
        </label>
        <Select
          name="codigo"
          options={users?.map((user) => ({
            label: user.first_name + " " + user.last_name,
            value: user.id
          }))}
          value={buscar}
          onChange={(e) => setBuscar(e)}
          placeholder="Listado de usuarios"
          styles={{ control: (base) => ({ ...base, width: 200 }) }}
        />
      </div>
      <div>
        <label htmlFor="tipo"><b>Tipo de entrega:</b>
        </label>
        <Select
          name="tipo"
          options={Types?.map((tipo) => ({
            label: tipo.name,
            value: tipo.code
          }))}
          value={type}
          onChange={(e) => setType(e)}
          placeholder="Todos los tipos"
          styles={{ control: (base) => ({ ...base, width: 200 }) }}
        />
      </div>
    </div>
    <Button className={styles.buttons} variant="primary" onClick={() => Show()} >Buscar</Button>
    <Button className={styles.buttons} variant="warning" onClick={() => Clean()} >Limpiar</Button>
    {(bandera == false) ?
      <div>
        <br /><h3>Cargando...{bandera}</h3>
      </div> : (data.length == 0||data.length == "0") ? 
      <h3>No hay entregas pendientes</h3> :
        <div>
          <DataTableWidget
            columns={columns}
            data={data}
          />
        </div>}


  </div>;

}