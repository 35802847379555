import FormElement from "common/components/FormElement/FormElement";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Container,Row,Col,Form,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {signin} from "modules/user/actions/userActions";
import AnimatedBlocks from "common/components/AnimatedIcons/AnimatedBlocks/AnimatedBlocks";
import { useSelector } from "react-redux";
import styles from "./LoginForm.module.scss";

export default function LoginForm(props){
  const dispatch = useDispatch();
  
  const user = useSelector( state => {
    return state.user;
  });
  const [formerrors,setFormErrors] = useState({});
  const [formData,setFormData] = useState({email:"",password:'', rememberMe:false});

  const onchangeInput = (e) =>{
    let newErrors = {formerrors};
    let newFormData = {...formData};
    newFormData[e.target.name] = e.target.value;
    if (newErrors.hasOwnProperty(e.target.name)) delete newErrors[e.target.name];
    setFormErrors(newErrors);
    setFormData(newFormData);
  }

  const validateData = () =>{
    let newErrors = {};
    setFormErrors(newErrors);
    if (formData.email.trim()==="") newErrors["email"] = "El email no puede estar vacío";
    /*else
      if (!String(formData.email).toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ))  newErrors["email"] = "E-mail no válido";*/
    if (formData.password.trim()==="") newErrors["password"] = "Por favor ingrese su password";
    setFormErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      return false;
    }
  }
  const submitHandler = (e) => {
    let result = null;
		e.preventDefault();
    if (validateData()){
        // aqui sign in      
        dispatch(signin(formData.email, formData.password, false));
       
    }
		/*setErrorData({
			emailError: data.email.trim() === '',
			passwordError: data.password.trim() === '',
		});
		if (errorData.emailError || errorData.passwordError) return;
		dispatch(signin(...Object.values(data)));*/
	};

    

    return <div>

<Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12}>
            
            
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2">Modulo de recepción de sistema de archivo</h2>
                  <p className=" mb-5">Por favor ingrese su usuario y contraseña</p>
                  <div className="mb-3">
                    {
                      (user.loginStatus.error)? <Alert variant="danger">{user.loginStatus.message}</Alert>:''
                    }
                    {
                      formerrors.__form_general_error && <Alert variant="danger">{formerrors.__form_general_error}</Alert>
                    }
                    <Form onSubmit={submitHandler}>
                      
                      <FormElement 
                          margin="1" 
                          id="formBasicEmail" 
                          label="Usuario" 
                          type="text" 
                          onChange={onchangeInput} 
                          name="email" 
                          error={formerrors.email} 
                          placeholder="Ingrese usuario"
                      />
                      
                      {/*<Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={onchangeInput} name="email" />
                        {
                           formerrors.email &&
                           <p className="text-danger">{formerrors.email}</p>
                        }
                      </Form.Group>*/}
                      
                      {
                        /*
                      <Form.Group
                        className="mb-3"
                        controlId="formPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={onchangeInput} name="password" />
                        {
                           formerrors.password &&
                           <p className="text-danger">{formerrors.password}</p>
                        }
                      </Form.Group>*/
                      }
                      <FormElement 
                          margin="1" 
                          id="formPassword" 
                          label="Contraseña" 
                          type="password" 
                          onChange={onchangeInput} 
                          name="password" 
                          error={formerrors.password} 
                          placeholder="Password"
                      />

                      <Form.Group
                        className="mb-3"
                        controlId="formCheckbox"
                      >
                        {/*<p className="small">
                          <a className="text-primary" href="#!">
                            ¿Olvidó su contraseña?
                          </a>
                    </p>*/}
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>
                    {
                      (user.loading == true)? <div className={styles.loadinglogin}> <small><AnimatedBlocks fill="#15e574" stroke="#666" /></small> Autenticando usuario... </div>: ''
                    }
                  </div>
                </div>
   
          </Col>
        </Row>
      </Container>


    </div>;
}