import React, { useEffect, useState, useRef } from "react";
import ApiURLs from "services/BackendApi/BackendApiURLs";
import styles from './TemporaryFileViewer.module.css';
import Axios from 'services/BackendApi/BackendApi';

export default function TemporaryFileViewer(props) {
    const { file, openingLabel, grantKey, variant, pdf } = props;
    const [grant, setGrant] = useState({ loading: false, key: null, error: false, error_log: [] });
    const iframeRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const iframe = iframeRef.current;
            console.log('Scrolled!');
            if (iframe && iframe.contentWindow) {
                const { scrollTop, scrollHeight, clientHeight } = iframe.contentWindow.document.documentElement;
                console.log('Scrolled!');
                console.log('ScrollTop:', scrollTop, 'ClientHeight:', clientHeight, 'ScrollHeight:', scrollHeight);
                if (scrollTop + clientHeight >= scrollHeight) {
                    console.log('Reached the end of the document');
                }
            }
        };

        const loadIframe = () => {
            const iframe = iframeRef.current;
            if (iframe && iframe.contentWindow) {
                iframe.contentWindow.addEventListener('scroll', handleScroll);
                console.log('Event listener for scroll added.');
            }
        };

        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', loadIframe);
            console.log('Iframe loaded');
        }

        return () => {
            const iframe = iframeRef.current;
            if (iframe) {
                iframe.removeEventListener('load', loadIframe);
                if (iframe.contentWindow) {
                    iframe.contentWindow.removeEventListener('scroll', handleScroll);
                    console.log('Event listener for scroll removed.');
                }
            }
        };
    }, []);

    async function requestGrant() {
        if (!grant.loading) {
            setGrant((prev) => ({ ...prev, loading: true }));
            try {
                const { data } = await Axios.RequestAccessToFile({ file: file });
                if (data.result === true) {
                    setGrant({ loading: false, key: data.grant.key, error: false, error_log: [] });
                } else {
                    setGrant({ loading: false, key: null, error: true, error_log: data.error_log || ['Error general.'] });
                }
            } catch (error) {
                setGrant({ loading: false, key: null, error: true, error_log: ['Error de red.'] });
            }
        }
    }

    useEffect(() => {
        if (grant.key === null && !grant.loading && !grant.error) {
            requestGrant();
        }
    }, [grant]);

    if (grant.key === null) {
        if (grant.error) {
            return (
                <div className={styles.viewwrapper}>
                    Hubo un error al abrir el archivo:
                    <ul>
                        {grant.error_log.map((oneError, idx) => (
                            <li key={idx}>{oneError}</li>
                        ))}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className={styles.viewwrapper}>
                    <div className={styles.labelwrapper}>
                        {openingLabel ? openingLabel : "Opening your file..."}
                    </div>
                </div>
            );
        }
    } else {
        if (variant === "download") {
            return <a href={`${ApiURLs.publicFilesURL}file/download/${grant.key}/${file}`} download>{openingLabel}</a>;
        } else {
            return (
                <div id="viewerContainer" className={styles.viewwrapper}>
                    <iframe
                        id="somePdfIframe"
                        ref={iframeRef}
                        className={styles.iframeviewer}
                        src={ApiURLs.publicFilesURL+`file/get-preview/${pdf}`}
                        onLoad={() => {
                            console.log('Iframe loaded');
                            const iframe = iframeRef.current;
                            if (iframe && iframe.contentWindow) {
                                console.log(iframe.contentWindow);
                                console.log(iframe.contentDocument.documentElement);
                                console.log(iframe.contentWindow.document.documentElement);
                                document.querySelector('#somePdfIframe').addEventListener('load', e => {
                                    console.log("a");
                                    e.target.contentWindow.addEventListener('scroll', e => {
                                        console.log('scrollin');
                                    });
                                });
                                console.log('Event listener for scroll added.');
                            }
                        }}
                    />
                </div>
            );
        }
    }
}
