import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './RetornoLoteView.module.scss';
import Axios from 'services/BackendApi/BackendApi';
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { Row,Col } from "react-bootstrap";

import { useState } from "react";
import { Button } from "react-bootstrap";
import { SkipBackward } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Download } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";

export default function RetornoLoteView(props){
    const navigate = useNavigate();
    
    const {loteID} = useParams();
    const dispatch = useDispatch();
    const [loteData,setLoteData] = useState({loading: true , data: null});
    const [loteErrors,setLoteErrors] = useState([]);
    const [loteReturnErrors,setLoteReturnErrors] = useState({status:false,errors:[]});
    const [loteReturnData,setLoteReturnData] = useState({loading: true, confirm: false , data: null});
    async function processTx(){
      let toStore = [];
      try {
         const { data } = await  Axios.DetalleLote({lote: loteID});
         if (data.error){
            //console.log('Error,',data);
            setLoteErrors(data.error_log);
         }
         if (data.result && !data.error){
            //console.log('Vino la data',data);
            if (data.query_result[0].result.length>0){
              toStore['lote'] = data.query_result[0].result[0];
            }
            if (data.query_result[1].result.length>0){
              toStore['detalle'] = [];
              data.query_result[1].result.map((oneDoc,idx)=>{
                if (!toStore.detalle['dossier_'+oneDoc.dossier_id]){
                  toStore['detalle']['dossier_'+oneDoc.dossier_id] = {
                    dossier_data: JSON.parse(oneDoc.dossier_data).post,
                    dossier_id: oneDoc.dossier_id,
                    dossier_name: oneDoc.dossier_name,
                    documents: [
                      {
                        description: oneDoc.description,
                        type_label: oneDoc.document_type_label,
                        type: oneDoc.document_type_code,
                        data: JSON.parse(oneDoc.data)
                      }
                    ]
                  }
                }else{
                  toStore['detalle']['dossier_'+oneDoc.dossier_id].documents[toStore['detalle']['dossier_'+oneDoc.dossier_id].documents.length] = {
                    description: oneDoc.description,
                    type_label: oneDoc.document_type_label,
                    type: oneDoc.document_type_code,
                    data: JSON.parse(oneDoc.data)
                  };
                }
              });
            }
            //console.log('toStore lote',toStore);
            setLoteData({loading:false, data:toStore});
         }
      }catch(error){           
          //console.log('catch Error',error);
      }
        
    }
    async function processTxRetornar(){
      let toStore =[];
      try {
         const { data } = await  Axios.RetornarLote({lote: loteID});
         if (data.error){
            //console.log('Error,',data);
            setLoteReturnErrors({status: true, errors: data.error_log});
         }
         if (data.result && !data.error){
            //console.log('Vino la data',data);
            if ((data.freeSQL_result.result.length>0)&&(data.freeSQL_result.result[0].status==501)){
              toStore =   data.freeSQL_result.result[0];
            }
            //console.log('toStore lote Returned',toStore);
            setLoteReturnData({loading:false, data:toStore});
         }
      }catch(error){           
          //console.log('catch Error',error);
      }
        
    }
    useEffect(() => {
      //dispatch(tramitePago());
      //dispatch(getDossierStatus());
      processTx();
    }, [dispatch]);

    function switchStatus(){
      switch (loteData.data.lote.status){
        case 0: {return <div className={styles.statuspill+" "+styles.statuscreado}>CREADO, PENDIENTE DE ENTREGA</div>}break;
        case 1: {return <div className={styles.statuspill+" "+styles.statusentregado}>ENTREGADO</div>}break;
        case 2: {return <div className={styles.statuspill+" "+styles.statusretornado}>RETORNADO POR ARCHIVO</div>}break;
        case 3: {return <div className={styles.statuspill+" "+styles.statuseliminado}>ELIMINADO</div>}break;
        case 501: {return <div className={styles.statuspill+" "+styles.statuseliminado}>DESCARTADO, RETORNADO POR EL USUARIO</div>}break;
      }
    }

    function switchModalContent(){
      if (loteReturnData.confirm){
        return <div> ¿Está seguro de retornar el lote No. {loteID}?</div>;
      }
      if (loteReturnErrors.status){
        //hay errores
          <div>
              <ErrorMark />
              <br />
              <div>
                No se pudo retornar el lote, Los errores encontrados fueron:
                <ul>
                  {
                    loteReturnErrors.errors.map((onee,idx)=><li key={idx}>{onee}</li>)
                  }
                </ul>
              </div>
          </div>
      }else{
        if ((loteReturnData.data!==null)&&(loteReturnData.data.status==501))
        return <div>
              <CheckMarkSuccess /><br />
              <div className={styles.modalcenter}>Lote '{loteReturnData.data.lote_id}'' retornado con éxito</div>
        </div>;
        else 
        return 
          <div>
              <ErrorMark /><br />
            <div>Error insesperado en la transacción.</div>
          </div>;
      }
    }

    if (loteErrors.length>0){
      return <div className={styles.viewwrapper}>
          <ul>
            {loteErrors.map((err,idx)=><li key={idx}>{err}</li>)}
          </ul>
      </div>;
    }
    return (!loteData.loading)?<div className={styles.viewwrapper}>

      {
				(!loteReturnData.loading || loteReturnData.confirm)? 
				
							
						<Modal show={(!loteReturnData.loading || loteReturnData.confirm)} onHide={()=>{navigate(-1)}}  backdrop="static" keyboard={false}>
							<Modal.Header closeButton={true}>
								<Modal.Title>Retorno de Lote {loteID}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{
									switchModalContent()
								}
							</Modal.Body>
              
							{ 
                (loteReturnData.confirm)?
                    <Modal.Footer>
                                    <Button variant="secondary" onClick={()=>{setLoteReturnData({...loteReturnData,confirm:false})}}>
                                        Cancelar
                                    </Button>
                                    <Button variant="danger" onClick={()=>{setLoteReturnData({...loteReturnData,confirm:false});processTxRetornar()}}>
                                        Sí, proceder
                                    </Button>
                  </Modal.Footer>:''
							} 
						
						</Modal>
						
				:''
			}



                <div>
                  <Button onClick={() => navigate(-1)}><SkipBackward /> Regresar</Button> 
                  
                  {
                    (loteData.data.lote.status==0)?
                      <Button variant="danger"  onClick={() => setLoteReturnData({...loteReturnData,confirm:true})}><Download /> Retornar definitivamente a ventanilla</Button>:''

                  }
                </div>

          <hr />
          <h3>Lote {loteID}</h3>
          <Container>
            <Row>
                <Col xs={2} className={styles.label}>ID de lote</Col>
                <Col>{loteData.data.lote.lote_id}</Col>
            </Row>
            <Row>
                <Col xs={2} className={styles.label}>Fecha y hora de creación</Col>
                <Col>{loteData.data.lote.created_on}</Col>
            </Row>
            <Row>
                <Col xs={2} className={styles.label}>Estatus</Col>
                <Col>{switchStatus()}</Col>
            </Row>
          </Container>
          <hr />
          <h3>Detalle del lote</h3>
          <Container>
              {
                Object.keys(loteData.data.detalle).map(
                  (onekey,idk)=>
                    <Row key={idk}>
                      <Col>
                        <h5>Trámite No. {loteData.data.detalle[onekey].dossier_id} - {loteData.data.detalle[onekey].dossier_name}</h5>
                        <Container>
                            <Row>
                                <Col xs={3} className={styles.label}>Documentos:</Col>
                                <Col>
                                    <ol>
                                      {
                                        loteData.data.detalle[onekey].documents.map((onedoc,iddoc)=>
                                          <li key={iddoc}>{onedoc.type_label}<br />{onedoc.description}</li>
                                        )
                                      }
                                    </ol>
                                </Col>
                            </Row>
                        </Container>
                      </Col>
                    </Row>
                  )
              }
          </Container>

    </div>:'Cargando ...';
}