import React, { useEffect, useState } from "react";
import ApiURLs from "services/BackendApi/BackendApiURLs";
import styles from './FileViewer.module.css';
import Axios from 'services/BackendApi/BackendApi';
export default function FileViewer(props){
    const {file,openingLabel,grantKey, variant} = props;
    const [grant,setGrant] = useState({loading:false, key:null, error:false, error_log:[]});
    useEffect(
        ()=>{
            if (grantKey){
                setGrant({...grant,loading:false, key:grantKey});
            }else{
                requestGrant();
            }
        },[]
    )
    async function requestGrant(){
        if (grant.loading===false){
            const {data} = await Axios.RequestAccessToFile({file:file});
            // console.log(ApiURLs.publicFilesURL)
            if (data.result===true){
                setGrant({loading:false, key:data.grant.key, error:false, error_log:[]});
            }else{
                if (data.error===true){
                    setGrant({loading:false, key:null, error:true, error_log:data.error_log});
                }else{
                    setGrant({loading:false, key:null, error:true, error_log:['Error general.']});
                }
            }
        }
    }
    //console.log("Antes de return grant:",grant);
    if (grant.key===null)
        if (grant.error===true){
            return <div className={styles.viewwrapper}>
                    Hubo un error al abrir el archivo:
                    <ul>
                        {
                            grant.error_log.map((oneError,idx)=>{
                                return <li key={idx}>{oneError}</li>
                            })
                        }
                    </ul>
            </div>;
        }else{
            return <div className={styles.viewwrapper}>
                        <div className={styles.labelwrapper}>
                        {
                            (openingLabel)?
                                openingLabel
                                :
                                "Opening your file..."
                            
                        }
                        </div>
                    </div>;
        }
    else{
        if (variant==="download")
        {
            return <a href={ApiURLs.publicFilesURL+'file/download/'+grant.key+"/"+file} download>{openingLabel}</a>
        }
        else
        return <div className={styles.viewwrapper}><iframe className={styles.iframeviewer} src={ApiURLs.publicFilesURL+'file/get/'+grant.key+"/"+file} /></div>;
    }
}