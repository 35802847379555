import React, { useEffect } from "react";

import styles from './DetalleTramiteView.module.scss';
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import Axios from 'services/BackendApi/BackendApi';
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import DossierDataTable from "./DossierDataTable/DossierDataTable";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { SkipBackward } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";
import { Download } from "react-bootstrap-icons";


export default function DetalleTramiteView(props) {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {dossierID} = useParams();
	const [dossierData, setDossierData] = useState({loading:true,data:[]});
	const [deleteDossier, setDeleteDossier] = useState({dialog:false,tx:0});
	const [txDataError, setTxDataError] = useState([]);
	async function confirmDelete(){
		setDeleteDossier({...deleteDossier,tx:1})
		try {
			const { data } = await  Axios.EliminarTramite({dossier_id: dossierID});
			if (data.error){
			   //console.log('Error,',data);
			   setDeleteDossier({dialog:true,tx:3})
			   setTxDataError(data.error_log);
			}
			if (data.result && !data.error){
				setDeleteDossier({dialog:true,tx:2})
			}
		}catch (error){
			setDeleteDossier({dialog:false,tx:0})
		}
	}

	async function processTx(){
       		  try {
                const { data } = await  Axios.DetalleDossier({dossier: dossierID});
                if (data.error){
                   //console.log('Error,',data);
                }
                if (data.result && !data.error){
                    //console.log('Good',data);
					let docsInfo = [];
					let docsMd = [];
					let dossierMd = {};
					let dossierLot  = {}
					data.query_result[1].result.forEach(
						element => {

								let fieldValue = null;

								switch (element.dossier_metadata_type){
									case "string":{
										fieldValue = element.string_value;
									}break;
									case "boolean":{
										fieldValue = (element.boolean_value==1)? true: false;
									}break;
									case "date":{
										fieldValue = element.date_value;
									}break;
									case "blob":{
										fieldValue = element.blob_value;
									}break;
									case "integer": case  "int":{
										fieldValue = element.int_value;
									}break;
									case "currency": {
										fieldValue = (typeof(element.currency_value)=="string")? parseFloat(element.currency_value): element.currency_value;
									} break;
									case "percentage": {
										fieldValue = element.percentage_value;
									} break;
									case "timestamp": {
										fieldValue = element.timestamp_value;
									} break;
								}
								
								if (typeof(dossierMd[element.dossier_metadata_code])=="undefined") dossierMd[element.dossier_metadata_code]={};
								dossierMd[element.dossier_metadata_code]={
									"key" : element.dossier_metadata_code,
									"label" : element.dossier_metadata_label,
									"value" : fieldValue
								};
							}
						
					);
					//console.log('dossier MD', dossierMd);
					data.query_result[3].result.forEach(
						element => {

								let fieldValue = null;

								switch (element.document_metadata_type){
									case "string":{
										fieldValue = element.string_value;
									}break;
									case "boolean":{
										fieldValue = (element.boolean_value==1)? true: false;
									}break;
									case "date":{
										fieldValue = element.date_value;
									}break;
									case "blob":{
										fieldValue = element.blob_value;
									}break;
									case "integer": case  "int":{
										fieldValue = element.int_value;
									}break;
									case "currency": {
										fieldValue = (typeof(element.currency_value)=="string")? parseFloat(element.currency_value): element.currency_value;
									} break;
									case "percentage": {
										fieldValue = element.percentage_value;
									} break;
									case "timestamp": {
										fieldValue = element.timestamp_value;
									} break;
								}
								let posMd = element.document;
								if (typeof(docsMd[posMd])=="undefined") docsMd[posMd]={};
								docsMd[posMd][element.document_metadata_code]={
									"key" : element.document_metadata_code,
									"label" : element.document_metadata_label,
									"value" : fieldValue
								};
							}
						
					);
					
					data.query_result[2].result.forEach(
						element => {
								docsInfo[docsInfo.length] = {
									...element,
									'metadata': (undefined !== docsMd[element.id])?docsMd[element.id]: {}
								};
								
							}
						
					)
					if (data.query_result[4].result.length>0){
						dossierLot = data.query_result[4].result[0];
					}

					//console.log('docs Info', docsInfo);
					let toStore = data.query_result[0].result[0];
					toStore['metadata'] = dossierMd;
					toStore['documents'] = docsInfo;
					toStore['lot'] = dossierLot;
					//console.log('To store:',toStore);
					setDossierData({loading:false,data:toStore});
                }	
            }catch(error){
               
				//console.log('catch Error',error);
            }
        
    }
	useEffect(() => {
		//dispatch(tramitePago());
		//dispatch(getDossierStatus());
		processTx();
	}, [dispatch]);

	function switchModalContent(){
		switch (deleteDossier.tx){
			case 0:{return "OJO!: Esta acción NO PUEDE SER REVERTIDA.";}break;
			case 1:{return <div>
				Procediendo con la eliminación...
			</div>;}break;
			case 2:{return <div>
				<CheckMarkSuccess />
				<h3>El trámite ha sido eliminado con éxito!</h3>
			</div>;}break;
			case 3:{
				return <div>
					<ErrorMark />
						<h3>Hubo un error al eliminar este trámite </h3>
						<h4>Los errores detectados fueron:</h4>
						<ul>
							{txDataError.map((error_msg,idx)=>{
								return <li key={idx}>{error_msg}</li>;
							})}
						</ul>
				</div>;
			}break;
		}
	}
	return (
		<div className={styles.container}>
			{
				(deleteDossier.dialog)? 
				
							
						<Modal show={deleteDossier.dialog} onHide={()=>{setDeleteDossier({dialog:false,tx:0});}}  backdrop="static" keyboard={false}>
							<Modal.Header closeButton={deleteDossier.tx==3}>
								<Modal.Title>¿Está seguro de Eliminar el trámite No. {dossierData.data.id} - "{dossierData.data.name}"</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{
									switchModalContent()
								}
							</Modal.Body><Modal.Footer>
							{ (deleteDossier.tx==0)?
									<>
                                    <Button variant="secondary" onClick={()=>{setDeleteDossier({dialog:false,tx:0})}}>
                                        Regresar
                                    </Button>
                                    <Button variant="danger" onClick={()=>{confirmDelete()}}>
                                        Sí, eliminar
                                    </Button>
                           </>: 
						   		(deleteDossier.tx==2)?
								   <Button variant="secondary" onClick={()=>{ navigate("/digitalizacion/pendientes",true)}}>
								   		Finalizar
							   		</Button>:''
							} </Modal.Footer>
						</Modal>
						
				:''
			}
            <div>
				<Button onClick={() => navigate(-1)}><SkipBackward /> Regresar</Button> 
				<Button variant="danger" disabled={dossierData.data.status==1} onClick={() => setDeleteDossier({dialog:true,tx:0})}><TrashFill /> Eliminar</Button>
				{
					(dossierData.data.status==1)?
						<Button variant="warning"  onClick={() => navigate('/digitalizacion/retorno-lote/'+dossierData.data.lot.lote_id)}><Download /> Retornar lote a ventanilla</Button>:''

				}
			</div>
			{
				(dossierData.loading)? "Cargando detalle...": <DossierDataTable data={dossierData.data} />
			}
			
		</div>
	);
}
