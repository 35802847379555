import React from "react";

import RecepcionSideBarOptions from './RecepcionRoutes/RecepcionMenuOptions';
import DefaultSideBarOptions from './DefaultRoutes/DefaultMenuOptions';

var AppMenuOptions =[];
AppMenuOptions = AppMenuOptions.concat(DefaultSideBarOptions);
AppMenuOptions = AppMenuOptions.concat(RecepcionSideBarOptions);


export default AppMenuOptions;