import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from './EntregaView.module.scss';
import { Button } from "react-bootstrap";
import { Check2Square, ArrowLeft } from "react-bootstrap-icons";
import CargaLibroContableForm from "../../Forms/CargaLibroContableForm/CargaLibroContableForm";
import Axios from 'services/BackendApi/BackendApi';
import Modal from 'react-bootstrap/Modal';

export default function EntregaView(props) {
    const navigate = useNavigate();
    const [name, setName] = useState([]);
    const [count, setCount] = useState([]);
    const [open, setOpen] = useState(true);
    const {ID} = useParams();

    const modalClose = () => setOpen(false);
    function Regresar() {
        navigate("/", true);
    }

    useEffect(() => {
        getName(ID);
    }, []);


    async function getName(id) {
        let res = await Axios.GetNameDelivery({ urlParams: { id } });
        console.log(res);
        setName(res.data.data[0].code);
        setCount(res.data.data[0].size);
        // setName('PRUEBA');
        // setCount(5);
        setOpen(true);
    }


    return <div className={styles.contenedor}>
        <div className={styles.centrar}>
            {/* <Heading variant="h2" override>
                {"Entrega"}
            </Heading> */}
            <Check2Square size={80} color="#2874a6" />
            <h2>Entrega realizada {name}</h2>
            <br /><br />
            <Button onClick={Regresar} > <ArrowLeft /> Regresar</Button>
        </div>

        <Modal backdrop="static" keyboard={false} show={open} onHide={modalClose} dialogClassName={styles.alerta}>
            <Modal.Header className={styles.header}>
                <Modal.Title style={{ color: 'white' }}>Entrega Realizada</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.columna}>
                <div>
                    <h4>
                        Muchas gracias por realizar la entrega, esta fue enviada a revisión por archivo
                    <br />
                    </h4>
                    <h4>
                        Registros enviados: {count}
                    </h4>
                </div>
                <div className={styles.buttons}>

                    <Button onClick={() => setOpen(false)}>Aceptar</Button>
                </div>

            </Modal.Body>
        </Modal>
    </div>;
}