import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from './SettingsRecepcionView.module.scss';
import { Button } from "react-bootstrap";
import { Check2Square, ArrowLeft } from "react-bootstrap-icons";
import CargaLibroContableForm from "../../Forms/CargaLibroContableForm/CargaLibroContableForm";
import Axios from 'services/BackendApi/BackendApi';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default function EntregaView(props) {
    const navigate = useNavigate();
    const [valueC, setValueC] = useState(10);
    const [valueLC, setValueLC] = useState(10);
    const [valueND, setValueND] = useState(10);
    const [valueCL, setValueCL] = useState(10);
    const [valueFC, setValueFC] = useState(10);
    const [valueEC, setValueEC] = useState(10);
    const [open, setOpen] = useState(false);

    const modalClose = () => setOpen(false);

    const handleSliderChange = (event) => {
        const newValue = Number(event.target.value);
        setValueC(newValue);
    };
    const handleSliderChangeLC = (event) => {
        const newValue = Number(event.target.value);
        setValueLC(newValue);
    };
    const handleSliderChangeND = (event) => {
        const newValue = Number(event.target.value);
        setValueND(newValue);
    };
    const handleSliderChangeCL = (event) => {
        const newValue = Number(event.target.value);
        setValueCL(newValue);
    };
    const handleSliderChangeFC = (event) => {
        const newValue = Number(event.target.value);
        setValueFC(newValue);
    };
    const handleSliderChangeEC = (event) => {
        const newValue = Number(event.target.value);
        setValueEC(newValue);
    };

    useEffect(() => {
        getValues()
    }, []);

    async function UpdateValues() {
        let array = ["CIERRES", "LIBROS", "NOTAS", "CONCI", "FACTURAS", "EC"];
        let values = [valueC, valueLC, valueND, valueCL, valueFC, valueEC];
        for (let i = 0; i < array.length; i++) {
            await Axios.setPorcentaje({ urlParams: { code: array[i], count: values[i] } });
        }
        getValues();
        setOpen(true);
    }
    function Reset() {
        getValues()
    }

    async function getValues() {
        var cantidades = await Axios.setPorcentaje({ urlParams: { code: null, count: null } });
        for (let i = 0; i < cantidades.data.length; i++) {
            if (cantidades.data[i].code == "CIERRES") {
                setValueC(cantidades.data[i].percent)
            } else if (cantidades.data[i].code == "LIBROS") {
                setValueLC(cantidades.data[i].percent)
            } else if (cantidades.data[i].code == "NOTAS") {
                setValueND(cantidades.data[i].percent)
            } else if (cantidades.data[i].code == "CONCI") {
                setValueCL(cantidades.data[i].percent)
            } else if (cantidades.data[i].code == "FACTURAS") {
                setValueFC(cantidades.data[i].percent)
            } else if (cantidades.data[i].code == "EC") {
                setValueEC(cantidades.data[i].percent)
            }
        }

    }


    return <div >
        <h2>Configuración del porcentaje de revisión</h2>
        <br /><br />
        <Form>
            <Form.Group controlId="Cierres" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Cierres Bancarios: {valueC}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueC} onChange={handleSliderChange} min={10} max={100} />
            </Form.Group>
            <Form.Group controlId="Libros" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Libros Contables: {valueLC}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueLC} onChange={handleSliderChangeLC} min={10} max={100} />
            </Form.Group>
            <Form.Group controlId="Notas" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Notas de débito: {valueND}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueND} onChange={handleSliderChangeND} min={10} max={100} />
            </Form.Group>
            <Form.Group controlId="Conci" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Conciliaciones: {valueCL}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueCL} onChange={handleSliderChangeCL} min={10} max={100} />
            </Form.Group>
            <Form.Group controlId="Facturas" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Facturas: {valueFC}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueFC} onChange={handleSliderChangeFC} min={10} max={100} />
            </Form.Group>
            <Form.Group controlId="EC" className={styles.slide}>
                <Form.Label>
                    <h3>
                        Estados de cuenta: {valueEC}%
                    </h3>
                </Form.Label>
                <Form.Range value={valueEC} onChange={handleSliderChangeEC} min={10} max={100} />
            </Form.Group>
            <br />
            <div className={styles.buttons}>
            <Button onClick={() => UpdateValues()}>Guardar</Button>
            <Button  onClick={() => Reset()} variant="warning">Deshacer Cambios</Button>
            </div>
        </Form>
        <Modal show={open} onHide={modalClose} dialogClassName={styles.alerta}>
            <Modal.Header closeButton className={styles.header}>
                <Modal.Title style={{ color: 'white' }}>Actualizado</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.columna}>
                <div>
                    <h5>
                        La Configuración se actualizó correctamente, estos cambios aplican para las entregas posteriores
                    </h5>
                </div>

            </Modal.Body>
        </Modal>
    </div>;
}