import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";
import { formatCurrency } from "common/helpers/DataFormat";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styles from './DigitalizacionLoteModal.module.scss';

export default function DigitalizacionLoteModal(props){
    const {data,show,onClose,switchShow,onProceed,success,errors} = props;
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(retrieveCatalogs()); 
    },[dispatch]);

    const {catalogs} = useSelector((state)=> {
        return state;
    });
    function renderDocuments(dossier){
        
        return <>
                <br />
                <ol>
                {
                    dossier.documents.map((doc,idx)=>{
                        
                        if ((doc.type==5)||(doc.type==1)){
                        return  <li key={idx} className={styles.attention}>
                                    
                                                    {doc.description}
                            </li>;
                        }else{
                            return;
                        }
                    })
                }
                {
                    dossier.documents.map((doc,idx)=>{
                        
                        if ((doc.type!==5)&&(doc.type!==1)){
                        return  <li key={idx} className={styles.attention}>
                                    
                                                    {doc.doctype_name}
                            </li>;
                        }else{
                            return;
                        }
                    })
                }
                </ol>
                </>;
    }
    function showEnterpriseData(enterpriseCode){
        const thisEnterprise = catalogs.enterprises.filter( ent => ent.erp_code1==enterpriseCode || ent.erp_code2 == enterpriseCode );
        if (catalogs.loading||thisEnterprise.length==0) return "...";
        
        return <>
                {thisEnterprise[0].tax_payer_id} - {thisEnterprise[0].name}
        </>;

    }
    //console.log("Catalogs>",catalogs);
    function switchModalBody(){
        if (null==success){
            if(errors.length>0){
                return <div key={'switchModalBody'}>
                
                <ErrorMark />
                <div>
                    Se han presentado los siguientes errores.
                    <ul>
                        {
                            errors.map((erroru,idx)=>{
                                return <li key={idx}>{erroru}</li>;
                            })
                        }
                    </ul>
                </div>
            </div>;
            }else{

                return <>
            <div key="linea001">Por favor confirme que cuenta con los siguientes trámites de pago y sus respectivos documentos para envío a digitalización:</div>
            
            <div key="linea002">
                {
                    data.map((selectedRow,idx)=>{
                        return <Container key={idx} className={styles.oneitemlote}>
                                <Row>
                                    <Col><span className={styles.notramite}>No.{selectedRow.dossier}</span> - <span className={styles.attention}>{selectedRow.description}</span></Col>
                                </Row>
                                <Row>
                                    <Col className={styles.tab}>
                                        {console.log("ROW SELECCIONADO>",selectedRow)}
                                        <span className={styles.notramite}>Emitido a la empresa: <span className={styles.attention}>{selectedRow.metadata.DOSSIER_ENTERPRISE} 
                                            - {
                                                showEnterpriseData(selectedRow.metadata.DOSSIER_ENTERPRISE)
                                            }</span>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={styles.tab}>
                                        <Row>
                                                <Col>Documentos Incluídos </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <Container>
                                                <Row>
                                                    
                                                    <Col>   
                                                        <ol>
                                                            {/*
                                                                (selectedRow.data.reporte_servicios=="1")?
                                                                    <li key="reportegen">Reporte de servicios general para todos los documentos</li>:''
                                                            */
                                                            }
                                                            {
                                                                renderDocuments(selectedRow)
                                                            }
                                                        </ol>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        </Container>;
                    })
                }
            </div></>;


            }


        }else{
            return <div key="successkey">
                {/* aqui si fue exitoso el lote*/}
                <CheckMarkSuccess />
                <div className={styles.loteok}>
                    Lote creado correctamente.
                    <div>Su lote es: <h3 className={styles.attention}>{success.lote_id}</h3></div>
                    <div>Por favor, proceda a llevar los documentos a ventanilla de Archivo.</div>
                </div>
            </div>;
        }
    }



    if (show===true){
    return <Modal show={show===true} onHide={()=>{onClose()}}  backdrop="static"  keyboard={false}>
        <Modal.Header closeButton={true}>
            <Modal.Title>Confirmación de lote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {switchModalBody()}
        </Modal.Body>
        <Modal.Footer>
        
                                    <Button variant="secondary" onClick={()=>{switchShow(false); onClose();}}>
                                        Regresar
                                    </Button>
                                    { ((null==success))?
                                        <Button variant="primary" onClick={()=>{onProceed()}}>
                                            Sí, proceder
                                        </Button>
                                        :
                                        ''
                                    }
        </Modal.Footer>
    </Modal>;
    }
    else
        return "";
}