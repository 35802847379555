
export function formatCurrency(currency,amount){
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(amount);
}
export function formatPercentage (value,decimals){
  return Math.trunc(value*100 * 100)/100 + "%";
}
export function formatBytes(bamt,measure){
  let factor=1;
  let label = " Bytes";
  switch (measure){
    case "MB":{
      label = " Megabytes";
      factor = 1024*1024;
    }break;
    case "KB":{
      factor = 1024;
      label = " Kilobytes";
    }break;
    case "GB":{
      factor = 1024*1024*1024;
      label = " Gigabytes";
    }break;

  }
  return Math.round((bamt/factor) * 100) / 100 + label;
}