import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './CargaLoteView.module.scss';
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";
import FileUploader from "common/components/FileUploader/FileUploader";
import { Download } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";


export default function CargaLoteView(props) {
    const { type } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(retrieveCatalogs());
    }, [dispatch]);

    const { catalogs } = useSelector((state) => {
        return state;
    });

    // if (!["factura","recibo"].includes(type)) return <div className={styles.viewwrapper}>
    //         Error. 
    //     </div>;

    return <div className={styles.viewwrapper}>
        <div>

            <Heading variant="h2" override>
                {"Recepcion de: Libros contables por lote"}
            </Heading>
            {<div>
                <h5 className={styles.text}>Por favor, suba su archivo ZIP con los documentos y su indice</h5>
                <FileUploader dropLabel={<>Arrastre y suelte su archivo ZIP<br /> o haga click acá para ubicar</>} callBack={UploadDocumentCallback} acceptOnly=".pdf" ></FileUploader>
            </div>
            }
        </div>
    </div>;

    function UploadDocumentCallback(type, data) {
        switch (type) {
            case "UPLOAD_SUCCESSFULL": {
                console.log("onUploadDocumentCallback>", data);
                navigate("/libros/carga-lote/" + data.id, true);
            } break;
        }
    }
    
}