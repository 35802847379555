import React from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import { BoxArrowRight } from "react-bootstrap-icons";
import { Person } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
export default function Topbar(props){
  const {sidebarStatus,style} = props;
  let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;
    return <Navbar>
            {
              (sidebarStatus===false)?
              <div>
                <img
                    src={props.logo}
                    width="10%"
                    height="auto"
                    className="d-inline-block align-top"
                    alt={props.logoalt? props.logoalt: ''}
                />
              </div>
              :""
            }
            <Navbar.Collapse className={"justify-content-end"+" "+style.textWhite}>
                <Navbar.Text>
                  <Dropdown size="sm"  align="end">
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                    {userLocal.account_data?.name} <Person />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item  as={Link}  to="/user/profile">
                        {userLocal.account_data?.name} <br />
                        Perfil
                      </Dropdown.Item>
                      
                      <Dropdown.Divider />
                      <Dropdown.Item  as={Link}  to="/user/logout">
                        <BoxArrowRight /> Cerrar sesión
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Text>
            </Navbar.Collapse>
  </Navbar>;
}