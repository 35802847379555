/*
    Este componente permite que se envien los 3 elementos:
    1.- Sidebar 
    2.- Topbar
    3.- Content

*/

import React, { useState } from "react";
import {Container,Row,Col, Button} from "react-bootstrap";


import styles from './SideMenuTopBarLayout.module.scss';

import logo from 'assets/img/GLIFOS-moon.png';
// import logo from 'assets/img/magdalena_logo.svg';
import SideMenu from "./SideMenu/SideMenu";
import Topbar from "./Topbar/Topbar";
import sidebaroptions from "routes/AppMenuOptions";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import { ArrowRightCircle } from "react-bootstrap-icons";

export default function SideMenuTopBarLayout(props){
    
    const {classes} = props;
    const [sidebarStatus, setSidebarStatus] = useState(true);
    let extraClassNames = "sm-layout-wrapper ";
    if (classes instanceof Array){
        extraClassNames+= classes.join(" ");
    }
    function displayToggle(){
      setSidebarStatus((previousState)=> !previousState);
    }
    return  <Container className={styles.container+ " g-0 "+extraClassNames} fluid>
                <Row className={styles.mainrow+ " g-0"}>
                    <Col xs={2}  className={styles.sidebarBackground + " "+((sidebarStatus==true)?styles.sidebarwrapper:styles.sidebarwrappercollapsed) + " d-flex flex-column flex-shrink-0 p-3 text-white  bxg-dark"}>      
                    {/* <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example" tabindex="0"> */}

                        <SideMenu logo={logo} logoalt="Tesorería Ingenio Magdalena" options={sidebaroptions} displayed={sidebarStatus} onDisplayClick={displayToggle}/>
                    {/* </div> */}

                        
                            {
                            (sidebarStatus===true)?
                            <div className={(sidebarStatus===true)?styles.collpasebuttondisplayed:styles.collpasebuttonhidden}>
                          <Button variant="secondary" onClick={displayToggle}><><ArrowLeftCircle /> Collapsar menú</></Button>
                        </div>
                            :
                            ""
                            } 
                            
                    </Col>
                    <Col  xs={10} className={(sidebarStatus===true)?styles.pagecontentcolumn:styles.pagecontentcolumnsidebarhidden}>
                      <div className={styles.navbarwrapper+" "+styles.sidebarBackground}>
                        <Topbar logo={logo} sidebarStatus={sidebarStatus} style={styles} /> 
                      </div>
                      <div className={styles.pagecontentwrapper}>
                        <React.Suspense fallback={<div>Loading...</div>}>
                        {props.children}
                        </React.Suspense>
                      </div>
                      
                      {
                            (sidebarStatus===true)?
                            ""
                            :
                            <div className={styles.insidecontentdbutton}><Button variant="secondary" onClick={displayToggle} alt="Desplegar menú" title="Desplegar menú"><ArrowRightCircle /></Button></div>
                      } 
                      
                    </Col> 
                </Row>
            </Container>
    ;
}