
import { useSelector } from 'react-redux';
import Axios from 'services/BackendApi/BackendApi';
import { signInFailed, signInRequest, signInUser, signOutUser } from '../services/Redux/userSlice';
import {frontEndBaseUrl} from 'services/BackendApi/BackendApiURLs';
export const signin = (username, password, remember) => async (dispatch) => {
	
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const returnTo = params.get('returnTo');

	dispatch(signInRequest());

	try {
        //console.log(username,password,remember);
		const { data } = await Axios.UserLogin({
			username,
			password,
			remember,
		});
		if (data.success) {
			dispatch(signInUser(data));
			localStorage.setItem('userInfo', JSON.stringify(data));
			Axios.resetService();
			if (returnTo){
				window.location.href=returnTo;
				window.location.href=frontEndBaseUrl+"/"+returnTo; // The router should handle this default route
			}else{
				window.location.href=frontEndBaseUrl+"/"; // The router should handle this default route
			}
		} else {
			dispatch(signInFailed(data.message));
            return data;
		}
	} catch (error) {
		dispatch(signInFailed(
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		));
	
        return error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
	}
};

export const signout = () => async (dispatch) => {
	dispatch(signOutUser());
	localStorage.removeItem('userInfo');
	return true;
}

export const memberof = (groups) => {
	let userLocal = localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null;

	let toCheck = (Array.isArray(groups))? groups: [groups];
	let result = false;
	//console.log(toCheck,userLocal.roles)
	if (null!==userLocal){
		userLocal.roles.map((oneGroup,idx)=>{
			result = (toCheck.includes(oneGroup))?true:result;
			//console.log("eval>",result,oneGroup,toCheck.includes(oneGroup));
		});
	}
	return result;
}