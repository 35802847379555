import React from "react";
import './DataTableWidget.css';
import DataTable from "react-data-table-component";
import DataTableWidgetPagination from "./DataTableWidgetPagination/DataTableWidgetPagination";
import DataTableCheckboxCell from "./DataTableCheckboxCell/DataTableCheckboxCell";
import SpinnerIcon from 'assets/img/loading'

export default function DataTableWidget(props){

    const {columns, data, title, handleSelection, selectableRowDisabled,conditionalRowStyles} = props;
    let clear = ((!data)||(data==null)||(!Array.isArray(data))||(data.length==0))?true:false;
    //console.log('The data on table clear_',clear, data);

    const CustomNoDataMessage = () => (
      <div>
        <SpinnerIcon/>
      </div>
    );

    return <DataTable
    title={title}
    columns={columns}
    data={data}
    clearSelectedRows={clear}
    defaultSortFieldID={1}
    pagination
    conditionalRowStyles={conditionalRowStyles}
    // selectableRows
    // selectableRowsComponent={DataTableCheckboxCell}
    noDataComponent={<CustomNoDataMessage />}
    onSelectedRowsChange={handleSelection}
    selectableRowDisabled = { selectableRowDisabled}
    compact={true}
  />;
}