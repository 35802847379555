
import React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import {frontEndBaseUrl} from 'services/BackendApi/BackendApiURLs';

import DefaultRoutes from './DefaultRoutes/DefaultRoutes';
import RecepcionRoutes from './RecepcionRoutes/RecepcionRoutes';


var AppRoutes = [];
AppRoutes = AppRoutes.concat(RecepcionRoutes);
AppRoutes = AppRoutes.concat(DefaultRoutes);

export default function AppRouter(props){
  const router = createBrowserRouter(AppRoutes,{basename: frontEndBaseUrl});
  return <RouterProvider router={router} />;
}

/* 
  NOTA:
    Es importante independizar el listado del menú de las rutas que hagamos
    porque hay rutas que no van a aparecer en el menú de navegación por lo que no debe confundirse
    y tener que estar cargando la lista de rutas sin que sea necesario.

*/