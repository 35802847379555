import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './CargaIndividualView.module.scss';
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";
import FileUploader from "common/components/FileUploader/FileUploader";
import CargaLibroContableForm from "../../Forms/CargaLibroContableForm/CargaLibroContableForm";


export default function CargaIndividualView(props) {
    const { type } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveCatalogs());
    }, [dispatch]);

    const { catalogs } = useSelector((state) => {
        return state;
    });

    // if (!["factura","recibo"].includes(type)) return <div className={styles.viewwrapper}>
    //         Error. 
    //     </div>;

    return <div className={styles.viewwrapper}>
        <div>

            <Heading variant="h2" override>
                {"Recepcion de: Libros contables"}
            </Heading>
            {<CargaLibroContableForm/>
            }
        </div>
    </div>;
}