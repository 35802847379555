import CentralElementLayout from "common/layouts/CentralElementLayout/CentralElementLayout";
import LoginForm from "modules/user/components/LoginForm/LoginForm";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";
import ProtectedRoute from "common/routes/PrivateRoute/PrivateRoute";
import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
import LogoutForm from "modules/user/components/LogoutForm/LogoutForm";

/* 
    NOTA:
    En Element debe ir el Layout y el Layout debe tener como props.children la el view que
    se va usar? 
*/


const DefaultRoutes = [
    {
      path: "/dashboard",
      index:true,
      element: (
        <ProtectedRoute>
          <SideMenuTopBarLayout classes={['dashboard-page']}> 
              Welcome to Dashboard
          </SideMenuTopBarLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/user/login",
      element: (
        <CentralElementLayout classes={["user-login-page"]}> 
            <LoginForm />
        </CentralElementLayout>
      ),
    },
    {
        path: "/user/logout",
        element: (
          <CentralElementLayout classes={["user-logout-page"]} redirectAfterTo="/user/login" timeOutToRedirect={3000}> 
            <LogoutForm />
          </CentralElementLayout>
        ),
    }
    ,
    {
        path: "/user/restricted",
        element: (
          <CentralElementLayout classes={["user-restricted-page"]} redirectAfterTo="/dashboard" timeOutToRedirect={3000}> 
          <ErrorMark />
             <div style={{textAlign:"center"}}>Error, al acceder.</div>
          </CentralElementLayout>
        ),
    }
];

export default DefaultRoutes;