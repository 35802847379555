export default function renderError(error) {
	let message = '';
	console.log(error);
	switch (error.name) {
		case 'InvalidPDFException':
			message = 'The document is invalid or corrupted';
			break;
		case 'MissingPDFException':
			message = 'The document is missing';
			break;
		case 'UnexpectedResponseException':
			message = 'Unexpected server response';
			break;
		default:
			message = 'Cannot load the document';
			break;
	}

	return (
		<div className='d-flex justify-content-center align-items-center w-100 h-100'>
			<div className='bg-danger text-white p-3 border border-danger rounded'>
				{message}
			</div>
		</div>
	);
}
