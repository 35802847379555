import React from "react";
import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
import RecepcionSideBarOptions from './RecepcionMenuOptions';
import { Navigate } from "react-router-dom";
import RecepcionProtectedRoute from "./RecepcionProctedRoute";
import LibrosProtectedRoute from "./LibrosProtectedRoute";
import CierreProtectedRoute from "./CierreProtectedRoute";
import NotasProtectedRoute from "./NotasProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";
import ConciliacionesProtectedRoute from "./ConciliacionProtectedRoute";
//import RegistroDocumentoView from "modules/recepcion/components/Views/RegistroDocumentoView/RegistroDocumentoView";
import IngresoDocumentosFisicosView from "modules/recepcion/components/Views/IngresoDocumentosFisicosView/IngresoDocumentosFisicosView";
import DigitalizacionPendientesView from "modules/recepcion/components/Views/DigitalizacionPendientesView/DigitalizacionPendientesView";
import DetalleTramiteView from "modules/recepcion/components/Views/DetalleTramiteView/DetalleTramiteView";
import RetornoLoteView from "modules/recepcion/components/Views/RetornoLoteView/RetornoLoteView";
import CargaEstadoCuentaView from "modules/recepcion/components/Views/CargaEstadoCuentaView/CargaEstadoCuentaView";
import CargaLoteView from "modules/recepcion/components/Views/CargaLoteView/CargaLoteView";
import CargaIndividualView from "modules/recepcion/components/Views/CargaIndividualView/CargaIndividualView";
import RecepcionDocumentosView from "modules/recepcion/components/Views/RecepcionDocumentosView/RecepcionDocumentosView";
import RecepcionNotasView from "modules/recepcion/components/Views/CargaNotasView/CargaNotasView";
import RecepcionConciliacionesView from "modules/recepcion/components/Views/CargaConciliacionesView/CargaConciliacionesView";
import RecepcionFacturasView from "modules/recepcion/components/Views/CargaFacturasView/CargaFacturasView";
import BusquedaRecepcionView from "modules/recepcion/components/Views/BusquedaRecepcion/BusquedaRecepcionView";
import ListaRecepcionesUserView from "modules/recepcion/components/Views/ListaRecepcionesUserView/ListaRecepcionesUserView";
import MisRecepcionesView from "modules/recepcion/components/Views/MisRecepcionesView/MisRecepcionesView";
import BusquedaUsuarioView from "modules/recepcion/components/Views/BusquedaUsuario/BusquedaUsuarioView";
import EstadoCuentaFileView from "modules/recepcion/components/Views/EstadoCuentaFileView/EstadoCuentaFileView";
import CargaNotasFileView from "modules/recepcion/components/Views/CargaNotasFileView/CargaNotasFileView";
import CargaConciliacionesFileView from "modules/recepcion/components/Views/CargaConciliacionesFileView/CargaConciliacionesFileView";
import CargaFacturasFileView from "modules/recepcion/components/Views/CargaFacturasFileView/CargaFacturasFileView";
import CargaEstadosCuentaFileView from "modules/recepcion/components/Views/CargaEstadosCuentaFileView/CargaEstadosCuentaFileView";
import CargaEstadosCuentaView from "modules/recepcion/components/Views/CargaEstadosCuentaView/CargaEstadosCuentaView";
import CargaLoteFileView from "modules/recepcion/components/Views/CargaLoteFileView/CargaLoteFileView";
import CargaDocumentosFileView from "modules/recepcion/components/Views/CargaDocumentosFileView/CargaDocumentosFileView";
import EntregaView from "modules/recepcion/components/Views/EntregaView/EntregaView";
import SettingsRecepcionView from "modules/recepcion/components/Views/SettingsRecepcionView/SettingsRecepcionView";
import CambiarAsignacionView from "modules/recepcion/components/Views/CambiarAsignacionView/CambiarAsignacionView";
import Alerts from "common/layouts/Alerts/Alerts";

const RegistroDocumentoView = React.lazy(() => import('modules/recepcion/components/Views/RegistroDocumentoView/RegistroDocumentoView'));

/* 
    NOTA:
    En Element debe ir el Layout y el Layout debe tener como props.children la el view que
    se va usar? 
*/


const RecepcionRoutes = [
    {
      path: "/registro-documento",
      element:<RecepcionProtectedRoute>
        <SideMenuTopBarLayout classes={['registro-doc-page']}> 
          <RegistroDocumentoView />
        </SideMenuTopBarLayout>
      </RecepcionProtectedRoute>,
    },
    {
      path: '/registro-documento/ingreso-documentos-fisicos/:type',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout classes={['registro-doc-page','fisico']}> 
          <IngresoDocumentosFisicosView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/detalle-tramite/:dossierID',
      element:  <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','detalle']}> 
            <DetalleTramiteView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/digitalizacion/pendientes',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['digitalizacion-page','pendientes']}> 
          <DigitalizacionPendientesView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/digitalizacion/retorno-lote/:loteID',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['digitalizacion-page','retornolote']}> 
          <RetornoLoteView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/cierres/carga-estado-cuenta',
      element: <CierreProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaEstadoCuentaView />
      </SideMenuTopBarLayout></CierreProtectedRoute>,
    },
    {
      path: '/cierres/carga-estado-cuenta/:ID',
      element: <CierreProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <EstadoCuentaFileView />
      </SideMenuTopBarLayout></CierreProtectedRoute>,
    },
    {
      path: '/libros/carga-lote/:ID',
      element: <LibrosProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaLoteFileView />
      </SideMenuTopBarLayout></LibrosProtectedRoute>,
    },
    {
      path: '/notas/carga-lote/:ID',
      element: <NotasProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaNotasFileView />
      </SideMenuTopBarLayout></NotasProtectedRoute>,
    },
    {
      path: '/conciliaciones/carga-lote/:ID',
      element: <ConciliacionesProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaConciliacionesFileView />
      </SideMenuTopBarLayout></ConciliacionesProtectedRoute>,
    },
    {
      path: '/facturas/carga-lote/:ID',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaFacturasFileView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/estados-cuenta/carga-lote/:ID',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaEstadosCuentaFileView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/cierres/carga-documentos/:ID',
      element: <CierreProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaDocumentosFileView />
      </SideMenuTopBarLayout></CierreProtectedRoute>,
    },
    {
      path: '/cierres/carga-documentos-cierre',
      element: <CierreProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <RecepcionDocumentosView />
      </SideMenuTopBarLayout></CierreProtectedRoute>,
    },
    {
      path: '/notas/carga-documentos-notas',
      element: <NotasProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <RecepcionNotasView />
      </SideMenuTopBarLayout></NotasProtectedRoute>,
    },
    {
      path: '/conciliaciones/carga-documentos-conciliaciones',
      element: <ConciliacionesProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <RecepcionConciliacionesView />
      </SideMenuTopBarLayout></ConciliacionesProtectedRoute>,
    },
    {
      path: '/facturas/carga-documentos-facturas',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <RecepcionFacturasView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/estados-cuenta/carga-documentos-estados',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaEstadosCuentaView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/libros/carga-lote',
      element: <LibrosProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaLoteView />
      </SideMenuTopBarLayout></LibrosProtectedRoute>,
    },
    {
      path: '/libros/carga-individual',
      element: <LibrosProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CargaIndividualView />
      </SideMenuTopBarLayout></LibrosProtectedRoute>,
    },
    {
      path: '/mis-entregas',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <ListaRecepcionesUserView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/entrega/:ID',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <EntregaView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/busqueda/recepcion/:id',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <BusquedaRecepcionView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/revision/:id',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <BusquedaRecepcionView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
      path: '/busqueda/usuario',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <BusquedaUsuarioView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/settings',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <SettingsRecepcionView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/asignar-revision',
      element: <AdminProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <CambiarAsignacionView />
      </SideMenuTopBarLayout></AdminProtectedRoute>,
    },
    {
      path: '/mis-revisiones',
      element: <RecepcionProtectedRoute><SideMenuTopBarLayout  classes={['registro-doc-page','retornolote']}> 
          <MisRecepcionesView />
      </SideMenuTopBarLayout></RecepcionProtectedRoute>,
    },
    {
        path:"*",
        element:<Navigate to="/dashboard" replace />
    }
];

export default RecepcionRoutes;