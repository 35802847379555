import { FileCheck } from "react-bootstrap-icons";
import { House, FiletypePdf, Gear,ArrowBarDown,ListCheck,PersonUp} from "react-bootstrap-icons";

const options =[
    {
        variant: 'divider',
        label: 'Administración',
        roles: ['administrator','recepcion']
    },
    {
        label :"Listado de recepciones",
        defaultOption: false,
        icon : <PersonUp />,
        route : '/busqueda/usuario',
        alias : [
        ],
        roles: ['administrator','recepcion']
    },
    {
        label :"Configuración de entregas",
        defaultOption: false,
        icon : <Gear />,
        route : '/settings',
        alias : [
        ],
        roles: ['administrator','recepcion']
    },
    {
        label :"Asignacion de revisiones",
        defaultOption: false,
        icon : <Gear />,
        route : '/asignar-revision',
        alias : [
        ],
        roles: ['administrator','recepcion']
    },
    {
        variant: 'divider',
        label: 'Revisión de recepciones',
        roles: ['administrator','recepcion']
    },
    {
        label: "Mis Revisiones",
        defaultOption: false,
        icon: <ListCheck />,
        route: '/mis-revisiones',
        alias:[
        ],
        roles: ['recepcion','administrator']
    },
    {
        variant: 'divider',
        label: 'Entregas Realizadas',
        roles: ['administrator','recepcion']
    },
    {
        label: "Listado de entregas",
        defaultOption: false,
        icon: <ListCheck />,
        route: '/mis-entregas',
        alias:[
        ],
        roles: ['recepcion','administrator']
    },
    // {
    //     variant: 'divider',
    //     label: 'Cierres Bancarios',
    //     roles: ['cierre-bancario','administrator','recepcion']
    // },
    // {
    //     label: "Carga de estado de cuenta",
    //     defaultOption: false,
    //     icon: <ArrowBarDown />,
    //     route: '/cierres/carga-estado-cuenta',
    //     alias:[
    //     ],
    //     roles: ['recepcion','administrator']
    // },
    // {
    //     label :"Carga de documentos",
    //     defaultOption: false,
    //     icon : <ArrowBarDown />,
    //     route : '/cierres/carga-documentos-cierre',
    //     alias : [
    //     ],
    //     roles: ['cierre-bancario','administrator','recepcion']
    // },
    // {
    //     variant: 'divider',
    //     label: 'Libros Contables',
    //     roles: ['libros-contables','administrator','recepcion']
    // },
    // {
    //     label: "Carga de lote",
    //     defaultOption: false,
    //     icon: <ArrowBarDown />,
    //     route: '/libros/carga-lote',
    //     alias:[
    //     ],
    //     roles: ['libros-contables','administrator','recepcion']
    // },
    
    // {
    //     variant: 'divider',
    //     label: 'Notas de debito',
    //     roles: ['notas-debito','administrator','recepcion']
    // },
    // {
    //     label :"Carga en Lote",
    //     defaultOption: false,
    //     icon : <ArrowBarDown />,
    //     route : '/notas/carga-documentos-notas',
    //     alias : [
    //     ],
    //     roles: ['notas-debito','administrator','recepcion']
    // },
    // {
    //     variant: 'divider',
    //     label: 'Conciliaciones',
    //     roles: ['conciliacion','administrator','recepcion']
    // },
    // {
    //     label :"Carga en Lote",
    //     defaultOption: false,
    //     icon : <ArrowBarDown />,
    //     route : '/conciliaciones/carga-documentos-conciliaciones',
    //     alias : [
    //     ],
    //     roles: ['conciliacion','administrator','recepcion']
    // },
    {
        variant: 'divider',
        label: 'Entregas',
        roles: ['facturas','administrator','recepcion']
    },
    {
        label :"Carga en Lote",
        defaultOption: false,
        icon : <ArrowBarDown />,
        route : '/facturas/carga-documentos-facturas',
        alias : [
        ],
        roles: ['facturas','administrator','recepcion']
    },
    {
        label :"Carga individual",
        defaultOption: false,
        icon : <ArrowBarDown />,
        route : '/libros/carga-individual',
        alias : [
        ],
        roles: ['libros-contables','administrator','recepcion']
    },
    // {
    //     variant: 'divider',
    //     label: 'Estados de cuenta',
    //     roles: ['ec','administrator','recepcion']
    // },
    // {
    //     label :"Carga en Lote",
    //     defaultOption: false,
    //     icon : <ArrowBarDown />,
    //     route : '/estados-cuenta/carga-documentos-estados',
    //     alias : [
    //     ],
    //     roles: ['ec','administrator','recepcion']
    // },
];
export default options;