import React, { useEffect, useRef } from "react";
import { Button, Col } from "react-bootstrap";
import { Form, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
// import TagInput from "../TagInput/TagInput";
import { Search } from "react-bootstrap-icons";

export default function FormElement(props){
    const {id, type, label, placeholder, name, onChange, error,errorMessage, events, focused, onSearchButtonClick} = props;
    const inputRef  = useRef(null);
    let {margin} = props;
    if (!margin) margin="2";

    let sizes = {
        xs: (typeof(props.fieldLength)!=="undefined")? props.fieldLength.xs: 10,
        sm: (typeof(props.fieldLength)=="number")?props.fieldLength: (typeof(props.fieldLength)!=="undefined")? props.fieldLength.sm: 10,
        md: (typeof(props.fieldLength)!=="undefined")? props.fieldLength.md: 10,
        lg: (typeof(props.fieldLength)!=="undefined")? props.fieldLength.lg: 10,
    };
    
    useEffect(()=>{
        if (focused===true){
            setTimeout(()=>inputRef.current.focus(),100);
        }
    },[]);

    function RenderSwitch(props){
        switch (props.type){
            case "dropdown":{
                return <Form.Select aria-label="" onChange={props.onChange} name={props.name}  value={props.value} {...events} ref={inputRef}>
                            {props.children}
                        </Form.Select>;
            }break;
            case "checkbox":{
                return <><Form.Check 
                    type={type}
                   
                    label={props.placeholder}
                    onChange={props.onChange}
                    name={props.name}  value={props.value}
                    {...events}
                    ref={inputRef}
                />{props.children}</>;
            }break;
            case "currency":{
                
                return <><CurrencyInput
                    autoComplete="off"    
                    name={props.name}
                    placeholder={props.placeholder}
                    defaultValue={1.00}
                    decimalsLimit={2}
                    onValueChange={props.onChange}
                    className="form-control"
                    prefix={props.prefix+" "}
                    ref={inputRef}
                    style={{textAlign:'right'}}  value={props.value}  {...events}  /> 
                    {props.children}
                    
                    </>;
            }break;
            case 'textarea':{
                    
                return <Form.Control as="textarea" rows={3} placeholder={props.placeholder} onChange={props.onChange} name={props.name}  value={props.value}  {...events} ref={inputRef}  />
            }break;
            // case 'taginput':{
            //     return <>
                
            //         <TagInput id={props.id} name={props.name} onChange={props.onChange} value={props.value} {...events} />
            //         {props.children}
                
            //     </>;
            // }
            break;
            case 'void':{
                    return <>
                        {props.children}
                    </>;
            }break;
            case 'search':{
                return <>
                       <div style={{width:"100%",display:'table-row'}}>
                            <div style={{width:"80%",display:'table-cell'}}>
                                <Form.Control type={props.type} autoComplete="off"  placeholder={props.placeholder} onChange={props.onChange} name={props.name}  value={props.value}  {...events} ref={inputRef} />{props.children}
                            </div>
                            <div style={{width:"50px",display:'table-cell'}}>
                                <Button variant="link" onClick={onSearchButtonClick}><Search /></Button>
                            </div>
                
                       </div> 
                    </>;
            }break;
            default: {
                return <><Form.Control type={props.type} autoComplete="off"  placeholder={props.placeholder} onChange={props.onChange} name={props.name}  value={props.value}  {...events} ref={inputRef} />{props.children}</>;
            }break;
        }
    }
    

    return <Form.Group as={Row} className={"mb-"+margin} controlId={id}>
                <Form.Label column sm={2} className="xtext-end fw-bold">
                    {label}
                </Form.Label>
                <Col sm={sizes.sm} xs={sizes.xs} md={sizes.md} lg={sizes.lg}>

                        {RenderSwitch(props)}
                        {
                            error &&
                                <p className="text-danger">{errorMessage}</p>
                        }
                </Col>
            </Form.Group>;
}