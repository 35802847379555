import React, { useEffect, useRef, useState } from "react";
import styles from './FileUploader.module.scss';
import Axios from 'services/BackendApi/BackendApi';
import { formatBytes,formatPercentage } from "common/helpers/DataFormat";
import { ProgressBar } from "react-bootstrap";
import CheckMarkSuccess from "../AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "../AnimatedIcons/ErrorMark/ErrorMark";
import { Button } from "react-bootstrap";

export default function FileUploader(props){
    const {callBack,verbose, dropLabel} = props;
    const inputFile = useRef(null); 
    const [fileInfo, setFileIfo] = useState(null);

    function onDragEnter(evde){
        setDraggingData({...draggingData,overWindow:true});
        evde.preventDefault();
    }
    function onDragLeave(evl){
        evl.preventDefault();
        setDraggingData({...draggingData,overWindow:false});
    }
    function onDragOver(evdo){
        evdo.preventDefault();
        setDraggingData({...draggingData,overWindow:true});
    }
    function onDrop(evdr){
        setDraggingData({...draggingData,overWindow:false});
        evdr.preventDefault();  
    }
    function draggingOverArea(evd){
        evd.preventDefault(); 
        evd.stopPropagation();
        setDraggingData({...draggingData,overArea:true});
    }
    function endDraggingOverArea(evd){
        evd.preventDefault();
        setDraggingData({...draggingData,overArea:false});
    }
    function dropOverArea(evd){
        evd.preventDefault();
        evd.stopPropagation();
        setDraggingData({overWindow: false,overArea:false});
  
        
        
            const dT = new DataTransfer();
            if (evd.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...evd.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                    if (item.kind === 'file') {
                        const file = item.getAsFile();
                        if (i==0) dT.items.add(file);
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...evd.dataTransfer.files].forEach((file, i) => {
                    if (i==0) dT.items.add(file);
                });
            }
        
        inputFile.current.files = dT.files;
        inputFile.current.dispatchEvent(new Event('change', { bubbles: true}));
    }

    useEffect(()=>{
        if (!document.fileupBlockedDrop){
            window.addEventListener('dragenter',onDragEnter,false);
            window.addEventListener('dragover',onDragOver,false);
            window.addEventListener('dragleave',onDragLeave,false);
            window.addEventListener('dragend',onDragLeave,false);
            window.addEventListener('drop',onDrop,false);
            document.fileupBlockedDrop = true;
        }
    },[]);
    const [draggingData,setDraggingData] = useState(
        {
            overArea: false,
            overWindow: false,
        }
    );
    const [fileUploadTX,setFileUploadTx] = useState({
        uploading: false,
        finalized: false,
        status: false,
        error: false,
        result: false,
        uploadEnabled: false,
        uploadData:{
            progress: 0.00,
            totalBytes: 0,
            uploadedBytes: 0,
            uploadingRate: 0
        },
        error_log:[]
    });
    function handleAreaClick(evc){
        inputFile.current.value="";
        inputFile.current.click();
    }
    function handleInputFileChange(evch){
        uploadFile();
    }
    function onUploadProgress(progressEvent) {
        setFileUploadTx({
            ...fileUploadTX,
            uploading: true,
            uploadData:{
                progress: progressEvent.progress,
                uploadingRate: progressEvent.rate,
                totalBytes: progressEvent.total,
                uploadedBytes: progressEvent.loaded
            }
        });
    }
    async function uploadFile(){
        
        if ((fileUploadTX.uploading===false)&&(inputFile.current.files[0])){
            setFileUploadTx({...fileUploadTX,uploading:true});
            const formData = new FormData();
			formData.append('File[digitalFile]', inputFile.current.files[0]);
            const {data} = await Axios.UploadFile(formData,{onUploadProgress:onUploadProgress});
            
            if (data.result===true){
            
                setFileUploadTx({...fileUploadTX, finalized: true,error:false, result: true, uploading: false});
                setFileIfo(data.data); 
                if (typeof(callBack)=="function")
                callBack("UPLOAD_SUCCESSFULL",{...data.data, grant:data.grant});
                
            }else{
                if (typeof(callBack)=="function")
                callBack("ERROR_ON_UPLODAD",data);
                setFileUploadTx({...fileUploadTX, finalized: true,error:true, result: false, uploading: false,error_log:(data.message.digitalFile)?data.message.digitalFile:["Error general."]});
            }
            inputFile.current.value="";
            
        }
    }
    function switchRender(){
        if (fileUploadTX.uploading===false){
            if (fileUploadTX.finalized===false)
                    return  <div 
                            className={  styles.droparea
                                        +" "
                                        +(
                                            (draggingData.overWindow===true)? 
                                                styles.draggingoverwindow
                                            :(
                                                (draggingData.overArea===true)? 
                                                    styles.draggingoverarea
                                                :'' 
                                            )
                                        )
                                    }
                             onClick={handleAreaClick} onDragOver={draggingOverArea} onDragEnter={draggingOverArea} onDragEnd={endDraggingOverArea} onDrop={dropOverArea}>
                                {(dropLabel)? dropLabel:"Drag and Drop your files here."}
                            </div>;
            else    
                if (fileUploadTX.result===true)
                    return <div className={styles.successmsg}>
                        <div className={styles.iconc}><CheckMarkSuccess /></div>
                        <div>Archivo "{fileInfo.original_name}" subido con éxito.</div>
                    </div>;
                else
                    return <div className={styles.errormsg}>
                            <div className={styles.iconc}><ErrorMark /></div>
                        <div>Error al subir el archivo</div>
                        {
                            (fileUploadTX.error_log.map((errorLine,i)=><div key={i}>{errorLine}</div>))
                        }
                        <Button onClick={(evc)=> setFileUploadTx({
        uploading: false,
        finalized: false,
        status: false,
        error: false,
        result: false,
        uploadEnabled: false,
        uploadData:{
            progress: 0.00,
            totalBytes: 0,
            uploadedBytes: 0,
            uploadingRate: 0
        },
        error_log:[]
    })}>Intentar de nuevo</Button>
                    </div>;
        }else{
            return <div className={styles.upwrapper}>
                        <div>Subiendo archivo...</div>
                        <ProgressBar now={fileUploadTX.uploadData.progress*100} label={formatPercentage(fileUploadTX.uploadData.progress)} />
                        { (verbose)?<div>{formatBytes(fileUploadTX.uploadData.uploadedBytes,"MB")} de {formatBytes(fileUploadTX.uploadData.totalBytes,"MB")} a {formatBytes(fileUploadTX.uploadData.uploadingRate)+"/s"}</div>:''}


                    
            </div>;
        }
    }
    return <div className={styles.uploadwrapper}>
        {switchRender()}
        <input type="file" ref={inputFile} className={styles.fileinput} onChange={handleInputFileChange} />
    </div>;
}