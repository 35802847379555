import Heading from "common/components/Typography/Heading/Heading";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './CargaFacturasView.module.scss';
import CargaConciliacionesForm from "../../Forms/CargaConciliacionesForm/CargaConciliacionesForm";

import { useDispatch } from "react-redux";
import { retrieveCatalogs } from "modules/bussiness_logic/actions/catalogActions";
import { useSelector } from "react-redux";


export default function CargaFacturasView(props){
     const { type } = useParams();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(retrieveCatalogs());
    },[dispatch]);
   
    const {catalogs} = useSelector((state)=> {
            return state;
    });

    // if (!["factura","recibo"].includes(type)) return <div className={styles.viewwrapper}>
    //         Error. 
    //     </div>;

    return <div className={styles.viewwrapper}>
        <div>
           
            <Heading variant="h2" override>
                {"Recepcion de: Facturas"}    
            </Heading> 
            {<CargaConciliacionesForm Doctype="FC"/>}
        </div>
    </div>;
}