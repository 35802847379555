import React, { useEffect ,useState} from "react";
import { formatCurrency } from 'common/helpers/DataFormat';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios from 'services/BackendApi/BackendApi';
import './CargaEstadoCuentaForm.scss';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import FormElement from "common/components/FormElement/FormElement";
import { CheckCircle, Receipt } from "react-bootstrap-icons";
import Heading from "common/components/Typography/Heading/Heading";
import { TrashFill, Trash, FastForward, SkipBackward, ExclamationTriangleFill } from "react-bootstrap-icons";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import ErrorMark from "common/components/AnimatedIcons/ErrorMark/ErrorMark";
import OneSupportDocument from "./OneSupportDocument/OneSupportDocument";
import FileUploader from "common/components/FileUploader/FileUploader";

const formErrorLabels = {
    enterprise: 'No ha seleccionado la empresa',
    nit: 'No ha ingresado NIT o número de proveedor, o dato es erróneo',
    no_pedido: 'No ha ingresado No. de Pedido (Órden de Compra)',
    hoja_entrada: 'No ha ingresado No. de Hoja de entrada',
    anticipo: '',
    reporte_servicios: '',
    recibos: {
        serie: 'Serie del documento faltante',
        numero: 'Número del documento faltante',
        moneda: 'Moneda no válida',
        tipo: 'Tipo no válido',
        monto: 'Monto no válido',
        fecha: 'Por favor, ingrese una fecha válida',
        concepto: 'Por favor ingrese una descripción'
    }
};



export default function CargaEstadoCuentaForm(props) {
    const [cuentas, setCuentas] = useState([]);
    useEffect(() => {
        getAccounts();
      }, []);
    
    async function getAccounts(){
        let res = await Axios.getAccounts({ urlParams:  "1"  });
        setCuentas(res.data.query_result.models_1.queryOneModel_1.result)
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { catalogs } = useSelector((state) => {
        return state;
    });
    const month = [
        { name: "enero" }, { name: "febrero" }, { name: "marzo" }, { name: "abril" }, { name: "mayo" }, { name: "junio" }, { name: "julio" }, { name: "agosto" }, { name: "septiembre" }, { name: "octubre" }, { name: "noviembre" }, { name: "diciembre" }
    ]
    const [verifica, setVerifica] = useState(false);
    const [oneSupportDoc, setOneSupportDoc] = useState({ error: false, verifying: false, data: null });
    const [wizardState, setWizardState] = useState({ step: 0 });
    const [txDataError, setTxDataError] = useState([]);
    const [confirmationDialog, setConfirmationDialog] = useState({
        displayed: false,
        title: '',
        body: '',
        cancelCallback: null,
        confirmCallback: null
    });
    const [formDataError, setFormDataError] = useState({
        enterprise: false,
        nit: false,
        no_pedido: false,
        hoja_entrada: false,
        anticipo: false,
        reporte_servicios: false,
        recibos: [
            {
                serie: false,
                numero: false,
                moneda: false,
                tipo: false,
                monto: false,
                fecha: false,
                concepto: false,
                anticipo: false,
                reporte: false,
                hoja_entrada: false
            }
        ]

    });
    const [formData, setFormData] = useState({
        enterprise: 'GT00',
        nit: '',
        no_pedido: '',
        hoja_entrada: '',
        anticipo: false,
        reporte_servicios: false,
        recibos: [
            {
                serie: '',
                numero: '',
                moneda: 'GTQ',
                tipo: 'servicio',
                monto: '1.00',
                fecha: new Date().toISOString().slice(0, 10),
                concepto: '',
                anticipo: false,
                reporte: false,
                hoja_entrada: false
            }
        ],
        documentos_soporte: []
    });
    const [formPrueba, setFormPrueba] = useState({
        cuenta: '',
        año: '',
        mes: 'enero',
    });
    const [formPruebaError, setFormPruebaError] = useState({
        cuenta: false,
        año: false,
        mes: false,

    });
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogLeave, setOpenDialogLeave] = useState(false);
    const [openDialogConfirm, setDialogConfirm] = useState(false);
    const [reciboToDelete, setReciboToDelete] = useState(-1);
    const [confirmationState, setConfirmationState] = useState(0);

    function handleChange(evc) {
        const newFormData = { ...formData };
        let newFormDataErrors = { ...formDataError };
        newFormData[evc.target.name] = evc.target.value;
        setFormData(newFormData);
        newFormDataErrors[evc.target.name] = false;
        setFormDataError(newFormDataErrors);
    }

    function handleChangePrueba(evc) {
        console.log(evc.target.value);
        const newFormPrueba = { ...formPrueba };
        let newFormPruebaErrors = { ...formPruebaError };
        newFormPrueba[evc.target.name] = evc.target.value;
        setFormPrueba(newFormPrueba);
        newFormPruebaErrors[evc.target.name] = false;
        setFormPruebaError(newFormPruebaErrors);
        console.log(formPrueba);
    }

    function handleCloseDialog(deleteRecibo) {
        if (deleteRecibo) {
            let newData = { ...formData };
            if (reciboToDelete >= 0) {
                newData.recibos.splice(reciboToDelete, 1);
                setFormData(newData);
                setReciboToDelete(-1);
            }
        }
        setOpenDialog(false);
    }
    function deleteRecibo(index) {
        if (formData.recibos.length > index) {
            setReciboToDelete(index);
            setOpenDialog(true);
        }
    }
    function newRecibo() {
        let newData = { ...formData };
        let newFormDataError = { ...formDataError };
        newData.recibos[newData.recibos.length] = {
            serie: '',
            numero: '',
            moneda: 'GTQ',
            tipo: 'servicio',
            monto: 0.00,
            fecha: new Date().toISOString().slice(0, 10),
            concepto: '',
            anticipo: false,
            reporte: false,
            hoja_entrada: false
        };
        newFormDataError.recibos[newFormDataError.recibos.length] = {
            serie: false,
            numero: false,
            moneda: false,
            tipo: false,
            monto: false,
            fecha: false,
            concepto: false,
            anticipo: false,
            reporte: false,
            hoja_entrada: false
        };
        setFormData(newData);
        setFormDataError(newFormDataError);
    }
    function handleDateChange(reciboNo, newDate) {
        let newData = { ...formData };
        try {
            newData.recibos[reciboNo].fecha = newDate.$d.toISOString().slice(0, 10);
        } catch (errorx) {
        }
        setFormData(newData);
    }
    function handleCurrencyChange(value, name) {
        if (undefined != value) {
            let propName = name.substring(name.indexOf('.') + 1);
            let reciboNo = parseInt(name.replace('recibos[', '').replace('].' + propName, ""));
            let newData = { ...formData };
            newData.recibos[reciboNo].monto = value;
            setFormData(newData);
        }
    }
    function handleChange(evc) {
        let e = evc.target;
        let newData = { ...formData };
        let newFormDataError = { ...formDataError };
        let propName = "";


        if (formData.hasOwnProperty(e.name)) {

            if ((e.tagName == "INPUT") && (e.type == "checkbox")) {
                newData[e.name] = e.checked;
            } else {
                switch (e.name) {
                    case 'nit': {
                        newData[e.name] = e.value.toUpperCase().replace(/[^0-9K]+/, "");
                        newFormDataError[e.name] = false;
                    } break;
                    case 'no_pedido': case 'hoja_entrada': {
                        newData[e.name] = e.value.replace(/[^0-9]+/, "");
                        newFormDataError[e.name] = false;
                    } break;
                    default: {
                        newData[e.name] = e.value;
                        newFormDataError[e.name] = false;
                    }
                }



            }

            setFormDataError(newFormDataError);
            setFormData(newData);
        } else {

            if (!e.parentNode) {

                propName = e.name.substring(e.name.indexOf('.') + 1);
                let reciboNo = parseInt(e.name.replace('recibos[', '').replace('].' + propName, ""));
                newData.recibos[reciboNo][propName] = e.value;
                newFormDataError.recibos[reciboNo][propName] = false;
                setFormData(newData);
            } else if (e.parentNode.parentNode.hasAttribute('data-recibo')) {
                // es de recibo

                propName = e.getAttribute('name');
                propName = propName.substring(propName.indexOf('.') + 1);

                newData.recibos[e.parentNode.parentNode.dataset.recibo][propName] = e.value;
                newFormDataError.recibos[e.parentNode.parentNode.dataset.recibo][propName] = false;

                setFormData(newData);
            } else {

                propName = e.name.substring(e.name.indexOf('.') + 1);
                let reciboNo = parseInt(e.name.replace('recibos[', '').replace('].' + propName, ""));

                if ((e.tagName == "INPUT") && (e.type == "checkbox"))
                    newData.recibos[reciboNo][propName] = e.checked;
                else
                    newData.recibos[reciboNo][propName] = e.value;

                newFormDataError.recibos[reciboNo][propName] = false;

                setFormData(newData);
            }
            setFormDataError(newFormDataError);
        }

    }
    function validateData() {
        //let formKeys = Object.keys(formData);
        let newFormDataError = { ...formDataError };
        let generalError = false;
        let stringFields = ['enterprise', 'nit', 'no_pedido', 'hoja_entrada'];
        for (var key in formData) {
            //console.log('propiedad>',key);


            if (stringFields.includes(key)) {
                //console.log('Si es string:', formData[key].trim(), " - " );
                if (formData[key].trim() == "") {
                    //console.log('Hay un error:".'+ formData[key].trim()+".\" - " );
                    newFormDataError[key] = true;
                    generalError = true;
                    //console.log('El error lo dio:',key);
                    //console.log('asi queda la nueva data de error:',newFormDataError);
                } else {
                    newFormDataError[key] = false;
                }

            }
            if (key == "nit") {
                let position = 2;
                let aux = 0;
                for (let posnit = (formData.nit.length - 2); posnit >= 0; posnit--) {
                    aux += parseInt(formData.nit[posnit]) * position;
                    position++;
                }
                let cociente = 100;

                while (cociente > 0) {
                    cociente = Math.floor(aux / 11);
                    cociente = cociente * 11;
                    aux = aux - cociente;
                };
                //aux tiene el resultado
                let resultado = 11 - aux;
                let check = "K";
                if (resultado < 10) {
                    check = resultado.toString();
                }
                if (formData.nit[formData.nit.length - 1] != check) {
                    newFormDataError.nit = true;
                    generalError = true;
                }
            }
            //console.log('objeto key',key);
            if (key == "recibos") {
                for (var reciboNo = 0; reciboNo < formData.recibos.length; reciboNo++) {
                    for (var keyF in formData.recibos[reciboNo]) {
                        //console.log('recibo #'+reciboNo+"["+keyF+"] = "+formData.recibos[reciboNo][keyF]);
                        if (typeof (formData.recibos[reciboNo][keyF]) == "string")
                            if (formData.recibos[reciboNo][keyF].trim().replace(" ", "") == "") {
                                newFormDataError['recibos'][reciboNo][keyF] = true;
                                generalError = true;
                                //console.log('El error lo dio:',reciboNo,keyF);
                            } else {
                                newFormDataError['recibos'][reciboNo][keyF] = false;
                            }
                    }
                }
            }
        }
        //console.log("Errores de form:",newFormDataError);
        setFormDataError(newFormDataError);
        //console.log('Se va a retornar>',generalError);
        return generalError;
    }
    async function processTx() {
        setConfirmationState(1);
        //procedemos
        try {
            const { data } = await Axios.RegistrarRecibosPapel2(formData);
            if (data.error === true) {
                setConfirmationState(-1);
                console.log("ERROR ... LA DATA");
                console.log(data.error_log);
                if (Array.isArray(data.error_log))
                    setTxDataError(data.error_log);
                else
                    setTxDataError([data.error_log]);
            }
            if (data.result && !data.error) {
                setConfirmationState(10);
            }
        } catch (error) {
            //console.log("ERROR ON REGISTRARRECIBOS PAPEL",error);
            setConfirmationState(-1);
        }

    }
    function shutDown() {
        setTxDataError([]);
        setFormDataError({
            enterprise: false,
            nit: false,
            no_pedido: false,
            hoja_entrada: false,
            anticipo: false,
            reporte_servicios: false,
            recibos: [
                {
                    serie: false,
                    numero: false,
                    moneda: false,
                    tipo: false,
                    monto: false,
                    fecha: false,
                    concepto: false,
                    anticipo: false,
                    reporte: false,
                    hoja_entrada: false
                }
            ]
        });
        setFormData({
            enterprise: 'GT00',
            nit: '',
            no_pedido: '',
            hoja_entrada: '',
            anticipo: false,
            reporte_servicios: false,
            recibos: [
                {
                    serie: '',
                    numero: '',
                    moneda: 'GTQ',
                    tipo: 'servicio',
                    monto: '1.00',
                    fecha: new Date().toISOString().slice(0, 10),
                    concepto: '',
                    anticipo: false,
                    reporte: false,
                    hoja_entrada: false
                }
            ]
        });
        setOpenDialog(false);
        setOpenDialogLeave(false);
        setDialogConfirm(false);
        setReciboToDelete(-1);
        setConfirmationState(0);
        navigate("/digitalizacion/pendientes", true);
    }

    function switchModalTitle() {
        switch (confirmationState) {
            case 0:
                return "Por favor, revise los siguientes datos..."; break;
            case 1:
                return "Registrando los documentos..."; break;
            case -1:
                return "Hubo un error"; break;
            case 10:
                return "Éxito"; break;
        }
    }
    function switchModalContent() {
        switch (confirmationState) {

            case 1:
                return "Procesando transacción ... "; break;
            case -1:
                return <>
                    <ErrorMark />
                    <h3>Hubo un error al registrar los documentos </h3>
                    <h4>Los errores detectados fueron:</h4>
                    <ul>
                        {txDataError.map((error_msg, idx) => {
                            return <li key={idx}>{error_msg}</li>;
                        })}
                    </ul></>; break;
            case 10:
                return <div className="bodycentered"><CheckMarkSuccess />
                    <h3> Los documentos han sido registrados exitosamente! </h3></div>; break;
        }
    }
    function handleChangeSupportDtype(evc) {

        if (parseInt(evc.target.value) !== 0) {
            let tdoc = catalogs.support_document_types.filter((one) => one.id === parseInt(evc.target.value));
            if (tdoc.length > 0) {
                let { code } = tdoc[0];
                let allowedTypes = ".pdf";
                if (code.indexOf("_XLS") > 0) {
                    allowedTypes = ".xlsx, .xls";
                }
                setOneSupportDoc({ ...oneSupportDoc, data: { document_type: parseInt(evc.target.value), fileExtension: allowedTypes } });
            }
        }
        else
            setOneSupportDoc({ ...oneSupportDoc, data: null });
    }
    function prevStep() {
        setWizardState({ step: wizardState.step - 1 });
    }

    async function Prueba() {
        try {
            const data = await Axios.PruebaForm(formPrueba);
            console.log("Res:", data);
            console.log("La peticion salio bien");

        }
        catch (error) {
            console.log("Error en peticion prueba", error);
        }
    }

    function nextStep() {
        //if (validarFormulario()===true)
        if (wizardState.step + 1 == 2) {
            setDialogConfirm(true);
            setConfirmationState(0);
        }
        setWizardState({ step: wizardState.step + 1 });

    }
    function deleteSupportDocumentCallback(position) {
        setConfirmationDialog(
            {
                displayed: true,
                title: 'Confirmar descarte',
                body: "¿Está seguro que desea descartar este documento?",
                confirmCallback: (() => {
                    let newFormData = { ...formData };
                    newFormData.documentos_soporte.splice(position, 1);
                })
            }
        )


    }
    function renderConfirmation() {
        if (confirmationDialog.displayed === true) {
            return <Modal show={confirmationDialog.displayed} onHide={(event, reason) => {
                setConfirmationDialog({
                    displayed: false,
                    title: '',
                    body: '',
                    cancelCallback: null,
                    confirmCallback: null
                });
            }} backdrop="static" keyboard={false}>
                <Modal.Header closeButton={false}>
                    {confirmationDialog.title}
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="warncont">
                            <ExclamationTriangleFill className="bigwarning" />

                            <i className="fa-solid fa-triangle-exclamation fa-fade"></i>
                            <div>
                                {confirmationDialog.body}
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(evc) => {
                        setConfirmationDialog({
                            displayed: false,
                            title: '',
                            body: '',
                            cancelCallback: null,
                            confirmCallback: null
                        });
                        if (typeof (confirmationDialog.cancelCallback) == "function") confirmationDialog.cancelCallback();
                    }}>Cancelar</Button>
                    <Button variant="danger" onClick={(evc) => {
                        setConfirmationDialog({
                            displayed: false,
                            title: '',
                            body: '',
                            cancelCallback: null,
                            confirmCallback: null
                        });
                        if (typeof (confirmationDialog.confirmCallback) == "function") confirmationDialog.confirmCallback();
                    }}>Confirmar</Button>
                </Modal.Footer>
            </Modal>;
        }
    }
    function renderConfirmTx() {
        return (openDialogConfirm) ?

            (confirmationState == 0) ?
                <Modal show={openDialogConfirm} onHide={(event, reason) => { setDialogConfirm(false); prevStep(); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{switchModalTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="confirmtbl">
                            <Row>
                                <Col className="confirmlbl">Sociedad a la que emite el documento:</Col><Col className="datacol">{formData.enterprise}</Col>
                            </Row>
                            <Row>
                                <Col className="confirmlbl">Nit o No. del proveedor</Col><Col className="datacol">{formData.nit}</Col>
                            </Row>
                            <Row>
                                <Col className="confirmlbl">No. Pedido</Col><Col className="datacol">{formData.no_pedido}</Col>
                            </Row>
                            <Row>
                                <Col className="confirmlbl">No. Hoja Entrada</Col><Col className="datacol">{formData.hoja_entrada}</Col>
                            </Row>
                            {/*<Row>
                            <Col className="confirmlbl">¿Es un Anticipo?</Col><Col className="datacol">{formData.anticipo? "Sí":"No"}</Col>
                        </Row>
                        <Row>
                            <Col className="confirmlbl">¿Incluye reporte de servicios general a todos los documentos?</Col><Col className="datacol">{formData.reporte_servicios? "Sí":"No"}</Col>
                        </Row>*/}
                            <Row>

                                <Col><br /><Heading variant="h4">Recibos</Heading>
                                    <Container>
                                        {
                                            formData.recibos.map((recibo, index) => {
                                                return <div key={index} className='factreview'>

                                                    <Row>
                                                        <Col><Heading variant='h5'>Documento #{index + 1}</Heading></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Fecha: <span className="datacol">{recibo.fecha}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Serie: <span className="datacol">{recibo.serie}</span> - Número: <span className="datacol">{recibo.numero}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Tipo: <span className="datacol">{recibo.tipo == "bien" ? "Bien" : "Servicio"}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Monto: <span className="datacol">{formatCurrency(recibo.moneda, recibo.monto)}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Concepto: <span className="datacol">{recibo.concepto}</span></Col>
                                                    </Row>
                                                    {/*
                                                        (recibo.reporte===true)?
                                                        <Row>
                                                            <Col><span className="datacol">(INCLUYE UN REPORTE DE SERVICIOS PARA ESTE DOCUMENTO)</span></Col>
                                                        </Row>:''
                                        
                                                    */}
                                                </div>;
                                            })

                                        }
                                    </Container>
                                </Col>
                            </Row>
                            <Row> </Row>
                            <Row>

                                <Col><br /><Heading variant="h4">Documentos de soporte</Heading>
                                    <Container>
                                        {
                                            formData.documentos_soporte.map((docs, index) => {
                                                const doctype = catalogs.support_document_types.filter((oneType) => oneType.id == docs.document_type);
                                                return <div key={index} className='factreview'>

                                                    <Row>
                                                        <Col><Heading variant='h5'>Documento soporte #{index + 1}</Heading></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Tipo: <span className="datacol">{doctype[0].name}</span></Col>
                                                    </Row>

                                                </div>;
                                            })

                                        }
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setDialogConfirm(false); prevStep(); }}>
                            Regresar
                        </Button>
                        <Button variant="primary" onClick={() => { processTx(); }}>
                            OK, proceder
                        </Button>
                    </Modal.Footer>
                </Modal> : ''
            :
            ''
            ;
    }
    function renderTxResult() {
        return (confirmationState !== 0) ?
            <Modal show={openDialogConfirm} onHide={() => { setDialogConfirm(false); if (confirmationState == 10) shutDown(); else { prevStep(); prevStep(); } }} backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton={(confirmationState < 0) || (confirmationState == 10)}>
                    <Modal.Title>{switchModalTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {switchModalContent()}
                </Modal.Body>
            </Modal> : '';

    }


    if (catalogs.loading) return <div className="f001">Cargando..</div>;


    console.log("wizardState=", wizardState.step);
    switch (wizardState.step) {
        case 0: {
            return <div className="f001">
                {renderConfirmTx()}
                {renderTxResult()}
                {
                    (openDialogLeave) ?
                        <Modal show={openDialogLeave} onHide={() => { setDialogConfirm(false); if (confirmationState == 10) shutDown(); }} backdrop="static"
                            keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>¿Está seguro que desea abandonar el formulario?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                OJO: Esta acción no puede revertirse.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => { setOpenDialogLeave(false) }}>
                                    Regresar
                                </Button>
                                <Button variant="primary" onClick={() => { navigate("/", true) }}>
                                    Sí, proceder
                                </Button>
                            </Modal.Footer>
                        </Modal> : ''
                }
                {
                    (openDialog) ?
                        <Modal show={openDialog} onHide={(event, reason) => { setDialogConfirm(false); }}>
                            <Modal.Header closeButton>
                                <Modal.Title>¿Está seguro de eliminar el siguiente documento?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Col>Documento #{reciboToDelete + 1}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Fecha: {formData.recibos[reciboToDelete].fecha}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Serie: {formData.recibos[reciboToDelete].serie} - Número: {formData.recibos[reciboToDelete].numero}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Tipo: {formData.recibos[reciboToDelete].tipo == "bien" ? "Bien" : "Servicio"}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Monto: {formatCurrency(formData.recibos[reciboToDelete].moneda, formData.recibos[reciboToDelete].monto)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Concepto: {formData.recibos[reciboToDelete].concepto}</Col>
                                    </Row>
                                    {
                                        (formData.recibos[reciboToDelete].reporte === true) ?
                                            <Row>
                                                <Col>(INCLUYE UN REPORTE DE SERVICIOS PARA ESTE DOCUMENTO)</Col>
                                            </Row> : ''

                                    }
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => {
                                    handleCloseDialog(false);
                                }}>
                                    Cerrar
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    handleCloseDialog(true);
                                }}>
                                    Sí, proceder
                                </Button>
                            </Modal.Footer>
                        </Modal> : ''

                }
                <Form>
                    {/* <FormElement label="Cuenta Bancaria" id="enterprise" type="dropdown" error={formDataError.enterprise} fieldLength={2}>
                        {
                            catalogs.enterprises.map((enterprise, index) =>
                                <option value={enterprise.erp_code1} key={index}>NIT: {enterprise.tax_payer_id} - {enterprise.name} - ({enterprise.erp_code1 + "|" + enterprise.erp_code2}) </option>
                            )
                        }
                    </FormElement> */}
                    {/* <FormElement label="Cuenta" id="cuenta" value={formPrueba.cuenta} name="cuenta" fieldLength={{ sm: 3, xs: 10, lg: 2 }} onChange={handleChangePrueba} error={formDataError.cuenta}
                        errorMessage={formErrorLabels.cuenta}
                    ></FormElement> */}
                    <FormElement label="Cuenta" id="cuenta" name="cuenta" type="dropdown" onChange={handleChangePrueba} fieldLength={2}>
                        {
                            cuentas.map((mes, index) =>
                                <option value={mes.bank_account_number} > {mes.bank_account_number}</option>
                            )
                        }
                    </FormElement>
                    <FormElement label="Año" id="año" value={formPrueba.año} name="año" fieldLength={{ sm: 3, xs: 10, lg: 2 }} onChange={handleChangePrueba} error={formDataError.año}
                        errorMessage={formErrorLabels.año}
                    ></FormElement>
                    <FormElement label="Mes" id="mes" name="mes" type="dropdown" onChange={handleChangePrueba} fieldLength={2}>
                        {
                            month.map((mes, index) =>
                                <option value={mes.name} > {mes.name}</option>
                            )
                        }
                    </FormElement>
                    {/* {(verifica)? <h3>Faltan datos</h3>:UploadDocumentCallback} */}
                    <FileUploader dropLabel={<>Arrastre y suelte su archivo EXCEL del Estado de Cuenta <br /> o haga click acá para ubicar</>} acceptOnly=".xlsx" callBack={UploadDocumentCallback} ></FileUploader>
                    {/* <Button variant="primary" onClick={Prueba}>Enviar <CheckCircle /></Button>  */}
                    {/* <FormElement label="Nombre" id="nombre" name="nombre" value={formPrueba.nombre} fieldLength={2}  onChange={handleChangePrueba} error={formPruebaError.nombre}
                        ></FormElement>
                        <FormElement label="edad" id="edad" name="edad" value={formPrueba.edad} fieldLength={2}  onChange={handleChangePrueba} error={formPruebaError.edad}
                        ></FormElement>
                        <FormElement label="direccion" id="direccion" name="direccion" value={formPrueba.direccion} fieldLength={2}  onChange={handleChangePrueba} error={formPruebaError.direccion}
                        ></FormElement>*/}
                    {/* <FormElement label="No. de Hoja de Entrada" id="hoja_entrada" name="hoja_entrada" value={formData.hoja_entrada} fieldLength={4}  onChange={handleChange} error={formDataError.hoja_entrada}
                            errorMessage={formErrorLabels.hoja_entrada}
                        ></FormElement> */}
                    {/*<FormElement label="Checklist" id="anticipo" name="anticipo" value={formData.anticipo} type="checkbox" placeholder="Trámite de anticipo"  onChange={handleChange} error={formDataError.anticipo}
                            errorMessage={formErrorLabels.anticipo}
                        ></FormElement>
                        <FormElement label="" id="reporte_servicios" name="reporte_servicios" value={formData.reporte_servicios} type="checkbox" placeholder="Incluye un reporte de servicios general a todos los documentos"  onChange={handleChange} error={formDataError.reporte_servicios}
                            errorMessage={formErrorLabels.reporte_servicios}
                            ></FormElement>*/}
                    {/* <div>
                        {
                            formData.recibos.map((recibo,index)=>{
                                return <div className="fact-cont" key={index}>
                                        <fieldset className="fs-fact">
                                            <legend>
                                                <Receipt /> Documento #{index+1}
                                                <div className="factTools">
                                                { 
                                                    (index>0)?
                                                    <Button variant="danger" onClick={()=> deleteRecibo(index)}>
                                                        <TrashFill /> Eliminar
                                                    </Button>
                                                    :''
                                                }
                                                </div>
                                            </legend>
                                        
                                        
                                       
                                        <div className="fact-form">
                                            <FormElement label="Fecha" type='date'  id={"recibos-"+index+"-fecha"} name={"recibos["+index+"].fecha"} value={recibo.fecha} onChange={handleDateChange}  fieldLength={3} error={formDataError.recibos[index].fecha}
                                                errorMessage={formErrorLabels.recibos.fecha}
                                            />
                                            <FormElement label="Serie" type='text'  id={"recibos-"+index+"-serie"} name={"recibos["+index+"].serie"} value={recibo.serie}  fieldLength={3}  onChange={handleChange} error={formDataError.recibos[index].serie}
                                                errorMessage={formErrorLabels.recibos.serie}
                                              />
                                            <FormElement label="Numero" type='number'  id={"recibos-"+index+"-numero"} name={"recibos["+index+"].numero"} value={recibo.numero}  fieldLength={3}  onChange={handleChange} error={formDataError.recibos[index].numero}
                                                errorMessage={formErrorLabels.recibos.numero}
                                              />
                                            <FormElement label="Tipo" id={"recibos-"+index+"-tipo"} name={"recibos["+index+"].tipo"} type="dropdown" value={recibo.tipo} fieldLength={3} onChange={handleChange} error={formDataError.recibos[index].tipo}
                                                errorMessage={formErrorLabels.recibos.tipo}
                                            >
                                                    <option value="bien">Bien</option>
                                                    <option value="servicio">Servicio</option>
                                            </FormElement>
                                            <FormElement label="Moneda" type='dropdown'  id={"recibos-"+index+"-moneda"} name={"recibos["+index+"].moneda"} value={recibo.moneda} onChange={handleChange} fieldLength={3} defaultValue={recibo.moneda}  error={formDataError.recibos[index].moneda}
                                                errorMessage={formErrorLabels.recibos.moneda}
                                            >
                                                {
                                                    catalogs.currencies.map((currency,index)=><option key={index} value={currency.code}>{currency.symbol} - {currency.name}</option>) 
                                                }
                                            </FormElement>
                                            <FormElement label="Monto" type='currency'  id={"recibos-"+index+"-monto"} name={"recibos["+index+"].monto"} currency={recibo.moneda} value={recibo.monto} prefix='' onChange={handleCurrencyChange} fieldLength={3} error={formDataError.recibos[index].monto}
                                                errorMessage={formErrorLabels.recibos.monto}
                                            />
                                            <FormElement label="Concepto" type='textarea'  id={"recibos-"+index+"-concepto"} name={"recibos["+index+"].concepto"} currency={recibo.concepto} value={recibo.concepto} prefix='' onChange={handleChange} fieldLength={4} error={formDataError.recibos[index].concepto}
                                                errorMessage={formErrorLabels.recibos.concepto}
                                            />
                                        </div>
                                        </fieldset>
                                </div>;
                            })
                        }
                        </div> */}

                    {/* <div className="nexttoolbar">
                            <Button variant="danger" className="discardbutton" onClick={()=>{setOpenDialogLeave(true)}}>
                                    <Trash /> Descartar trámite 
                            </Button>
                            <Button variant="primary" className="nextbutton" onClick={()=>{
                            if(!validateData())setWizardState({step:wizardState.step+1})}}>
                                Siguiente paso <FastForward />
                            </Button>
                        </div> */}

                    {/* <Button
						
							color='secondary'
							onClick={newRecibo}>
							+ Agregar otro Documento
						</Button>
     */}
                    {/*<Button
							variant='outlined'
							color='secondary'
							onClick={()=>{
                                setOpenDialogLeave(true)
                            }}>
							Cancelar
						</Button>
						<Button
							variant='outlined'
							color='primary'
							onClick={()=>{
                                setConfirmationState(0);
                                if(!validateData()){
                                    setDialogConfirm(true);
                                }
                            }}>
							Continuar
						</Button>*/}
                </Form>


            </div>;
        } break;

            function UploadDocumentCallback(type, data) {
                switch (type) {
                    case "UPLOAD_SUCCESSFULL": {
                        localStorage.setItem('datosEC', JSON.stringify(formPrueba));
                        console.log("onUploadDocumentCallback>", data);
                        navigate("/cierres/carga-estado-cuenta/" + data.id, true);

                    } break;
                }
            }
        case 1: {
            return <div className="">
                {renderConfirmation()}
                <Heading variant="h2">Ingreso documentos de soporte <span className="stepspan">Paso 2 de 3</span></Heading>
                {/*<MessageRenderer keyCode="PROV_MSG001" />*/}
                <div>
                    {
                        formData.documentos_soporte.map((dsoporte, idx) => <OneSupportDocument data={dsoporte} key={idx} pos={idx} deleteCallback={deleteSupportDocumentCallback} />)
                    }
                </div>
                <div className="newsupportdoc">
                    <Heading variant="h5">Documentos de Soporte</Heading>
                    <Form>

                        <FormElement label="Tipo de documento" id="supportdoctype" name="supportdoctype" value={(oneSupportDoc.data === null) ? 0 : oneSupportDoc.data.supportdoctype} type="dropdown" fieldLength={6} onChange={handleChangeSupportDtype}>
                            <option value={0} key={0}>-- seleccione un tipo --</option>
                            {
                                catalogs.support_document_types.map((dt, index) =>
                                    <option value={dt.id} key={index + 1}>{dt.code} - {dt.name} </option>
                                )
                            }
                        </FormElement>
                        <Button variant="primary" onClick={() => {
                            setOneSupportDoc({ error: false, verifying: false, error_log: [], finalized: true, data: { ...oneSupportDoc.data } });
                            let newFormData = { ...formData };
                            newFormData.documentos_soporte.push({ ...oneSupportDoc.data });
                            setOneSupportDoc({ error: false, verifying: false, data: null });
                            setTimeout(() => {
                                setOneSupportDoc({ error: false, verifying: false, data: null });
                            }, 2000);
                        }} disabled={(oneSupportDoc.data == null)} >Registrar documento de soporte</Button>

                        {/*
                        (oneSupportDoc.data!==null)? <FileUploader dropLabel={<>Por favor arrastre y suelte su archivo <br /> o haga click acá y suba el {(oneSupportDoc.data.fileExtension==".pdf")?"PDF":"ARCHIVO DE EXCEL"} de su documento de soporte acá</>} acceptOnly={oneSupportDoc.data.fileExtension} callBack={SoporteDocumentCallback} ></FileUploader>
                        : "Seleccione primero el tipo de documento..."*/
                        }
                    </Form>
                </div>

                <div className="nexttoolbar">
                    <Button variant="secondary" onClick={prevStep} className="prevbutton"><SkipBackward /> Anterior</Button>
                    <Button variant="danger" className="discardbutton" onClick={() => { setOpenDialogLeave(true) }}><Trash /> Descartar trámite </Button>
                    {

                        (formData.documentos_soporte.length > 0) ? <Button variant="primary" className="nextbutton" onClick={nextStep}>Siguiente paso <FastForward /></Button> : ''
                    }
                </div>
            </div>;
        } break;
        case 2: {
            return <div className="">
                {renderConfirmTx()}
                {renderTxResult()}
            </div>;
        } break;
        default: {
            return <div>{renderTxResult()}{wizardState.step}</div>;
        } break;
    };
};