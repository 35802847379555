import React from "react";
import { formatCurrency } from "common/helpers/DataFormat";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import styles from './DossierDataTable.module.scss';
import { ExclamationTriangle } from "react-bootstrap-icons";

export default function DossierDataTable(props){
    const {data} = props;
    const lblSize = 4;
    
    function renderReporteGeneral(){
        let CompleteReport = data.documents.filter(doc => doc.type==4);
        //console.log("Complete report:",CompleteReport);
        if ((CompleteReport.length)>0)
        return <Row key={-1}>
        <Col>
            <Container className={styles.onedoc}>
                <Row>
                    <Col>
                        <h4 className={styles.doctitle}>Incluye un {CompleteReport[0].document_type_label} general para todos los documentos.</h4>
                    </Col>
                </Row>
            </Container>
        </Col>
        </Row>;
        else return "";
    }
    function switchStatus(){
        switch (data.status){
            case 0:{
                return <div className={styles.recibidovent}>RECIBIDO EN VENTANILLA</div>;
            }break;
            case 1:{
                return <div className={styles.transitoarchivo}>EN TRÁNSITO  incluído Lote: {data.lot.lote_id}</div>;
            }break;
            case 2:{
                return <div className={styles.retornadoarchivo}><ExclamationTriangle /> RETORNADO DE ARCHIVO</div>;
            }break;
        }
    }
    return <Container><Container>
                <Row>
                    <Col><h1 className={styles.detailtitle}>{data.dossier_type_name} #{data.id}</h1> </Col>

                </Row>
                <Row>
                    <Col className={styles.labeldata} xs={lblSize}>Hora y fecha de recepción:</Col>
                    <Col>{data.reception_timestamp}</Col>
                </Row>
                <Row>
                    <Col className={styles.labeldata} xs={lblSize}>Estatus del trámite:</Col>
                    <Col>{switchStatus()}</Col>
                </Row>
                <Row>
                    <Col className={styles.labeldata} xs={lblSize}>Empresa:</Col>
                    <Col>{data.metadata.DOSSIER_ENTERPRISE.value}</Col>
                </Row>
                <Row>
                    <Col className={styles.labeldata} xs={lblSize}>{data.metadata.DOSSIER_NO_PEDIDO.label}:</Col>
                    <Col>{data.metadata.DOSSIER_NO_PEDIDO.value}</Col>
                </Row>
                <Row>
                    <Col className={styles.labeldata} xs={lblSize}>{data.metadata.DOSSIER_NO_HOJA_ENTRADA.label}:</Col>
                    <Col>{data.metadata.DOSSIER_NO_HOJA_ENTRADA.value}</Col>
                </Row>
                {/*<Row>
                    <Col className={styles.labeldata} xs={lblSize}>{data.metadata.DOSSIER_PAGO_ANTICIPO.label}:</Col>
                    <Col>{data.metadata.DOSSIER_PAGO_ANTICIPO.value? "Sí": "No"}</Col>
                </Row>*/}
            </Container>
            <Container>
                <hr />
                <h2>Documentos recibidos</h2>
                {
                    renderReporteGeneral()
                }
                {
                    data.documents.map((doc,idx)=>{
                        
                        if ((doc.type==5)||(doc.type==1)){
                        return  <Row key={idx}>
                                    <Col>
                                        <Container className={styles.onedoc}>
                                            <Row>
                                                <Col>
                                                    <h4 className={styles.doctitle}>{doc.document_type_label} #{idx+1}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className={styles.labeldata} xs={lblSize}>
                                                    {doc.metadata.DOCUMENT_DATE.label}:
                                                </Col>
                                                <Col>
                                                    {doc.metadata.DOCUMENT_DATE.value}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className={styles.labeldata} xs={lblSize}>Serie y Número:</Col>
                                                <Col>{doc.serie}-{doc.numero}</Col>
                                            </Row>
                                            <Row>
                                                <Col className={styles.labeldata} xs={lblSize}>{
                                                    doc.metadata.DOCUMENT_MONTO.label
                                                }:
                                                </Col>
                                                <Col>{formatCurrency(doc.metadata.DOCUMENT_CURRENCY.value,doc.metadata.DOCUMENT_MONTO.value)}</Col>
                                            </Row>
                                            <Row>
                                                <Col className={styles.labeldata} xs={lblSize}>{doc.metadata.DOCUMENT_DESCRIPCION.label}</Col>
                                                <Col><div>{doc.description}</div></Col>
                                            </Row>
                                            { (data.documents.length>0)?
                                            <Row>
                                                <Col>
                                                <h5>Documentos asociados a este documento</h5> 
                                                    <ul>
                                                    {
                                                        data.documents.map((doc2,idx2)=>{
                                                            if ((doc2.section==doc.section)&&(doc2.id!=doc.id)){
                                                                return <li key={idx2}>
                                                                        {doc2.description}
                                                                    </li>;
                                                            }
                                                            return ;
                                                        })
                                                    }
                                                    </ul>
                                                </Col>
                                            </Row>:""}
                                        </Container>
                                    </Col>
                            </Row>;
                        }else{
                            return;
                        }
                    })
                }
                <br />
                <h3>Documentos de soporte presentados</h3>
                <br />
                <ol>
                {
                    data.documents.map((doc,idx)=>{
                        
                        if ((doc.type!==5)&&(doc.type!==1)){
                        return  <li key={idx}>
                                    
                                                    {doc.document_type_label}
                            </li>;
                        }else{
                            return;
                        }
                    })
                }
                </ol>
            </Container></Container>
   ;
}